<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4">
    <h3>Merverdiavgift (MVA)
      <a v-if="isSuperAdmin" @click="goToEditVatUrl">
        <img src="@/assets/images/edit.png" class="vat-edit-btn" width="32" title="Rediger"/>
      </a>
    </h3>

    <br/>
    <div>
      <span class="vat-element-width"><strong>Spise her:</strong></span> &nbsp;
      <span>{{ vat.dineIn }}%</span>
    </div>
    <div>
      <span class="vat-element-width"><strong>Ta med:</strong></span> &nbsp;
      <span>{{ vat.takeOut }}%</span>
    </div>
    <div>
      <span class="vat-element-width"><strong>Levere:</strong></span> &nbsp;
      <span>{{ vat.delivery }}%</span>
    </div>
  </div>
</template>

<script>
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import SettingsService from "@/services/settings/SettingsService";
  import UserUtil from "@/util/UserUtil";

  export default {
    components: {LoadingSpinner },
    data() {
      return {
        loading: true,
        vat: {}
      };
    },
    computed: {
      isSuperAdmin(){
        return UserUtil.isSuperAdmin();
      }
    },
    mounted() {
      this.getVat();
    },
    methods: {
      getVat(){
        SettingsService.getVat().then(
            (response) => {
              this.vat = response.data;

              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      goToEditVatUrl(){
        this.$router.push({ path: 'vatEdit' });
      }
    }
  };
</script>

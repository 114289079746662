<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4 mb-4">
    <div class="bo_categories" style="max-width:597px">
        <h3>Legg til kategori</h3>
        <br/>
        <div>
          <span class="ingredient-title">Navn</span>
          <input id="name" class="form-control" type="text" v-model="category.name"/>
        </div>

        <div v-if="hasMultiLocations()">
          <span class="ingredient-title">Tilgjengelig på følgende avdelinger</span>
          <fieldset>
            <label v-for="(location, index) in locations" :key="index" class="multiple-checkbox-label">
              <input type="checkbox" :name="location.name" :value="location.id" @click="setSelectedLocations" class="multiple-checkbox"
                     :checked="category.availableAtLocationIds.indexOf(location.id) > -1" /> {{ location.name }}
            </label>
          </fieldset>
        </div>

        <div v-if="showAvailableForOrderTypes()">
          <span class="ingredient-title">Tilgjengelig for</span>
          <fieldset>
            <label v-for="(orderType, index) in orderTypes" :key="index" class="multiple-checkbox-label">
              <input type="checkbox" :name="orderType" :value="orderType" @click="setSelectedOrderTypes" class="multiple-checkbox"
                     :checked="category.availableForOrderTypes.indexOf(orderType) > -1" /> {{ getOrderTypeName(orderType) }}
            </label>
          </fieldset>
        </div>

        <div v-if="showAvailableOnDays()">
          <span class="ingredient-title">Tilgjengelig på følgende dager</span>
          <fieldset>
            <label v-for="(day, index) in days" :key="index" class="multiple-checkbox-label">
              <input type="checkbox" :name="day" :value="day" @click="setSelectedDays" class="multiple-checkbox"
                     :checked="category.availableOnDays.indexOf(day) > -1" /> {{ getDayName(day) }}
            </label>
          </fieldset>
        </div>

        <div class="mt-4">
          <div class="bo_label">Bilde</div>
          <div style="margin-top: -10px">Dette kategoribildet vises bare i selvbetjeningskiosk og kasse.</div>
          <div class="mt-4 mb-4">
              <input type="file" class="form-control" accept="image/*" ref="file" @change="selectFile" />
          </div>
        </div>

        <div>
          <span class="bo_label">Beskrivelse</span>
          <textarea id="description" class="form-control" type="text" v-model="category.description" rows="3" cols="50"></textarea>
        </div>

        <div>
          <span class="ingredient-title">Hva skal kategorien inneholde?</span>
          <select class="form-select" v-model="category.itemType">
            <option value="">Velg type</option>
            <option value="DISH">Matrett</option>
            <option value="DRINK_BUBBLE_TEA">Bubble tea</option>
            <option value="DRINK">Drikke alkoholfri</option>
            <option value="ADD_ON">Tilbehør</option> <!-- itemType ADD_ON for item in category -->
            <option v-if="offerDeliveryService" value="DELIVERY_SERVICE">Leveringstjeneste</option>
          </select>
        </div>

        <div class="bo_hr mt-4">
          <hr/>
        </div>

        <div class="mb-5">
          <button v-if="activateSaveButton" type="button" class="bo_btn" @click="saveCategory"> Lagre </button>
          <button v-else type="button" class="bo_btn" @click="saveCategory" disabled="true"> Lagre </button>
        </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import MerchantUtil from "@/util/MerchantUtil";
import ProductService from "@/services/product/ProductService";
import MediaFileService from "@/services/media/MediaFileService";
import LocationService from "@/services/location/LocationService";
import LocationUtil from "@/util/LocationUtil";

export default {
  components: {LoadingSpinner},
  data() {
    return {
      loading: false,

      locations: [],
      orderTypes: ['DINE_IN', 'TAKEAWAY'],
      days: ["MONDAY", "TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY","SUNDAY"],
      imageFile: undefined,
      category: {
        type: "category",
        merchant: "",
        sortOrder: -1,
        name: "",
        availableAtLocationIds: [],
        availableForOrderTypes: ['DINE_IN', 'TAKEAWAY'], //default checked
        availableOnDays: ["MONDAY", "TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY","SUNDAY"], //default checked
        imageUrl: "",
        description: "",
        showItemsCount: true,
        itemType: ""
      }
    };
  },
  mounted() {
    LocationService.listLocations().then(
        (response) => {
          this.locations = response.data;
          //console.log('locations: ' + JSON.stringify(this.locations));

          //All locations selected by default
          for (let i = 0; i < this.locations.length; i++) {
              let location = this.locations[i];
              this.category.availableAtLocationIds.push(location.id);
          }

          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
    );
  },
  computed: {
    activateSaveButton(){
      if(this.category.name.length > 0 && this.category.itemType.length > 0){
        return true;
      } else {
        return false;
      }
    },
    offerDeliveryService(){
      return MerchantUtil.offerDeliveryService();
    }
  },
  methods: {
    getOrderTypeName(orderType){
      if(orderType === 'DINE_IN'){
        return 'Spise Her';
      } else if(orderType === 'TAKEAWAY'){
        return 'Takeaway';
      }
    },
    selectFile(){
      this.imageFile = this.$refs.file.files[0];
      console.log("imageFile: " + this.imageFile.name)
    },
    hasMultiLocations(){
      return LocationUtil.hasMultiLocations();
    },
    showAvailableForOrderTypes(){
      if(MerchantUtil.useFunctionality() === 'POS' || MerchantUtil.useFunctionality() === 'ALL' || MerchantUtil.websiteShowDineInMenu()){
        return true;
      }else {
        return false;
      }
    },
    showAvailableOnDays(){
      return MerchantUtil.showAvailableOnDays();
    },
    setSelectedLocations(event){
      //console.log(JSON.stringify(this.category.availableAtLocationIds));
      let locationId = event.target.value;
      if(event.target.checked){
        this.category.availableAtLocationIds.push(locationId);
      } else {
        for (let i=0; i < this.category.availableAtLocationIds.length; i++){
          if (this.category.availableAtLocationIds[i] === locationId) {
            this.category.availableAtLocationIds.splice(i,1);
            break;
          }
        }
      }
      //console.log(JSON.stringify(this.category.availableAtLocationIds));
    },
    setSelectedOrderTypes(event){
      //console.log(JSON.stringify(this.category.availableForOrderTypes));
      let orderType = event.target.value;
      if(event.target.checked){
        this.category.availableForOrderTypes.push(orderType);
      } else {
        for (let i=0; i < this.category.availableForOrderTypes.length; i++){
          if (this.category.availableForOrderTypes[i] === orderType) {
            this.category.availableForOrderTypes.splice(i,1);
            break;
          }
        }
      }
      //console.log(JSON.stringify(this.category.availableForOrderTypes));
    },
    getDayName(day){
      if(day === 'MONDAY'){
        return 'Mandag';
      } else if(day === 'TUESDAY'){
        return 'Tirsdag';
      } else if(day === 'WEDNESDAY'){
        return 'Onsdag';
      } else if(day === 'THURSDAY'){
        return 'Torsdag';
      } else if(day === 'FRIDAY'){
        return 'Fredag';
      } else if(day === 'SATURDAY'){
        return 'Lørdag';
      } else if(day === 'SUNDAY'){
        return 'Søndag';
      }
      return 'DAY_NOT_DEFINED';
    },
    setSelectedDays(event){
      //console.log(JSON.stringify(this.category.availableOnDays));
      let day = event.target.value;
      if(event.target.checked){
        this.category.availableOnDays.push(day);
      } else {
        for (let i=0; i < this.category.availableOnDays.length; i++){
          if (this.category.availableOnDays[i] === day) {
            this.category.availableOnDays.splice(i,1);
            break;
          }
        }
      }
      //console.log(JSON.stringify(this.category.availableOnDays));
    },
    saveCategory(){
      this.loading = true;

      this.category.merchant = MerchantUtil.getMerchant();
      if(this.imageFile){
        MediaFileService.uploadImageFile(this.imageFile).then(
            (response) => {
              let imageUrl = response.data;
              if(imageUrl){
                //console.log("imageUrl: " + imageUrl);
                this.category.imageUrl = imageUrl;
                this.createCategory(this.category);
              } else {
                console.log("Uploading image failed");
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      } else {
        this.createCategory(this.category);
      }
    },
    createCategory(category){
      console.log('category: ' + JSON.stringify(category));
      ProductService.createCategory(category).then(
          (response) => {
            let documentId = response.data;
            if(documentId){
              this.$router.push({ path: 'products'});
            } else {
              console.log("Creating category failed");
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    }
  }
};
</script>

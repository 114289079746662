import html2canvas from "html2canvas";
import jsPDF from "jspdf";

class PdfUtil {
    generatePdf(elementId, pdfFileName) {
        const input = document.getElementById(elementId);
        html2canvas(input).then(function(canvas) {
            const imgData = canvas.toDataURL('image/png');
            //window.open(imgData); //Open image to see if canvas is rendered correctly.
            const ratio = 0.35;
            const width = canvas.width * ratio;
            const height = canvas.height * ratio;
            const pdf = new jsPDF('p', 'px', [width, height], true);
            pdf.addImage(imgData, 0, 0, width, height);
            pdf.save(pdfFileName);
        });
    }
}

export default new PdfUtil();

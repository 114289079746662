class MerchantUtil {
    getMerchant() {
        let user = JSON.parse(localStorage.getItem('user'));
        return user.merchant;
    }

    useFunctionality(){
        let user = JSON.parse(localStorage.getItem('user'));
        let merchantSetting = user.merchantSetting;
        return merchantSetting.useFunctionality;
    }

    useAddOns(){
        let user = JSON.parse(localStorage.getItem('user'));
        let merchantSetting = user.merchantSetting;
        return merchantSetting.useAddOns;
    }

    addOnAllowMultipleTimes(){
        let user = JSON.parse(localStorage.getItem('user'));
        let merchantSetting = user.merchantSetting;
        return merchantSetting.addOnAllowMultipleTimes;
    }

    websiteShowDineInMenu(){
        let user = JSON.parse(localStorage.getItem('user'));
        let merchantSetting = user.merchantSetting;
        return merchantSetting.websiteShowDineInMenu;
    }

    showAvailableOnDays(){
        let user = JSON.parse(localStorage.getItem('user'));
        let merchantSetting = user.merchantSetting;
        return merchantSetting.showAvailableOnDays;
    }

    showAvailableOnDaysHolidayOption(){
        let user = JSON.parse(localStorage.getItem('user'));
        let merchantSetting = user.merchantSetting;
        return merchantSetting.showAvailableOnDaysHolidayOption;
    }

    enableShowAsDiscount(){
        let user = JSON.parse(localStorage.getItem('user'));
        let merchantSetting = user.merchantSetting;
        return merchantSetting.enableShowAsDiscount;
    }

    showSpicyLevelSelections(){
        let user = JSON.parse(localStorage.getItem('user'));
        let merchantSetting = user.merchantSetting;
        return merchantSetting.showSpicyLevelSelections;
    }

    getLocations(){
        let user = JSON.parse(localStorage.getItem('user'));
        let locations = user.locations;
        return locations;
    }

    userProfilePageName(){
        let user = JSON.parse(localStorage.getItem('user'));
        let merchantSetting = user.merchantSetting;
        return merchantSetting.userProfilePageName;
    }

    showUsersPage(){
        let user = JSON.parse(localStorage.getItem('user'));
        let merchantSetting = user.merchantSetting;
        return merchantSetting.showUsersPage;
    }

    offerDiscount(){
        let user = JSON.parse(localStorage.getItem('user'));
        let merchantSetting = user.merchantSetting;
        return merchantSetting.offerDiscount;
    }

    payForSMS(){
        let user = JSON.parse(localStorage.getItem('user'));
        let merchantSetting = user.merchantSetting;
        return merchantSetting.payForSMS;
    }

    offerRewardProgram(){
        let user = JSON.parse(localStorage.getItem('user'));
        let merchantSetting = user.merchantSetting;
        return merchantSetting.offerRewardProgram;
    }

    offerDeliveryService(){
        let user = JSON.parse(localStorage.getItem('user'));
        let merchantSetting = user.merchantSetting;
        return merchantSetting.offerDeliveryService;
    }

}

export default new MerchantUtil();

<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4">
    <h3>Stengte dager</h3>
    <br/>
    <span v-if="useFunctionality !== 'WEBSITE'">Det skal ikke være mulig for brukere å bestille i nettbutikk for henting eller levering på stengte dager. Stengte dager kan f.eks være feriedager eller hellige dager som dere holder stengt.</span>
    <br/>
    <div class="bo_responsive-users-table-header">
          <span class="right-align">
              <button type="button" class="bo_btn" @click="goToCreatePage"> + Legg til stengte dager </button>
          </span>
    </div>

    <div class="table-responsive-sm mt-3">
        <table class="table table-striped">
        <thead>
          <tr>
              <th></th>
              <th v-if="hasMultiLocations()">Avdeling</th>
              <th>Fra dato</th>
              <th>Til dato</th>
              <th>Informasjon som vises til brukere</th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="closingDay in closingDays" :key="closingDay.id">
              <td>
                <a class="remove-user-btn"
                   @click="removeClosingDay(closingDay)">
                  <img src="@/assets/images/remove.png" width="21" title="Slett"/>
                </a>
              </td>
              <td v-if="hasMultiLocations()">
                <span>{{ getLocationName(closingDay.locationId) }}</span>
              </td>
              <td>
                {{ getDateText(closingDay.fromDate) }}
              </td>
              <td>
                {{ getDateText(closingDay.toDate) }}
              </td>
              <td>
                {{ closingDay.message }}
              </td>
            </tr>
        </tbody>
      </table>
    </div>
  </div>
  <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
</template>

<script>
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import SettingsService from "@/services/settings/SettingsService";
  import ConfirmDialogue from "@/components/other/ConfirmDialogue";
  import moment from "moment";
  import MerchantUtil from "@/util/MerchantUtil";
  import LocationUtil from "@/util/LocationUtil";

  export default {
    components: {LoadingSpinner, ConfirmDialogue},
    data() {
      return {
        loading: true,
        closingDays: []
      };
    },
    computed: {
      useFunctionality(){
        return MerchantUtil.useFunctionality();
      }
    },
    mounted() {
      this.getClosingDays();
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      getLocationName(locationId){
        let location = LocationUtil.getLocation(locationId);
        return location.name;
      },
      goToCreatePage(){
        this.$router.push({ path: 'closingDaysCreate'});
      },
      getClosingDays(){
        SettingsService.getClosingDays().then(
            (response) => {
              this.closingDays = response.data;

              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      getDateText(date){
        var dateMoment = moment(date);
        return dateMoment.format('DD.MM.YYYY');
      },
      async removeClosingDay(closingDay){
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Slett "stengte dager: ' + this.getDateText(closingDay.fromDate) + ' - ' + this.getDateText(closingDay.toDate) + '"',
          message: 'Vil du slette "stengte dager: ' + this.getDateText(closingDay.fromDate) + ' - ' + this.getDateText(closingDay.toDate) + '"?',
          okButton: 'Slett',
          cancelButton: 'Avbryt'
        })
        if(ok) {
          SettingsService.deleteClosingDay(closingDay).then(
              (response) => {
                if(response.data === 'SUCCEEDED'){
                  this.$router.go(); //Reload current page
                }else {
                  console.log("Deleting closingDay failed");
                }
              },
              (error) => {
                console.log(error);
              }
          );
        } else {
          //Cancel, do nothing
        }
      }
    }
  };
</script>

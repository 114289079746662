<template>
  <div class="mb-5 container-fluid">
    <div class="pdf-icon">
      <a @click="createPdf">
        <img src="@/assets/images/pdf.png" alt="PDF" height="32" />
      </a>
    </div>
    <div id="z-report" className="xz-report">
      <ZReportHeader :location="location" :company="company" :terminal="terminal" :zReport="zReport" />
      <ZReportTotalSales :zReport="zReport" :vat="vat" />
      <ZReportCategories :zReport="zReport" :vat="vat" />
      <ZReportSalesPayments :zReport="zReport" :vat="vat" />
      <ZReportRefund :zReport="zReport" :vat="vat" />

      <!--ZReportVariousCounters :zReport="zReport" />
      <ZReportDiff v-if="zReport.diff != 0" :zReport="zReport" /-->
    </div>
  </div>
</template>

<script>
  import ZReportHeader from "@/components/report/zreport/ZReportHeader";
  import ZReportTotalSales from "@/components/report/zreport/ZReportTotalSales";
  import ZReportCategories from "@/components/report/zreport/ZReportCategories";
  import ZReportSalesPayments from "@/components/report/zreport/ZReportSalesPayments";
  import ZReportRefund from "@/components/report/zreport/ZReportRefund";
  import PdfUtil from "@/util/PdfUtil";

  export default {
    components: {ZReportHeader, ZReportTotalSales, ZReportCategories, ZReportSalesPayments, ZReportRefund},
    props: {
      location: Object,
      terminal: Object,
      zReport: Object,
      company: Object,
      vat: Object
    },
    methods: {
      createPdf() {
        PdfUtil.generatePdf('z-report', "Z-rapport.pdf");
      }
    }
  }
</script>

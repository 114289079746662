import authHeader from "@/services/auth/AuthHeader";
import axios from "axios";

class LoyaltyService {
    getDiscounts(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/loyalty/getDiscounts' + '?merchant=' + user.merchant;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getDataForDiscountAdminGui(discountId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/loyalty/getDataForDiscountAdminGui' + '?merchant=' + user.merchant + '&discountId='+ discountId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    deleteDiscount(discount){
        let url = '/api/loyalty/deleteDiscount';
        let basicAuth = authHeader();
        return axios.post(url, discount,{
            headers: basicAuth });
    }

    createDiscount(discount){
        let url = '/api/loyalty/createDiscount';
        let basicAuth = authHeader();
        return axios.post(url, discount,{
            headers: basicAuth });
    }

    editDiscount(discount){
        let url = '/api/loyalty/editDiscount';
        let basicAuth = authHeader();
        return axios.post(url, discount,{
            headers: basicAuth });
    }

    getRewardPrograms(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/loyalty/getRewardPrograms' + '?merchant=' + user.merchant;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getDataForRewardProgramAdminGui(rewardProgramId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/loyalty/getDataForRewardProgramAdminGui' + '?merchant=' + user.merchant + '&rewardProgramId='+ rewardProgramId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    deleteRewardProgram(rewardProgram){
        let url = '/api/loyalty/deleteRewardProgram';
        let basicAuth = authHeader();
        return axios.post(url, rewardProgram,{
            headers: basicAuth });
    }

    createRewardProgram(rewardProgram){
        let url = '/api/loyalty/createRewardProgram';
        let basicAuth = authHeader();
        return axios.post(url, rewardProgram,{
            headers: basicAuth });
    }

    editRewardProgram(rewardProgram){
        let url = '/api/loyalty/editRewardProgram';
        let basicAuth = authHeader();
        return axios.post(url, rewardProgram,{
            headers: basicAuth });
    }

}

export default new LoyaltyService();

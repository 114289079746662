<template>
  <LoadingSpinner :loading='loading'> </LoadingSpinner>

  <div class="container mt-4 mb-4">
    <div style="max-width:560px">
        <h3>Merchant</h3>
        <div v-if='updated'>
          <span class='message-info'>Merchant innstillingene er oppdatert</span>
        </div>

        <div class="mt-4">
          <span class="bo_label">Logo</span>
          <input type="file" class="form-control" accept="image/*" ref="logoFile" @change="selectLogoFile" />
          <button type='button' class='bo_btn mt-2' @click='uploadLogoFile' :disabled="!activateUploadLogoFileButton"> Last opp </button>
        </div>

        <div class="mt-4">
          <span class="ingredient-title">Hvilken funksjonalitet av løsningen bruker denne merchant?</span>
          <select class="form-select" v-model="merchantSetting.useFunctionality">
            <option value="">Velg funksjonalitet</option>
            <option value="WEBSITE">Website</option>
            <option value="TAKEAWAY">Takeaway</option>
            <option value="POS">POS</option>
            <option value="ALL">All</option>
          </select>
        </div>

        <div>
          <span class="ingredient-title">Skal SPISE HER meny vises på nettsiden?</span>
          <label class="ms-3">
            <input type="radio" v-model="merchantSetting.websiteShowDineInMenu" v-bind:value="true"/> Ja
          </label>
          <br/>
          <label class="mt-2 ms-3">
            <input type="radio" v-model="merchantSetting.websiteShowDineInMenu" v-bind:value="false"/> Nei
          </label>
        </div>

        <div>
          <span class="ingredient-title">Skal "Tilgjengelig på følgende dager" vises for oppretting og redigering av produkter?</span>
          <label class="ms-3">
            <input type="radio" v-model="merchantSetting.showAvailableOnDays" v-bind:value="true"/> Ja
          </label>
          <br/>
          <label class="mt-2 ms-3">
            <input type="radio" v-model="merchantSetting.showAvailableOnDays" v-bind:value="false"/> Nei
          </label>
        </div>

        <div>
          <span class="ingredient-title">Skal "Angi de mulige valgene for sterkhetsgrad" vises for oppretting og redigering av produkter?</span>
          <label class="ms-3">
            <input type="radio" v-model="merchantSetting.showSpicyLevelSelections" v-bind:value="true"/> Ja
          </label>
          <br/>
          <label class="mt-2 ms-3">
            <input type="radio" v-model="merchantSetting.showSpicyLevelSelections" v-bind:value="false"/> Nei
          </label>
        </div>

        <div>
          <span class="ingredient-title">Skal systemet sende bekreftelsesmelding på SMS automatisk?</span>
          <label class="ms-3">
            <input type="radio" v-model="merchantSetting.takeawayAutoSmsConfirm" v-bind:value="true"/> Ja
          </label>
          <br/>
          <label class="mt-2 ms-3">
            <input type="radio" v-model="merchantSetting.takeawayAutoSmsConfirm" v-bind:value="false"/> Nei
          </label>
        </div>

        <div>
          <span class="ingredient-title">Skal systemet ta dagens oppgjør for nettbutikken automatisk?</span>
          <select class="form-select" v-model="merchantSetting.webshopAutoSettleAtTime">
            <option value="">Nei</option>
            <option value="23:55">Ja, kl. 23:55 hver dag</option>
            <option value="05:00">Ja, kl. 05:00 hver dag</option>
          </select>
        </div>

        <div>
          <span class="ingredient-title">Skal systemet merke bekreftede ordrer som fullførte ved dagens slutt?</span>
          <label class="ms-3">
            <input type="radio" v-model="merchantSetting.takeawayAutoSetOrdersAsCompleted" v-bind:value="true"/> Ja
          </label>
          <br/>
          <label class="mt-2 ms-3">
            <input type="radio" v-model="merchantSetting.takeawayAutoSetOrdersAsCompleted" v-bind:value="false"/> Nei
          </label>
        </div>

        <div class='bo_hr mt-4'>
          <hr/>
        </div>

        <div class='mb-5'>
          <button v-if='activateSaveButton' type='button' class='bo_btn' @click='save'> Lagre </button>
          <button v-else type='button' class='bo_btn' @click='save' disabled='true'> Lagre </button>
        </div>
    </div>
  </div>
</template>

<script>
  import SettingsService from '@/services/settings/SettingsService';
  import MerchantUtil from "@/util/MerchantUtil";
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import MediaFileService from "@/services/media/MediaFileService";

  export default {
    components: {LoadingSpinner},
    data() {
      return {
        loading: true,

        updated: false,
        merchantSetting: {
          type: "merchant_setting",
          merchant: '',
          logoImageFile: '',
          useFunctionality: '',
          websiteShowDineInMenu: false,
          showAvailableOnDays: false,
          showSpicyLevelSelections: false,
          takeawayAutoSmsConfirm: false,
          webshopAutoSettleAtTime: '',
          takeawayAutoSetOrdersAsCompleted: false
        }
      };
    },
    mounted() {
        this.getMerchantSetting();
    },
    computed: {
      activateUploadLogoFileButton(){
        if(this.merchantSetting.logoImageFile !== ''){
          return true;
        } else {
          return false;
        }
      },
      activateSaveButton(){
        if(this.merchantSetting.useFunctionality.length > 0){
          return true;
        } else {
          return false;
        }
      }
    },
    methods: {
      getMerchantSetting(){
        SettingsService.getMerchantSetting().then(
            (response) => {
              if(response.data !== null){
                this.merchantSetting = response.data;
                console.log(JSON.stringify(this.merchantSetting));
              }

              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      selectLogoFile(){
        this.merchantSetting.logoImageFile = this.$refs.logoFile.files[0];
        console.log("logoImageFile: " + this.merchantSetting.logoImageFile.name)
      },
      uploadLogoFile(){
        this.loading = true;
        MediaFileService.uploadImageFile(this.merchantSetting.logoImageFile).then(
            (response) => {
              let imageUrl = response.data;
              if(imageUrl){
                console.log("logoImageFile: " + imageUrl);
                this.merchantSetting.logoImageFile = imageUrl;
              } else {
                console.log("Uploading logoImageFile failed");
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      save(){
        this.loading = true;
        this.merchantSetting.merchant = MerchantUtil.getMerchant();
        this.editMerchantSetting(this.merchantSetting);
      },
      editMerchantSetting(merchantSetting){
        SettingsService.editMerchantSetting(merchantSetting).then(
            (response) => {
              let documentId = response.data;
              this.merchantSetting.id = documentId;
              if(documentId){
                this.updated = true;
              } else {
                console.log("Editing merchantSetting failed");
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      }
    }
  };
</script>

import authHeader from "@/services/auth/AuthHeader";
import axios from "axios";

class SystemService {
    getCacheInfo(){
        let url = '/api/superadmin/system/getCacheInfo';
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    emptyCache(){
        let url = '/api/superadmin/system/emptyCache';
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

}

export default new SystemService();

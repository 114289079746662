<template>
  <div :class="useAddOns ? 'container-fluid' : 'container'">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div style="max-width:1860px">
          <div class="row">
            <div :class="useAddOns ? 'col' : 'col-lg-5'">
              <Categories :categories="categories"/>
            </div>
            <div :class="useAddOns ? 'col-lg-5' : 'col-lg-7 mt-3'">
                <div v-for="itemsOfCategory in itemsOfCategories" :key="getCategoryElementId(itemsOfCategory)" class="bo_items-of-category" :id="getCategoryElementId(itemsOfCategory)">
                  <div class="bo_items_of_category_panel">
                    <a class="sort-order-btn"
                       @click="goToSortItemsPage(itemsOfCategory.id)">
                      <img src="@/assets/images/sort_order.png" width="23" title="Endre rekkefølgen av produkter"/>
                    </a>
                    <span class="bo_items-of-category-header"> {{ itemsOfCategory.name }} </span>
                    <span class="right-align">
                      <button type="button" class="bo_btn" @click="goToAddItemPage(itemsOfCategory.id)"> + Legg til produkt </button>
                    </span>
                  </div>
                  <ItemsOfCategory :items="itemsOfCategory.items" :vat="vat"/>
                </div>
            </div>
            <div v-if="useAddOns" class="col">
              <AddOnGroups :addOnGroups="addOnGroups" :addOns="addOns" :vat="vat"/>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import Categories from "@/components/product/Categories";
  import ProductService from "@/services/product/ProductService";
  import ItemsOfCategory from "@/components/product/ItemsOfCategory";
  import AddOnGroups from "@/components/product/AddOnGroups";
  import ItemUtil from "@/util/ItemUtil";
  import MerchantUtil from "@/util/MerchantUtil";

  export default {
    components: {LoadingSpinner, Categories, ItemsOfCategory, AddOnGroups},
    data() {
      return {
        loading: true,

        categories: [],
        itemsOfCategories: [],
        addOnGroups: [],
        addOns: [],
        vat: {}
      };
    },
    computed: {
      useAddOns(){
        return MerchantUtil.useAddOns();
      }
    },
    mounted() {
      ProductService.getProducts().then(
          (response) => {
            this.categories = response.data.categories;
            let items = response.data.items;
            this.vat = response.data.vat;
            this.itemsOfCategories = this.getItemsOfCategories(items, this.vat);
            this.addOnGroups = response.data.addOnGroups;
            this.addOns = response.data.addOns;

            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      getCategoryElementId(itemsOfCategory){
        let categoryElementId = 'cat_' + itemsOfCategory.id;
        return categoryElementId;
      },
      goToSortItemsPage(categoryId){
        this.$router.push({ path: 'itemsOfCategorySort', query: {categoryId: categoryId} });
      },
      goToAddItemPage(categoryId){
        this.$router.push({ path: 'itemCreate', query: {categoryId: categoryId} });
      },
      getItemsOfCategories(items, vat){
         var itemsOfCategories = [];
         for (var y = 0; y < items.length; y++) {
           //Update price to show
           items[y].price = ItemUtil.getItemSubselectionPriceRange(items[y]);
           items[y].priceTakeaway = ItemUtil.getItemSubselectionPriceRangeTakeaway(items[y], vat);
           items[y].priceDineInWithVat = ItemUtil.getItemSubselectionPriceRangeDineIn(items[y], vat);
         }
         for (var i = 0; i < this.categories.length; i++) {
           var category = this.categories[i];
           var tempItems = [];
           for (var x = 0; x < items.length; x++) {
             var item = items[x];
             if(item.categoryId == category.id){
               tempItems.push(item)
             }
           }
           //Remove already found items of category
           items = items.filter(function(item) {
             return tempItems.indexOf(item) === -1;
           });

           //Sort tempItems by asc sortOrder
           tempItems.sort(function(a, b) {
             if(a.sortOrder == b.sortOrder) { return 0; }
             if(a.sortOrder > b.sortOrder) {
               return 1;
             }else {
               return -1;
             }
           });

           var itemsOfCategory = {
             id  : category.id,
             sortOrder: category.sortOrder,
             name : category.name,
             items : tempItems
           };
           itemsOfCategories.push(itemsOfCategory);
         }

         //Sort itemsOfCategory by asc sortOrder
         itemsOfCategories.sort(function(a, b) {
           if(a.sortOrder == b.sortOrder) { return 0; }
           if(a.sortOrder > b.sortOrder) {
             return 1;
           }else {
             return -1;
           }
         });

          return itemsOfCategories;
       }
    }
  };
</script>

<style scoped>
.item-list{
  min-width: 450px;
}
</style>

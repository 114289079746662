<template>
  <div class="container">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div class="bo_categories">
        <h3>Endre rekkefølgen av valg og tillegg i gruppen</h3>
        <div class="dragndrop-info">Dra og slipp for å endre rekkefølgen på valg og tillegg i gruppen. Tilslutt klikk på "Lagre" knappen nederst.</div>

        <ul id="addOnsList" class="bo_categoriesList">
          <li v-for="addOn in addOns" :key="addOn.id" :id="addOn.id" class="category-row" > {{ addOn.menuNr }} {{ addOn.name }} </li>
        </ul>

        <div class="bo_savebtn_container">
          <hr/>
          <button type="button" class="bo_btn" @click="saveOrderOfAddOns()">Lagre</button>
        </div>
      </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import ProductService from "@/services/product/ProductService";
import Sortable from 'sortablejs';

export default {
  components: {LoadingSpinner},
  data() {
    return {
      loading: true,
      addOns : []
    };
  },
  mounted() {
    let addOnGroupId = this.$route.query.addOnGroupId;
    ProductService.getAddOnsOfAddOnGroup(addOnGroupId).then(
        (response) => {
          this.addOns = response.data;
          let el = document.getElementById('addOnsList');
          Sortable.create(el);

          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
    );
  },
  methods: {
   updateSortOrder(addOnId, sortOrder) {
      //console.log(sortOrder + ' : ' + addOnId);
      for (var i = 0; i < this.addOns.length; i++) {
        if (this.addOns[i].id == addOnId) {
          this.addOns[i].sortOrder = sortOrder;
          break; //Stop this loop, we found it!
        }
      }
    },
    saveOrderOfAddOns(){
     this.loading = true;

      let listAddOns = document.getElementById('addOnsList').getElementsByTagName('li');
      for (var i = 0; i < listAddOns.length; i++) {
        let li = listAddOns[i];
        let addOnId = li.getAttribute('id');
        this.updateSortOrder(addOnId, i);
      }

      ProductService.editAddOns(this.addOns).then(
          (response) => {
            if(response.data === "SUCCEEDED"){
              this.$router.push({ path: 'products'});
            } else {
              console.log("Updating sortOrder of addOns failed");
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    }
  }
};
</script>

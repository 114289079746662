<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4">
    <h3>Terminaler</h3>

    <div class="table-responsive-sm mt-4">
      <table class="table table-striped">
        <thead>
        <tr>
          <th v-if="hasMultiLocations()">Avdeling</th>
          <th>ID-nummer</th>
          <th>Terminal-ID</th>
          <th>Type</th>
          <th>Enhetsnavn</th>
          <th>Opprettet</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.id">
          <td v-if="hasMultiLocations()">
            <span>{{ item.locationName }}</span>
          </td>
          <td>
            <span>{{ item.systemIdNr }}</span>
          </td>
          <td>
            <span>{{ item.terminalId }}</span>
          </td>
          <td>
            <span>{{ item.terminalType }}</span>
          </td>
          <td>
            <span>{{ item.deviceName }}</span>
          </td>
          <td>
            <span>{{ item.createdDateTime }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import TerminalService from "@/services/terminal/TerminalService";
import TextUtil from "@/util/TextUtil";
import LocationUtil from "@/util/LocationUtil";

export default {
  components: {LoadingSpinner},
  data() {
    return {
      loading: true,
      items: []
    };
  },
  mounted() {
    TerminalService.getTerminals().then(
        (response) => {
          let terminals = response.data;
          for (var i = 0; i < terminals.length; i++) {
            let terminal = terminals[i];
            this.items.push({
              'locationName': this.getLocationName(terminal.locationId),
              'systemIdNr': terminal.systemIdNr,
              'terminalId': terminal.terminalId,
              'terminalType': terminal.terminalType,
              'deviceName': terminal.deviceName,
              'createdDateTime': TextUtil.getCreatedTimeText(terminal.createdDateTime)
            });
          }

          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
    );
  },
  methods:{
    hasMultiLocations(){
      return LocationUtil.hasMultiLocations();
    },
    getLocationName(locationId){
      let location = LocationUtil.getLocation(locationId);
      return location.name;
    }
  }
};
</script>

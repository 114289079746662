<template>
  <div class="container-fluid">
    <table class="xz-table">
      <thead>
      <tr>
        <th class="bold">Betalingsmiddel</th>
        <th class="bold">Antall</th>
        <th class="bold"></th>
        <th class="bold">Beløp</th>
      </tr>
      </thead>
      <tbody>

      <tr>
        <td colSpan="4"><b>Kontant</b></td>
      </tr>
      <tr>
        <td>Ta med</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>
      <tr>
        <td>Spise her</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>
      <tr>
        <td>Levere</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>
      <tr>
        <td>Total kontant</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>

      <tr>
        <td colSpan="4"><b>Vipps</b></td>
      </tr>
      <tr>
        <td>Ta med </td>
        <td>{{ xReportCounters.vippsPickupCount }}</td>
        <td></td>
        <td>{{ displayTwoDecimals(xReportCounters.vippsPickupPaymentsTotalAmount) }}</td>
      </tr>
      <tr>
        <td>Spise her</td>
        <td>{{ xReportCounters.vippsDineInCount }}</td>
        <td></td>
        <td>{{ displayTwoDecimals(xReportCounters.vippsDineInPaymentsTotalAmount) }}</td>
      </tr>
      <tr>
        <td>Levere</td>
        <td>{{ xReportCounters.vippsDeliveryCount }}</td>
        <td></td>
        <td>{{ displayTwoDecimals(xReportCounters.vippsDeliveryPaymentsTotalAmount) }}</td>
      </tr>
      <tr>
        <td>Total Vipps</td>
        <td>{{ vippsTotalCount }}</td>
        <td></td>
        <td>{{ displayTwoDecimals(vippsPaymentsTotalAmount) }}</td>
      </tr>

      <tr>
        <td colSpan="4"><b>Kort</b></td>
      </tr>
      <tr>
        <td>Ta med</td>
        <td>{{ xReportCounters.cardPickupCount }}</td>
        <td></td>
        <td>{{ displayTwoDecimals(xReportCounters.cardPickupPaymentsTotalAmount) }}</td>
      </tr>
      <tr>
        <td>Spise her</td>
        <td>{{ xReportCounters.cardDineInCount }}</td>
        <td></td>
        <td>{{ displayTwoDecimals(xReportCounters.cardDineInPaymentsTotalAmount) }}</td>
      </tr>
      <tr>
        <td>Levere</td>
        <td>{{ xReportCounters.cardDeliveryCount }}</td>
        <td></td>
        <td>{{ displayTwoDecimals(xReportCounters.cardDeliveryPaymentsTotalAmount) }}</td>
      </tr>
      <tr>
        <td>Total kort</td>
        <td>{{ cardTotalCount }}</td>
        <td></td>
        <td>{{ displayTwoDecimals(cardPaymentsTotalAmount) }}</td>
      </tr>

      <tr>
        <td class="bold">Total salg</td>
        <td class="bold">{{ totalPaymentsCount }}</td>
        <td></td>
        <td class="bold">{{ displayTwoDecimals(totalPayments) }}</td>
      </tr>
      </tbody>
    </table>
    <hr class="horizontal-dashed"/>
  </div>
</template>

<script>
  import MathUtil from "@/util/MathUtil";

  export default {
    props: {
      xReportCounters: Object,
      vat: Object
    },
    computed: {
      vippsTotalCount() {
        let vippsPickupCount = this.xReportCounters.vippsPickupCount;
        let vippsDineInCount = this.xReportCounters.vippsDineInCount;
        return (vippsPickupCount + vippsDineInCount);
      },
      vippsPaymentsTotalAmount() {
        return this.xReportCounters.vippsPickupPaymentsTotalAmount + this.xReportCounters.vippsDineInPaymentsTotalAmount;
      },

      cardTotalCount() {
        let cardPickupCount = this.xReportCounters.cardPickupCount;
        let cardDineInCount = this.xReportCounters.cardDineInCount;
        return (cardPickupCount + cardDineInCount);
      },

      cardPaymentsTotalAmount() {
        return this.xReportCounters.cardPickupPaymentsTotalAmount + this.xReportCounters.cardDineInPaymentsTotalAmount;
      },

      totalPaymentsCount() {
        return this.vippsTotalCount + this.cardTotalCount;
      },
      totalPayments() {
        return (this.xReportCounters.vippsPickupPaymentsTotalAmount + this.xReportCounters.vippsDineInPaymentsTotalAmount
            + this.xReportCounters.cardPickupPaymentsTotalAmount + this.xReportCounters.cardDineInPaymentsTotalAmount);
      }
    },
    methods: {
      displayTwoDecimals(num){
        if(num > 0 || num < 0){
          return MathUtil.displayTwoDecimals(num);
        }else {
          return num;
        }
      }
    }
  }
</script>

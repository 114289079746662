<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container">
    <div class="bo_categories" style="max-width:597px">
      <h3>Endre rekkefølgen av grupper for valg og tillegg</h3>
      <div class="dragndrop-info">Dra og slipp for å endre rekkefølgen på gruppene. Tilslutt klikk på "Lagre" knappen nederst.</div>

      <ul id="addOnGroupsList" class="bo_categoriesList">
        <li v-for="addOnGroup in addOnGroups" :key="addOnGroup.id" :id="addOnGroup.id" class="addOnGroup-row" > {{ addOnGroup.name }} </li>
      </ul>

      <div class="bo_savebtn_container">
        <hr/>
        <button type="button" class="bo_btn" @click="saveOrderOfCategories">Lagre</button>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import ProductService from "@/services/product/ProductService";
import Sortable from 'sortablejs';

export default {
  components: {LoadingSpinner},
  data() {
    return {
      loading: true,
      addOnGroups : []
    };
  },
  mounted() {
    ProductService.getAddOnGroups().then(
        (response) => {
          this.addOnGroups = response.data;

          let el = document.getElementById('addOnGroupsList');
          Sortable.create(el);

          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
    );
  },
  methods: {
    updateSortOrder(addOnGroupId, sortOrder) {
      //console.log(sortOrder + ' : ' + addOnGroupId);
      for (var i = 0; i < this.addOnGroups.length; i++) {
        if (this.addOnGroups[i].id == addOnGroupId) {
          this.addOnGroups[i].sortOrder = sortOrder;
          break; //Stop this loop, we found it!
        }
      }
    },
    saveOrderOfCategories(){
      this.loading = true;

      let listItems = document.getElementById('addOnGroupsList').getElementsByTagName('li');
      for (var i = 0; i < listItems.length; i++) {
        let li = listItems[i];
        let addOnGroupId = li.getAttribute('id');
        this.updateSortOrder(addOnGroupId, i);
      }

      ProductService.editAddOnGroups(this.addOnGroups).then(
          (response) => {
            if(response.data === "SUCCEEDED"){
              this.$router.push({ path: 'products'});
            } else {
              console.log("Updating sortOrder of addOnGroups failed");
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    }
  }
};
</script>

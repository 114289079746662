<template>
  <LoadingSpinner :loading='loading'> </LoadingSpinner>

  <div class="container mt-4 mb-4">
    <div style="max-width:560px">
      <div v-if="hasMultiLocations()" class="mt-5">
        <span class="ingredient-title">Velg avdeling</span>
        <select class="form-select" v-model="locationId" @change="getCloudprntDocument()">
          <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
        </select>
      </div>

      <div v-if="locationId != ''" class="mt-5">
        <h3>Merchant: {{ cloudprnt.merchant }}</h3>
        <h3>Avdeling: {{ getLocationName()}}</h3>

        <div class="mt-3">
          <h5>cloudprnt document:</h5>
          <span style="white-space: pre-wrap;">
            {{ cloudprntDocumentText() }}
          </span>
        </div>

        <div class='bo_hr mt-4'>
          <hr/>
        </div>

        <div class='mb-5'>
          <button type='button' class='bo_btn' @click='emptyJobs'> Empty currentJobs & jobsPrinted </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SupportService from '@/services/support/SupportService';
  import MerchantUtil from "@/util/MerchantUtil";
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import LocationUtil from "@/util/LocationUtil";

  export default {
    components: {LoadingSpinner},
    data() {
      return {
        loading: false,
        updated: false,

        locationId: '',
        locations: [],

        cloudprnt: {
          id: "",
          merchant: "",
          locationId: "",
          type: "cloudprnt",
          jobsPrinted: [],
          currentJobs: [],
          modifiedTime: "",
          lastCleanedUpTime: "2"
        }
      };
    },
    mounted() {
      this.locations = MerchantUtil.getLocations();
      this.locationId = this.locations.length ? this.locations[0].id : '';
      this.getCloudprntDocument();
    },
    computed: {

    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      getLocationName(){
        let location = LocationUtil.getLocation(this.locationId);
        return location.name;
      },
      getCloudprntDocument(){
        this.loading = true;

        SupportService.getCloudPrntDocument(this.locationId).then(
            (response) => {
              if(response.data !== null){
                this.cloudprnt = response.data;
                console.log(JSON.stringify(this.cloudprnt));
              }

              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      cloudprntDocumentText(){
          return JSON.stringify(this.cloudprnt, null, 2); //Pretty `JSON.stringify()` Output
      },
      emptyJobs(){
        this.loading = true;

        SupportService.emptyJobs(this.locationId).then(
            (response) => {
              if(response.data !== null){
                this.cloudprnt = response.data;
              }

              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      }
    }
  };
</script>

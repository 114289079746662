class WebshopZReportUtil {
    getCashTotalCount(zReport){
        var cashPickupCount = zReport.cashPickupCount;
        var cashDeliveryCount = zReport.cashDeliveryCount;
        return (cashPickupCount + cashDeliveryCount);
    }

    getCashTotalVat(zReport){
        var cashDineInVat = zReport.cashDineInVat;
        var cashPickupVat = zReport.cashPickupVat;
        var cashDeliveryVat = zReport.cashDeliveryVat;
        var cashDeliveryServiceVat =  zReport.cashDeliveryServiceVat;
        return Math.round(cashDineInVat + cashPickupVat + cashDeliveryVat + cashDeliveryServiceVat);
    }

    getCashTotalBrutto(zReport){
        var cashDineInBrutto = zReport.cashDineInBrutto;
        var cashPickupBrutto = zReport.cashPickupBrutto;
        var cashDeliveryBrutto = zReport.cashDeliveryBrutto;
        var cashDeliveryServiceBrutto = zReport.cashDeliveryServiceBrutto;
        return Math.round(cashDineInBrutto + cashPickupBrutto + cashDeliveryBrutto + cashDeliveryServiceBrutto);
    }

    getVippsTotalCount(zReport){
        var vippsPickupCount = zReport.vippsPickupCount;
        var vippsDeliveryCount = zReport.vippsDeliveryCount;
        return (vippsPickupCount + vippsDeliveryCount);
    }

    getVippsTotalVat(zReport){
        var vippsDineInVat = zReport.vippsDineInVat;
        var vippsPickupVat = zReport.vippsPickupVat;
        var vippsDeliveryVat = zReport.vippsDeliveryVat;
        var vippsDeliveryServiceVat =  zReport.vippsDeliveryServiceVat;
        return Math.round(vippsDineInVat + vippsPickupVat + vippsDeliveryVat + vippsDeliveryServiceVat);
    }

    getVippsTotalBrutto(zReport){
        var vippsDineInBrutto = zReport.vippsDineInBrutto;
        var vippsPickupBrutto = zReport.vippsPickupBrutto;
        var vippsDeliveryBrutto = zReport.vippsDeliveryBrutto;
        var vippsDeliveryServiceBrutto = zReport.vippsDeliveryServiceBrutto;
        return Math.round(vippsDineInBrutto + vippsPickupBrutto + vippsDeliveryBrutto + vippsDeliveryServiceBrutto);
    }

    getCardTotalCount(zReport){
        var cardPickupCount = zReport.cardPickupCount;
        var cardDeliveryCount = zReport.cardDeliveryCount;
        return (cardPickupCount + cardDeliveryCount);
    }

    getCardTotalVat(zReport){
        var cardDineInVat = zReport.cardDineInVat;
        var cardPickupVat = zReport.cardPickupVat;
        var cardDeliveryVat = zReport.cardDeliveryVat;
        var cardDeliveryServiceVat =  zReport.cardDeliveryServiceVat;
        return Math.round(cardDineInVat + cardPickupVat + cardDeliveryVat + cardDeliveryServiceVat);
    }

    getCardTotalBrutto(zReport){
        var cardDineInBrutto = zReport.cardDineInBrutto;
        var cardPickupBrutto = zReport.cardPickupBrutto;
        var cardDeliveryBrutto = zReport.cardDeliveryBrutto;
        var cardDeliveryServiceBrutto = zReport.cardDeliveryServiceBrutto;
        return Math.round(cardDineInBrutto + cardPickupBrutto + cardDeliveryBrutto + cardDeliveryServiceBrutto);
    }

    getTotalTakeawayVat(zReport){
        var cashPickupVat = zReport.cashPickupVat;
        var cashDeliveryVat = zReport.cashDeliveryVat;
        var vippsPickupVat = zReport.vippsPickupVat;
        var vippsDeliveryVat = zReport.vippsDeliveryVat;
        var cardPickupVat = zReport.cardPickupVat;
        var cardDeliveryVat = zReport.cardDeliveryVat;
        return Math.round(cashPickupVat + cashDeliveryVat+ vippsPickupVat + vippsDeliveryVat + cardPickupVat + cardDeliveryVat);
    }

    getTotalTakeawayBrutto(zReport){
        var cashPickupBrutto = zReport.cashPickupBrutto;
        var cashDeliveryBrutto = zReport.cashDeliveryBrutto;
        var vippsPickupBrutto = zReport.vippsPickupBrutto;
        var vippsDeliveryBrutto = zReport.vippsDeliveryBrutto;
        var cardPickupBrutto = zReport.cardPickupBrutto;
        var cardDeliveryBrutto = zReport.cardDeliveryBrutto
        return Math.round(cashPickupBrutto + cashDeliveryBrutto + vippsPickupBrutto + vippsDeliveryBrutto + cardPickupBrutto + cardDeliveryBrutto);
    }

    getTotalTakeawayNetto(zReport){
        return (this.getTotalTakeawayBrutto(zReport) - this.getTotalTakeawayVat(zReport));
    }

    getTotalServiceVat(zReport){
        var cashDineInVat = zReport.cashDineInVat;
        var cashDeliveryServiceVat = zReport.cashDeliveryServiceVat;
        var vippsDineInVat = zReport.vippsDineInVat;
        var vippsDeliveryServiceVat = zReport.vippsDeliveryServiceVat;
        var cardDineInVat = zReport.cardDineInVat;
        var cardDeliveryServiceVat = zReport.cardDeliveryServiceVat;
        return Math.round(cashDineInVat + cashDeliveryServiceVat + vippsDineInVat + vippsDeliveryServiceVat + cardDineInVat + cardDeliveryServiceVat);
    }

    getTotalServiceBrutto(zReport){
        var cashDineInBrutto = zReport.cashDineInBrutto;
        var cashDeliveryServiceBrutto = zReport.cashDeliveryServiceBrutto;
        var vippsDineInBrutto = zReport.vippsDineInBrutto;
        var vippsDeliveryServiceBrutto = zReport.vippsDeliveryServiceBrutto;
        var cardDineInBrutto = zReport.cardDineInBrutto;
        var cardDeliveryServiceBrutto = zReport.cardDeliveryServiceBrutto;
        return Math.round(cashDineInBrutto + cashDeliveryServiceBrutto + vippsDineInBrutto + vippsDeliveryServiceBrutto + cardDineInBrutto + cardDeliveryServiceBrutto);
    }

    getTotalServiceNetto(zReport){
        return (this.getTotalServiceBrutto(zReport) - this.getTotalServiceVat(zReport));
    }

    getTotalSalesVat(zReport){
        return (this.getTotalTakeawayVat(zReport) + this.getTotalServiceVat(zReport));
    }

    getTotalSalesNetto(zReport){
        return (this.getTotalTakeawayNetto(zReport) + this.getTotalServiceNetto(zReport));
    }

    getTotalSalesBrutto(zReport){
        return (this.getTotalTakeawayBrutto(zReport) + this.getTotalServiceBrutto(zReport));
    }
}

export default new WebshopZReportUtil();
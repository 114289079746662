<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4">
    <div style="max-width:597px">
        <h3>Rediger merverdiavgift (MVA)</h3>
        <br/>
        <span>Merverdiavgift(MVA) er fastsatt av Skatteetaten. Vær sikker på at du legger inn riktige verdier på MVA.</span>
        <br/>

        <div>
          <span class="ingredient-title">Spise her (%)</span>
          <input class="form-control" type="number" v-model="vat.dineIn" />
        </div>

        <div>
          <span class="ingredient-title">Ta med (%)</span>
          <input class="form-control" type="number" v-model="vat.takeOut" />
        </div>

        <div>
          <span class="ingredient-title">Levere (%)</span>
          <input class="form-control" type="number" v-model="vat.delivery" />
        </div>

        <div class="bo_hr mt-4">
          <hr/>
        </div>

        <div class="mb-5">
          <button v-if="activateSaveButton" type="button" class="bo_btn" @click="save"> Lagre </button>
          <button v-else type="button" class="bo_btn" @click="save" disabled="true"> Lagre </button>
        </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import SettingsService from "@/services/settings/SettingsService";

export default {
  components: {LoadingSpinner},
  data() {
    return {
      loading: true,
      vat: {}
    };
  },
  mounted() {
    this.getVat();
  },
  computed: {
    activateSaveButton(){
      if(this.vat.dineIn > 0 && this.vat.takeOut > 0 && this.vat.delivery > 0){
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    getVat(){
      SettingsService.getVat().then(
          (response) => {
            this.vat = response.data;

            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    save(){
      this.loading = true;

      this.vat.updatedDateTime = (new Date()).toISOString();
      //console.log('vat: ' + JSON.stringify(this.vat));
      SettingsService.editVat(this.vat).then(
          (response) => {
            let documentId = response.data;
            if(documentId){
              this.$router.push({ path: 'vat'});
            } else {
              console.log("Editing vat failed");
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    }
  }
};
</script>

<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4 mb-4">
    <div class="bo_categories" style="max-width:597px">
        <h3>Legg til gruppe</h3>
        <br/>
        <div>
          <span class="ingredient-title">Navn</span>
          <input class="form-control" type="text" v-model="addOnGroup.name"/>
        </div>

        <div>
          <span class="ingredient-title">Tittel (teksten som vises for brukere)</span>
          <input class="form-control" type="text" v-model="addOnGroup.title"/>
        </div>

        <div>
          <span class="bo_label">Beskrivelse</span>
          <textarea class="form-control" type="text" v-model="addOnGroup.description" rows="3" cols="50"></textarea>
        </div>

        <div>
          <span class="ingredient-title">Er gruppen valgfri eller obligatorisk?</span>
          <select class="form-select" v-model="addOnGroup.mandatory">
            <option :value="false">Valgfri</option>
            <option :value="true">Obligatorisk</option>
          </select>
        </div>

        <div v-if="addOnGroup.mandatory">
            <div>
              <span class="ingredient-title">Krev minimum</span>
              <input type="number" min="0" v-model="addOnGroup.minimum" />
            </div>
            <div>
              <span class="ingredient-title">Krev maksimum</span>
              <input type="number" min="0" v-model="addOnGroup.maximum" />
            </div>
        </div>

        <div class="bo_hr mt-4">
          <hr/>
        </div>

        <div class="mb-5">
          <button v-if="activateSaveButton" type="button" class="bo_btn" @click="saveAddOnGroup"> Lagre </button>
          <button v-else type="button" class="bo_btn" @click="saveAddOnGroup" disabled="true"> Lagre </button>
        </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import MerchantUtil from "@/util/MerchantUtil";
import ProductService from "@/services/product/ProductService";

export default {
  components: {LoadingSpinner},
  data() {
    return {
      loading: false,

      addOnGroup: {
        type: "add-on_group",
        merchant: "",
        sortOrder: -1,
        name: "",
        title: "",
        description: "",
        mandatory: false,
        minimum: 0,
        maximum: 0
      }
    };
  },
  computed: {
    activateSaveButton(){
      if(this.addOnGroup.name.length > 0 && this.addOnGroup.title.length > 0){
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    saveAddOnGroup(){
      this.loading = true;

      this.addOnGroup.merchant = MerchantUtil.getMerchant();
      this.createAddOnGroup(this.addOnGroup);
    },
    createAddOnGroup(addOnGroup){
      console.log('addOnGroup: ' + JSON.stringify(addOnGroup));
      ProductService.createAddOnGroup(addOnGroup).then(
          (response) => {
            let documentId = response.data;
            if(documentId){
              this.$router.push({ path: 'products'});
            } else {
              console.log("Creating addOnGroup failed");
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    }
  }
};
</script>

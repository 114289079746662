<template>
  <div class="container">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div v-if="!loading" class="mt-4" style="max-width:597px">
        <h3>Rediger bonusprogram</h3>
        <p class="mt-4">Hvis programmet skal gjelde i kun én dag, velger du samme dag på gyldig-fra-og-med-dato og til-og-med-dato.</p>

        <div>
          <span class="bo_label">Programnavn</span>
          <input type='text' class="form-control" v-model="rewardProgram.name" />
        </div>

        <div>
          <span class="bo_label">Prosent(%)</span>
          <input class="form-control" type="number" placeholder="f.eks 5" min="0" v-model="rewardProgram.percent" />
        </div>

        <SelectDate ref="selectDateFrom" label="Gyldig fra og med dato" :selectedDate="rewardProgram.fromDate" />
        <SelectDate ref="selectDateTo" label="Til og med dato" :selectedDate="rewardProgram.toDate" />

        <div v-if="hasMultiLocations()">
          <span class="ingredient-title">Gjelder for følgende avdelinger</span>
          <fieldset>
            <label v-for="(location, index) in locations" :key="index" class="multiple-checkbox-label">
              <input type="checkbox" :name="location.name" :value="location.id" @click="setSelectedLocations" class="multiple-checkbox"
                     :checked="rewardProgram.validForLocationIds.indexOf(location.id) > -1" /> {{ location.name }}
            </label>
          </fieldset>
        </div>

        <hr/>
        <button v-if="activateSaveButton" type="button" class="bo_btn" @click="save"> Lagre </button>
        <button v-else type="button" class="bo_btn" @click="save" disabled="true"> Lagre </button>
      </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import SelectDate from "@/components/other/SelectDate";
import MerchantUtil from "@/util/MerchantUtil";
import LoyaltyService from "@/services/loyalty/LoyaltyService";
import moment from "moment";
import LocationUtil from "@/util/LocationUtil";

export default {
    components: {LoadingSpinner, SelectDate},
    data() {
      return {
        loading: true,
        locations: [],

        rewardProgramId: '',
        rewardProgram : {
            merchant: '',
            type: "loyalty_reward_program",
            name: '',
            percent: '',
            fromDate: '',
            toDate: '',
            validForLocationIds: [],
            active: true
        }
      };
    },
    computed: {
      activateSaveButton(){
        if(this.rewardProgram.name.length > 0 && this.rewardProgram.percent > 0){
          return true;
        } else {
          return false;
        }
      }
    },
    mounted() {
      this.rewardProgramId = this.$route.query.rewardProgramId;
      LoyaltyService.getDataForRewardProgramAdminGui(this.rewardProgramId).then(
          (response) => {
            let dataForGui = response.data;

            this.locations = dataForGui.locations;
            this.rewardProgram = dataForGui.rewardProgram;

            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      setSelectedLocations(event){
        //console.log(JSON.stringify(this.rewardProgram.validForLocationIds));
        let locationId = event.target.value;
        if(event.target.checked){
          this.rewardProgram.validForLocationIds.push(locationId);
        } else {
          for (let i=0; i < this.rewardProgram.validForLocationIds.length; i++){
            if (this.rewardProgram.validForLocationIds[i] == locationId) {
              this.rewardProgram.validForLocationIds.splice(i,1);
              break;
            }
          }
        }
        //console.log(JSON.stringify(this.rewardProgram.validForLocationIds));
      },
      save(){
        this.loading = true;

        let fromDate =  this.$refs.selectDateFrom.getSelectedDate();
        let toDate =  this.$refs.selectDateTo.getSelectedDate();
        this.rewardProgram.merchant = MerchantUtil.getMerchant();
        this.rewardProgram.fromDate = (moment(fromDate)).format('YYYY-MM-DD');
        this.rewardProgram.toDate = (moment(toDate)).format('YYYY-MM-DD');

        this.editRewardProgram(this.rewardProgram);
      },
      editRewardProgram(rewardProgram){
        LoyaltyService.editRewardProgram(rewardProgram).then(
            (response) => {
              let documentId = response.data;
              if(documentId){
                this.$router.push({ path: 'rewards' });
              } else {
                console.log("Editing rewardProgram failed");
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      }
    }
  };
</script>

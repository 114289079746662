<template>
  <div class="container-fluid">
    <table class="xz-table">
      <thead>
      <tr>
        <th class="bold">Kategori</th>
        <th class="bold">MVA%</th>
        <th class="bold">MVA</th>
        <th class="bold">Brutto</th>
      </tr>
      </thead>
      <tbody>

      <tr v-for="category in zReport.categories" :key="category.id">
        <td>{{ category.name }}</td>
        <td>{{ category.vatRate }}%</td>
        <td>{{ getVatAmount(category) }}</td>
        <td>{{ displayTwoDecimals(category.amountWithVat) }}</td>
      </tr>

      </tbody>
    </table>
    <hr class="horizontal-dashed"/>
  </div>
</template>

<script>
  import MathUtil from "@/util/MathUtil";

  export default {
    props: {
      zReport: Object,
      vat: Object
    },
    methods: {
      getVatAmount(category){
        let vatAmount = (category.amountWithVat / (100 + category.vatRate)) * category.vatRate;
        return this.displayTwoDecimals(vatAmount);
      },
      displayTwoDecimals(num){
        if(num > 0 || num < 0){
          return MathUtil.displayTwoDecimals(num);
        }else {
          return num;
        }
      }
    }
  }
</script>

<template>
  <div class="mt-4 mb-4 sidebar">
    <div class="component">
      <div class="bo_categories-header">
        <a class="sort-order-btn"
           @click="goToSortAddOnGroupsPage">
          <img src="@/assets/images/sort_order.png" width="23" title="Endre rekkefølgen av grupper" />
        </a>
        <span class="element-title">Grupper for valg og tillegg</span>
        <span class="right-align">
          <button type="button" class="bo_btn" @click="goToAddAddOnGroupPage"> + Legg til gruppe </button>
        </span>
      </div>
      <div class="content">
        <ul class="bo_categoriesList">
          <li v-for="addOnGroup in addOnGroups" :key="addOnGroup.id" class="addOnGroup-row">
            <a class="remove-addOnGroup-btn"
               @click="removeAddOnGroup(addOnGroup)">
              <img src="@/assets/images/remove.png" width="21" title="Slett gruppe"/>
            </a>

            <span class="add-on-group-name" @click="expandOrCollapseContent(addOnGroup.id)">{{ addOnGroup.name }}</span>
            <span v-if="isAddOnGroupExpanded(addOnGroup.id)" class="ms-2" @click="expandOrCollapseContent(addOnGroup.id)"><font-awesome-icon :icon="['fas', 'chevron-up']"/></span>
            <span v-else class="ms-2" @click="expandOrCollapseContent(addOnGroup.id)"><font-awesome-icon :icon="['fas', 'chevron-down']"/></span>

            <div class="edit-category-btn">
              <a class="me-3"
                 @click="goToSortAddOnsOfAddOnGroupPage(addOnGroup.id)">
                <img src="@/assets/images/sort_order.png" width="23" title="Endre rekkefølgen av valg og tillegg" />
              </a>
              <a @click="editAddOnGroup(addOnGroup.id)">
                <img src="@/assets/images/edit.png" width="21" title="Rediger gruppe"/>
              </a>
              <span class="ms-3">
                <button type="button" class="bo_btn" @click="goToAddAddOnPage(addOnGroup.id)"> + Legg til valg </button>
              </span>
            </div>

            <div :class="expandedAddOnGroupIds.includes(addOnGroup.id)? 'addOns' : 'd-none'">
              <div v-for="addOn in getAddOns(addOnGroup.id)" :key="addOn.id" class="addOn">
                <div class="d-flex align-items-center">
                    <div>
                        <a class="remove-addOn-btn"
                           @click="removeAddOn(addOn)">
                          <img src="@/assets/images/remove.png" width="21" title="Slett valg"/>
                        </a>
                    </div>
                    <div>
                      <div>{{ addOn.name }}</div>
                      <div><span class="price">kr {{ getPriceWithVat(addOn.price) }} </span><span> (med {{vat.takeOut}}% mva for takeaway)</span></div>
                    </div>
                    <div class="edit-category-btn">
                      <a @click="editAddOn(addOnGroup.id, addOn.id)">
                        <img src="@/assets/images/edit.png" width="21" title="Rediger valg"/>
                      </a>
                    </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
</template>

<script>
import ConfirmDialogue from '@/components/other/ConfirmDialogue.vue'
import ProductService from "@/services/product/ProductService";
import ItemUtil from "@/util/ItemUtil";

export default {
  components: {ConfirmDialogue},
  props: {
    addOnGroups: Array,
    addOns: Array,
    vat: Object
  },
  data () {
    return {
      expandedAddOnGroupIds: []
    }
  },
  methods: {
      getPriceWithVat(priceWithoutVat){
        return ItemUtil.getPriceWithVat(priceWithoutVat, this.vat);
      },
      getAddOns(addOnGroupId) {
        let addOns = [];
        for (let i = 0; i < this.addOns.length; i++) {
          let addOn = this.addOns[i];
          if(addOn.addOnGroupId == addOnGroupId){
            addOns.push(addOn)
          }
        }
        return addOns;
      },
      expandOrCollapseContent(addOnGroupId){
         if(this.expandedAddOnGroupIds.includes(addOnGroupId)){//currently expanded
           //Collapse
            const index = this.expandedAddOnGroupIds.indexOf(addOnGroupId);
            this.expandedAddOnGroupIds.splice(index, 1);
         } else {
            //expand
            this.expandedAddOnGroupIds.push(addOnGroupId);
         }
      },
      isAddOnGroupExpanded(addOnGroupId){
          return this.expandedAddOnGroupIds.includes(addOnGroupId);
      },
      goToSortAddOnGroupsPage(){
        this.$router.push({ path: 'addOnGroupsSort'});
      },
      goToSortAddOnsOfAddOnGroupPage(addOnGroupId){
        this.$router.push({ path: 'addOnsOfAddOnGroupSort', query: {addOnGroupId: addOnGroupId} });
      },
      goToAddAddOnGroupPage(){
        this.$router.push({ path: 'addOnGroupCreate'});
      },
      goToAddAddOnPage(addOnGroupId){
        this.$router.push({ path: 'addOnCreate', query: {addOnGroupId: addOnGroupId} });
      },
      async removeAddOnGroup(addOnGroup){
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Slett gruppen "' + addOnGroup.name + '"',
          message: 'Vil du slette gruppen "' + addOnGroup.name + '"' + ' og dens tilhørende valg og tillegg?',
          okButton: 'Slett',
          cancelButton: 'Avbryt'
        })
        if(ok) {
          ProductService.deleteAddOnGroup(addOnGroup).then(
              (response) => {
                if(response.data != null){
                  this.$router.go(); //Reload current page
                }else {
                  console.log("Deleting addOnGroup failed");
                }
              },
              (error) => {
                console.log(error);
              }
          );
        } else {
          //Cancel, do nothing
        }
      },
    async removeAddOn(addOn){
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Slett valget "' + addOn.name.trim() + '"',
        message: 'Vil du slette valget "' + addOn.name.trim() + '"' + '?',
        okButton: 'Slett',
        cancelButton: 'Avbryt'
      })
      if(ok) {
        ProductService.deleteAddOn(addOn).then(
            (response) => {
              if(response.data != null){
                this.$router.go(); //Reload current page
              }else {
                console.log("Deleting addOnGroup failed");
              }
            },
            (error) => {
              console.log(error);
            }
        );
      } else {
        //Cancel, do nothing
      }
    },
    editAddOnGroup(addOnGroupId){
      this.$router.push({ path: 'addOnGroupEdit', query: {
          addOnGroupId: addOnGroupId
        }
      });
    },
    editAddOn(addOnGroupId, addOnId){
      this.$router.push({ path: 'addOnEdit', query: {
          addOnGroupId: addOnGroupId,
          addOnId: addOnId
        }
      });
    }
  }
}
</script>

<style scoped>
  /**
   * lg: 992px breakpoint or larger
   */
  @media screen and (min-width: 992px) {
    .bo_categories-header{
      padding-right: 5px;
    }

    .sidebar {
      position: sticky;
      top: 0px;
    }
    .component {
      display: grid;
      grid-template-rows: auto 1fr auto;
    }
    .component .content {
      overflow-y: auto;
    }
    .sidebar .component {
      max-height: calc(100vh - 5px);
    }
  }
</style>

import AuthService from '@/services/auth/AuthService';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        response => {
          let returnUser = response;
          if(returnUser !== null){
            commit('loginSuccess', returnUser);
            return Promise.resolve(returnUser);
          } else {
            commit('loginFailure');
            return Promise.reject(returnUser);
          }
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    superAdminLogin({ commit }, user) {
      return AuthService.superAdminlogin(user).then(
          response => {
            let returnUser = response;
            if(returnUser !== null){
              commit('loginSuccess', returnUser);
              return Promise.resolve(returnUser);
            } else {
              commit('loginFailure');
              return Promise.reject(returnUser);
            }
          },
          error => {
            commit('loginFailure');
            return Promise.reject(error);
          }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    }
  },
  mutations: {
    loginSuccess(state, user) {
      if(user !== null){
        state.status.loggedIn = true;
        state.user = user;
      } else {
        state.status.loggedIn = false;
        state.user = null;
      }
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    }
  }
};

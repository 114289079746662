<template>
  <div class="item">
    <div class="cart-buttons-panel">
      <a class="add-to-cart-btn"
         @click="removeItem">
        <img src="@/assets/images/remove.png" width="21" title="Slett produkt"/>
      </a>
      <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
    </div>
    <div class="item-col-left">
      <span> <img :src="item.imageUrl" width="100" height="100"/> </span>
    </div>
    <div class="item-col-right">
      <div class="flex-box">
        <div>
          <span class="item-title">{{ item.menuNr }} &nbsp;&nbsp;  {{item.name}} </span>
          <br/>
          <span class="description">{{item.description}}</span><br/>
          <div class="mb-2">
            <span>Tilgjengelig: </span><span v-for="(day, index) in item.availableOnDays" :key="index">{{ ' ' + getDayName(day) }}</span>
          </div>
          <div v-if="useFunctionality != 'WEBSITE'">
            <div v-if="item.itemType != 'DELIVERY_SERVICE'">
              <div>
                  <span class="price">kr&nbsp;</span> <span class="price"> {{item.priceTakeaway}} </span> <span>(med {{vat.takeOut}}% mva for takeaway)</span>
              </div>
              <div v-if="useFunctionality == 'POS' || useFunctionality == 'ALL'">
                  <span class="price">kr&nbsp;</span> <span class="price"> {{item.priceDineInWithVat}} </span> <span>(med {{vat.dineIn}}% mva for spise her)</span>
              </div>
            </div>
            <div v-else>
              <span class="price">kr&nbsp;</span> <span class="price"> {{item.priceDineIn}} </span> <span>(med {{vat.dineIn}}% mva for tjeneste)</span>
            </div>
          </div>
        </div>
        <div class="cart-buttons-panel">
          <a class="add-to-cart-btn"
             :href="goToItemEditPageUrl()">
            <img src="@/assets/images/edit.png" width="21" title="Rediger produkt"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmDialogue from '@/components/other/ConfirmDialogue.vue'
import ProductService from "@/services/product/ProductService";
import MerchantUtil from "@/util/MerchantUtil";

export default {
  components: {ConfirmDialogue},
  props: {
    item: Object,
    vat: Object
  },
  computed: {
    useFunctionality(){
      return MerchantUtil.useFunctionality();
    }
  },
  methods: {
    getDayName(day){
      if(day === 'MONDAY'){
        return 'Man';
      } else if(day == 'TUESDAY'){
        return 'Tir';
      } else if(day == 'WEDNESDAY'){
        return 'Ons';
      } else if(day == 'THURSDAY'){
        return 'Tor';
      } else if(day == 'FRIDAY'){
        return 'Fre';
      } else if(day == 'SATURDAY'){
        return 'Lør';
      } else if(day == 'SUNDAY'){
        return 'Søn';
      } else if(day == 'HOLIDAY'){
        return 'Hellig dag';
      }
      return 'DAY_NOT_DEFINED';
    },
    goToItemEditPageUrl(){
       let editUrl = '/itemEdit?categoryId='  + this.item.categoryId + '&itemId=' + this.item.id;
       return editUrl;
    },
    async removeItem(){
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Slett produktet "' + this.item.name + '"',
        message: 'Vil du slette produktet "' + this.item.name + '"?',
        okButton: 'Slett',
        cancelButton: 'Avbryt'
      })
      if(ok) {
        ProductService.deleteItem(this.item).then(
            (response) => {
              if(response.data != null){
                this.$router.go(); //Reload current page
              }else {
                console.log("Deleting item failed");
              }
            },
            (error) => {
              console.log(error);
            }
        );
      } else {
        //Cancel, do nothing
      }
    }
  }
}
</script>

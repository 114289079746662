<template>
  <div class="container">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div class="mt-4">
        <h3>Levering</h3>
        <div id="updatedMessage" v-if="updated">
          <span class="message-info">Leveringsinnstillingene er oppdatert</span>
        </div>

        <div v-if="hasMultiLocations()" class="select-location-box mt-4">
          <span class="ingredient-title">For avdeling</span>
          <select class="form-select" v-model="locationId" @change="getDeliveryDocument()">
            <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
          </select>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <span class="bo_label">Minimums ordre</span>
            <div class="col-sm-2">
              <input id="minimumOrder" class="form-control" type="number" />
            </div>
          </div>
        </div>

        <span class="bo_label">Hvilke dager tilbyr dere levering?</span>
        <div class="opening-hours-flex-box">
          <div class="delivery-days">
            <div>
              <span class="item-title">Mandag</span>
              <select id="mondayOpenOrClose" class="deliveryOpenOrClose">
                <option value="open">Tilbyr levering</option>
                <option value="close">Tilbyr ikke levering</option>
              </select>
            </div>
            Fra: <input type="text" id="monday_fromTime" name="monday_fromTime" class="time_element"/>
            Til: <input type="text" id="monday_toTime" name="monday_toTime" class="time_element"/>
          </div>

          <div class="delivery-days">
            <div>
              <span class="item-title">Tirsdag</span>
              <select id="tuesdayOpenOrClose" class="deliveryOpenOrClose">
                <option value="open">Tilbyr levering</option>
                <option value="close">Tilbyr ikke levering</option>
              </select>
            </div>
            Fra: <input type="text" id="tuesday_fromTime" name="tuesday_fromTime" class="time_element"/>
            Til: <input type="text" id="tuesday_toTime" name="tuesday_toTime" class="time_element"/>
          </div>

          <div class="delivery-days">
            <div>
              <span class="item-title">Onsdag</span>
              <select id="wednesdayOpenOrClose" class="deliveryOpenOrClose">
                <option value="open">Tilbyr levering</option>
                <option value="close">Tilbyr ikke levering</option>
              </select>
            </div>
            Fra: <input type="text" id="wednesday_fromTime" name="wednesday_fromTime" class="time_element"/>
            Til: <input type="text" id="wednesday_toTime" name="wednesday_toTime" class="time_element"/>
          </div>

          <div class="delivery-days">
            <div>
              <span class="item-title">Torsdag</span>
              <select id="thursdayOpenOrClose" class="deliveryOpenOrClose">
                <option value="open">Tilbyr levering</option>
                <option value="close">Tilbyr ikke levering</option>
              </select>
            </div>
            Fra: <input type="text" id="thursday_fromTime" name="thursday_fromTime" class="time_element"/>
            Til: <input type="text" id="thursday_toTime" name="thursday_toTime" class="time_element"/>
          </div>

          <div class="delivery-days">
            <div>
              <span class="item-title">Fredag</span>
              <select id="fridayOpenOrClose" class="deliveryOpenOrClose">
                <option value="open">Tilbyr levering</option>
                <option value="close">Tilbyr ikke levering</option>
              </select>
            </div>
            Fra: <input type="text" id="friday_fromTime" name="friday_fromTime" class="time_element"/>
            Til: <input type="text" id="friday_toTime" name="friday_toTime" class="time_element"/>
          </div>

          <div class="delivery-days">
            <div>
              <span class="item-title">Lørdag</span>
              <select id="saturdayOpenOrClose" class="deliveryOpenOrClose">
                <option value="open">Tilbyr levering</option>
                <option value="close">Tilbyr ikke levering</option>
              </select>
            </div>
            Fra: <input type="text" id="saturday_fromTime" name="saturday_fromTime" class="time_element"/>
            Til: <input type="text" id="saturday_toTime" name="saturday_toTime" class="time_element"/>
          </div>

          <div class="delivery-days">
            <div>
              <span class="item-title">Søndag</span>
              <select id="sundayOpenOrClose" class="deliveryOpenOrClose">
                <option value="open">Tilbyr levering</option>
                <option value="close">Tilbyr ikke levering</option>
              </select>
            </div>
            Fra: <input type="text" id="sunday_fromTime" name="sunday_fromTime" class="time_element"/>
            Til: <input type="text" id="sunday_toTime" name="sunday_toTime" class="time_element"/>
          </div>
        </div>

        <div class="bo_savebtn_container">
          <hr/>
          <button type="button" class="bo_btn" @click="save"> Lagre </button>
        </div>
      </div>
  </div>
</template>

<script>
import jQuery from 'jquery';
import $ from 'jquery';
window.jQuery = jQuery;

import SettingsService from "@/services/settings/SettingsService";
import LoadingSpinner from "@/components/other/LoadingSpinner";
import MerchantUtil from "@/util/MerchantUtil";
import LocationService from "@/services/location/LocationService";
import LocationUtil from "@/util/LocationUtil";
export default {
  components: {LoadingSpinner},
  data() {
    return {
      loading: true,

      locationId: '',
      locations: [],
      deliveryDocument: {},
      updated: false
    };
  },
  mounted() {
    const timepicki = document.createElement("script");
    timepicki.setAttribute(
        "src",
        require('@/assets/jquery/timepicki.js')
    );
    timepicki.async = true;
    document.head.appendChild(timepicki);

    $(".time_element").timepicki({
      show_meridian:false,
      min_hour_value:0,
      max_hour_value:23,
      step_size_hours:1,
      step_size_minutes:30,
      overflow_minutes:true,
      increase_direction:'up',
      disable_keyboard_mobile: true,
      start_time: ["10", "00"]
    });

    LocationService.listLocations().then(
        (response) => {
          this.locations = response.data;
          this.locationId = this.locations.length ? this.locations[0].id : '';

          this.getDeliveryDocument();
        },
        (error) => {
          console.log(error);
        }
    );
  },
  methods: {
    hasMultiLocations(){
      return LocationUtil.hasMultiLocations();
    },
    getDeliveryDocument(){
      this.loading = true;
      SettingsService.getDeliveryDocument(this.locationId).then(
          (response) => {
            if(response.data){
              this.deliveryDocument = response.data;
            }
            this.initData();

            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    initData(){
      $('#minimumOrder').val(this.deliveryDocument.minimumOrder);

      if(this.deliveryDocument){
        this.setDayChangeListeners();

        var deliveryDays = this.deliveryDocument.deliveryDays;
        var monday = this.getDeliveryDay('MONDAY', deliveryDays);
        if(monday != null){
          $('#mondayOpenOrClose').val('open');
          $('#monday_fromTime').val(monday.fromTime);
          $('#monday_toTime').val(monday.toTime);
        }else{
          $('#mondayOpenOrClose').val('close');
          $('#monday_fromTime').prop('disabled', true);
          $('#monday_toTime').prop('disabled', true);
        }

        var tuesday = this.getDeliveryDay('TUESDAY', deliveryDays);
        if(tuesday != null){
          $('#tuesdayOpenOrClose').val('open');
          $('#tuesday_fromTime').val(tuesday.fromTime);
          $('#tuesday_toTime').val(tuesday.toTime);
        }else{
          $('#tuesdayOpenOrClose').val('close');
          $('#tuesday_fromTime').prop('disabled', true);
          $('#tuesday_toTime').prop('disabled', true);
        }

        var wednesday = this.getDeliveryDay('WEDNESDAY', deliveryDays);
        if(wednesday != null){
          $('#wednesdayOpenOrClose').val('open');
          $('#wednesday_fromTime').val(wednesday.fromTime);
          $('#wednesday_toTime').val(wednesday.toTime);
        }else{
          $('#wednesdayOpenOrClose').val('close');
          $('#wednesday_fromTime').prop('disabled', true);
          $('#wednesday_toTime').prop('disabled', true);
        }

        var thursday = this.getDeliveryDay('THURSDAY', deliveryDays);
        if(thursday != null){
          $('#thursdayOpenOrClose').val('open');
          $('#thursday_fromTime').val(thursday.fromTime);
          $('#thursday_toTime').val(thursday.toTime);
        }else{
          $('#thursdayOpenOrClose').val('close');
          $('#thursday_fromTime').prop('disabled', true);
          $('#thursday_toTime').prop('disabled', true);
        }

        var friday = this.getDeliveryDay('FRIDAY', deliveryDays);
        if(friday != null){
          $('#fridayOpenOrClose').val('open');
          $('#friday_fromTime').val(friday.fromTime);
          $('#friday_toTime').val(friday.toTime);
        }else{
          $('#fridayOpenOrClose').val('close');
          $('#friday_fromTime').prop('disabled', true);
          $('#friday_toTime').prop('disabled', true);
        }

        var saturday = this.getDeliveryDay('SATURDAY', deliveryDays);
        if(saturday != null){
          $('#saturdayOpenOrClose').val('open');
          $('#saturday_fromTime').val(saturday.fromTime);
          $('#saturday_toTime').val(saturday.toTime);
        }else{
          $('#saturdayOpenOrClose').val('close');
          $('#saturday_fromTime').prop('disabled', true);
          $('#saturday_toTime').prop('disabled', true);
        }

        var sunday = this.getDeliveryDay('SUNDAY', deliveryDays);
        if(sunday != null){
          $('#sundayOpenOrClose').val('open');
          $('#sunday_fromTime').val(sunday.fromTime);
          $('#sunday_toTime').val(sunday.toTime);
        }else{
          $('#sundayOpenOrClose').val('close');
          $('#sunday_fromTime').prop('disabled', true);
          $('#sunday_toTime').prop('disabled', true);
        }
      }
    },
    setDayChangeListeners(){
      $("#mondayOpenOrClose" ).change(function() {
        if($('#mondayOpenOrClose').val() == 'open'){
          $('#monday_fromTime').prop('disabled', false);
          $('#monday_toTime').prop('disabled', false);
        }else{
          $('#monday_fromTime').prop('disabled', true);
          $('#monday_toTime').prop('disabled', true);
        }
      });

      $("#tuesdayOpenOrClose" ).change(function() {
        if($('#tuesdayOpenOrClose').val() == 'open'){
          $('#tuesday_fromTime').prop('disabled', false);
          $('#tuesday_toTime').prop('disabled', false);
        }else{
          $('#tuesday_fromTime').prop('disabled', true);
          $('#tuesday_toTime').prop('disabled', true);
        }
      });

      $("#wednesdayOpenOrClose" ).change(function() {
        if($('#wednesdayOpenOrClose').val() == 'open'){
          $('#wednesday_fromTime').prop('disabled', false);
          $('#wednesday_toTime').prop('disabled', false);
        }else{
          $('#wednesday_fromTime').prop('disabled', true);
          $('#wednesday_toTime').prop('disabled', true);
        }
      });

      $("#thursdayOpenOrClose" ).change(function() {
        if($('#thursdayOpenOrClose').val() == 'open'){
          $('#thursday_fromTime').prop('disabled', false);
          $('#thursday_toTime').prop('disabled', false);
        }else{
          $('#thursday_fromTime').prop('disabled', true);
          $('#thursday_toTime').prop('disabled', true);
        }
      });

      $("#fridayOpenOrClose" ).change(function() {
        if($('#fridayOpenOrClose').val() == 'open'){
          $('#friday_fromTime').prop('disabled', false);
          $('#friday_toTime').prop('disabled', false);
        }else{
          $('#friday_fromTime').prop('disabled', true);
          $('#friday_toTime').prop('disabled', true);
        }
      });

      $("#saturdayOpenOrClose" ).change(function() {
        if($('#saturdayOpenOrClose').val() == 'open'){
          $('#saturday_fromTime').prop('disabled', false);
          $('#saturday_toTime').prop('disabled', false);
        }else{
          $('#saturday_fromTime').prop('disabled', true);
          $('#saturday_toTime').prop('disabled', true);
        }
      });

      $("#sundayOpenOrClose" ).change(function() {
        if($('#sundayOpenOrClose').val() == 'open'){
          $('#sunday_fromTime').prop('disabled', false);
          $('#sunday_toTime').prop('disabled', false);
        }else{
          $('#sunday_fromTime').prop('disabled', true);
          $('#sunday_toTime').prop('disabled', true);
        }
      });
    },
    /**
     * Delivery for the day if it exists the deliveryDays array.
     * day: MONDAY, TUESDAY, WEDNESDAY, etc..
     */
    getDeliveryDay(day, deliveryDays){
      if(deliveryDays){
        for (var i = 0; i < deliveryDays.length; i++) {
          if(day == deliveryDays[i].day){
            return deliveryDays[i];
          }
        }
      }
      return null;
    },
    getDeliveryDaysInputs(){
      var deliveryDays = [];
      if($('#mondayOpenOrClose').val() == "open"){
        var mondayDeliveryDays = {
          day: "MONDAY",
          weekday: 1,
          fromTime: $('#monday_fromTime').val(),
          toTime: $('#monday_toTime').val()
        }
        deliveryDays.push(mondayDeliveryDays);
      }
      if($('#tuesdayOpenOrClose').val() == "open"){
        var tuesdayDeliveryDays = {
          day: "TUESDAY",
          weekday: 2,
          fromTime: $('#tuesday_fromTime').val(),
          toTime: $('#tuesday_toTime').val()
        }
        deliveryDays.push(tuesdayDeliveryDays);
      }
      if($('#wednesdayOpenOrClose').val() == "open"){
        var wednesdayDeliveryDays = {
          day: "WEDNESDAY",
          weekday: 3,
          fromTime: $('#wednesday_fromTime').val(),
          toTime: $('#wednesday_toTime').val()
        }
        deliveryDays.push(wednesdayDeliveryDays);
      }
      if($('#thursdayOpenOrClose').val() == "open"){
        var thursdayDeliveryDays = {
          day: "THURSDAY",
          weekday: 4,
          fromTime: $('#thursday_fromTime').val(),
          toTime: $('#thursday_toTime').val()
        }
        deliveryDays.push(thursdayDeliveryDays);
      }
      if($('#fridayOpenOrClose').val() == "open"){
        var fridayDeliveryDays = {
          day: "FRIDAY",
          weekday: 5,
          fromTime: $('#friday_fromTime').val(),
          toTime: $('#friday_toTime').val()
        }
        deliveryDays.push(fridayDeliveryDays);
      }
      if($('#saturdayOpenOrClose').val() == "open"){
        var saturdayDeliveryDays = {
          day: "SATURDAY",
          weekday: 6,
          fromTime: $('#saturday_fromTime').val(),
          toTime: $('#saturday_toTime').val()
        }
        deliveryDays.push(saturdayDeliveryDays);
      }
      if($('#sundayOpenOrClose').val() == "open"){
        var sundayDeliveryDays = {
          day: "SUNDAY",
          weekday: 7,
          fromTime: $('#sunday_fromTime').val(),
          toTime: $('#sunday_toTime').val()
        }
        deliveryDays.push(sundayDeliveryDays);
      }
      return deliveryDays;
    },
    save(){
      this.loading = true;

      var minimumOrder = $('#minimumOrder').val();
      var deliveryDays = this.getDeliveryDaysInputs();

      if(JSON.stringify(this.deliveryDocument) === '{}'){
        //Blank object, CREATE
        this.deliveryDocument = {
          type: "delivery",
          merchant: MerchantUtil.getMerchant(),
          locationId: this.locationId,
          minimumOrder: minimumOrder,
          availableOnDays: ["MONDAY", "TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY","SUNDAY"], //just for every day now!
          deliveryDays: deliveryDays
        }
      }else{
        //UPDATE
        this.deliveryDocument.minimumOrder = minimumOrder;
        this.deliveryDocument.deliveryDays = deliveryDays;
      }
      //console.log(JSON.stringify(this.deliveryDocument));
      SettingsService.editDeliveryDocument(this.deliveryDocument).then(
          (response) => {
            let documentId = response.data;
            if(documentId){
              this.updated = true;
            } else {
              console.log("Editing delivery days failed");
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    }
  }
};
</script>

import axios from 'axios';

class AuthService {
  login(user) {
    return axios
      .post('/api/public/backoffice_login', {
        username: user.username.trim().toLowerCase(),
        password: user.password
      })
      .then(response => {
        let result = response.data.result;
        if(result == "SUCCEEDED") {
          let user = JSON.parse(response.data.user);
          localStorage.setItem('user', JSON.stringify(user));
          return user;
        } else {
          return null;
        }
      });
  }

  superAdminlogin(user) {
    return axios
        .post('/api/public/backoffice_login', {
          username: user.username,
          password: user.password,
          merchant: user.merchant
        })
        .then(response => {
          let result = response.data.result;
          if(result == "SUCCEEDED") {
            let user = JSON.parse(response.data.user);
            localStorage.setItem('user', JSON.stringify(user));
            return user;
          } else {
            return null;
          }
        });
  }

  logout() {
    localStorage.removeItem('user');
  }

}

export default new AuthService();

<template>
  <div class="container">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div class="row vertical-align mt-3 ms-1 me-1">
        <div class="col-sm-6">
          <h3>Ta dagens oppgjør for nettbutikken</h3>
          <div v-if="!loading">
              <div v-if="hasMultiLocations()" class="mt-5">
                <span class="ingredient-title">Velg avdeling</span>
                <select class="form-select" v-model="locationId" @change="showXReport()">
                    <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
                </select>
              </div>

              <div v-if="isXReportCountersEmpty" class="mt-4 mb-5">
                  <p>
                    Ingen omsetning fra nettbutikken siden forrige oppgjør.
                  </p>
              </div>
              <div v-else>
                  <ShowWebshopXReport :xReportCounters="xReportCounters" :company="company" :location="getLocation" :vat="vat"/>
                  <button type="button" class="bo_btn mb-5" @click="createWebshopZReport">Ta dagens oppgjør for nettbutikken</button>
                  <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
              </div>
          </div>
        </div>
        <div v-if="!loading && (useFunctionality === 'TAKEAWAY' || useFunctionality === 'ALL')" class="col-sm-6 d-flex justify-content-center mb-5 pt-0 ps-4 pe-4">
            <span style="font-size: 19px">Dagsoppgjør for nettbutikken blir gjort automatisk ved dagens slutt hvis du ikke allerede har gjort det.</span>
        </div>
      </div>
    </div>
</template>
<script>
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import ShowWebshopXReport from "@/components/report/xreport/ShowWebshopXReport";
  import ConfirmDialogue from '@/components/other/ConfirmDialogue.vue'
  import ReportService from "@/services/report/ReportService";
  import LocationUtil from "@/util/LocationUtil";
  import MerchantUtil from "@/util/MerchantUtil";

  export default {
    components: {LoadingSpinner, ShowWebshopXReport, ConfirmDialogue},
    data() {
      return {
        loading: true,

        locationId: '',
        locations: [],
        merchant: '',
        company: {},
        vat: {},
        xReportCounters: {}
      };
    },
    computed: {
      useFunctionality(){
        return MerchantUtil.useFunctionality();
      },
      getLocation(){
        return LocationUtil.getLocation(this.locationId);
      },
      isXReportCountersEmpty() {
        if(this.xReportCounters.vippsPickupVat == 0 &&
            this.xReportCounters.vippsDeliveryVat == 0 &&
            this.xReportCounters.vippsDeliveryServiceVat == 0 &&
            this.xReportCounters.vippsPickupBrutto == 0 &&
            this.xReportCounters.vippsDeliveryBrutto == 0 &&
            this.xReportCounters.vippsDeliveryServiceBrutto == 0 &&
            this.xReportCounters.cardPickupVat == 0 &&
            this.xReportCounters.cardDeliveryVat == 0 &&
            this.xReportCounters.cardDeliveryServiceVat == 0 &&
            this.xReportCounters.cardPickupBrutto == 0 &&
            this.xReportCounters.cardDeliveryBrutto == 0 &&
            this.xReportCounters.cardDeliveryServiceBrutto == 0 &&
            this.xReportCounters.refundReceiptsCount == 0 &&
            this.xReportCounters.refundReceiptsTotalAmount == 0){
          return true;
        } else {
          return false;
        }
      }
    },
    mounted() {
      ReportService.getDataForReportGui().then(
          (response) => {
            let dataForGui = response.data;
            this.locations = dataForGui.locations;
            this.company = dataForGui.company;
            this.vat = dataForGui.vat;
            this.merchant = dataForGui.merchant;

            this.locationId = this.locations.length ? this.locations[0].id : '';

            this.showXReport();
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      showXReport(){
        this.loading = true;

        ReportService.getWebshopXReportCounters(this.locationId).then(
            (response) => {
              this.xReportCounters = response.data;
              console.log('xReportCounters: ' + JSON.stringify(this.xReportCounters));
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      async createWebshopZReport(){
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Ta dagens oppgjør for nettbutikken',
          message: 'Vil du generere oppgjørsrapport for nettbutikken?',
          okButton: 'Ja',
          cancelButton: 'Avbryt'
        })
        if(ok) {
            ReportService.createWebshopZReport(this.locationId).then(
                  (response) => {
                    if(response.data != null){ //Z-report is created, response.data :== documentId
                        this.showXReport(); //refresh GUI
                    }else {
                      console.log("Generering av oppgjørsrapport for nettbutikken feiler.");
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
              );
        } else {
          //Cancel, do nothing
        }
      }
    }
  };
</script>

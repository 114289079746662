<template>
  <LoadingSpinner :loading='loading'> </LoadingSpinner>

  <div class="container mt-4 mb-4">
    <div style="max-width:560px">
        <h3>Dintero konto</h3>
        <div v-if='updated'>
          <span class='message-info'>Dintero kontoinnstillingene er oppdatert</span>
        </div>

        <div v-if="hasMultiLocations()" class="select-location-box mt-4">
          <span class="ingredient-title">For avdeling</span>
          <select class="form-select" v-model="locationId" @change="getDinteroAccount()">
            <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
          </select>
        </div>

        <div class="mt-4">
          <span class='ingredient-title'>account_id (f.eks T11112936)</span>
          <input class='form-control' type='text' v-model='dinteroAccount.accountId' />
        </div>

        <div>
          <span class='ingredient-title'>profile_id (f.eks T11112936.4XGfAaDRe3bM2qunbzGmoU)</span>
          <input class='form-control' type='text' v-model='dinteroAccount.checkoutProfileId' />
        </div>

        <div>
          <span class='ingredient-title'>default_payment_type (f.eks payex.vipps)</span>
          <input class='form-control' type='text' v-model='dinteroAccount.defaultPaymentType' />
        </div>

        <div>
          <span class='ingredient-title'>primary (theme color f.eks #f0c7a9)</span>
          <input class='form-control' type='text' v-model='dinteroAccount.themePrimaryColor' />
        </div>

        <div>
          <span class='ingredient-title'>client_id</span>
          <input class='form-control' type='text' v-model='dinteroAccount.clientId' />
        </div>

        <div>
          <span class='ingredient-title'>client_secret</span>
          <input class='form-control' type='text' v-model='dinteroAccount.clientSecret' />
        </div>

        <div class="mt-3" style="font-style: italic">
          <span>NB! Dintero Basic Auth Token := Base64.encode(client_id:client_secret)</span>
        </div>

        <div class='bo_hr mt-4'>
          <hr/>
        </div>

        <div class='mb-5'>
          <button v-if='activateSaveButton' type='button' class='bo_btn' @click='save'> Lagre </button>
          <button v-else type='button' class='bo_btn' @click='save' disabled='true'> Lagre </button>
        </div>
    </div>
  </div>
</template>

<script>
  import SettingsService from '@/services/settings/SettingsService';
  import MerchantUtil from "@/util/MerchantUtil";
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import LocationUtil from "@/util/LocationUtil";
  import LocationService from "@/services/location/LocationService";

  export default {
    components: {LoadingSpinner},
    data() {
      return {
        loading: true,
        locationId: '',
        locations: [],

        updated: false,
        dinteroAccount: {
          type: "dinteroAccount",
          merchant: '',
          locationId: '',
          accountId: '',
          checkoutProfileId: '',
          defaultPaymentType: '',
          themePrimaryColor: '',
          clientId: '',
          clientSecret: ''
        }
      };
    },
    mounted() {
      LocationService.listLocations().then(
          (response) => {
            this.locations = response.data;
            this.locationId = this.locations.length ? this.locations[0].id : '';

            this.getDinteroAccount();
          },
          (error) => {
            console.log(error);
          }
      );
    },
    computed: {
      activateSaveButton(){
        if(this.dinteroAccount.accountId.length > 0 &&
            this.dinteroAccount.checkoutProfileId.length > 0 && this.dinteroAccount.defaultPaymentType.length > 0 && this.dinteroAccount.themePrimaryColor.length > 0 &&
            this.dinteroAccount.clientId.length > 0 && this.dinteroAccount.clientSecret.length > 0){
          return true;
        } else {
          return false;
        }
      }
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      getDinteroAccount(){
        SettingsService.getDinteroAccount(this.locationId).then(
            (response) => {
              if(response.data !== null){
                this.dinteroAccount = response.data;
              }

              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      save(){
        this.loading = true;
        this.dinteroAccount.merchant = MerchantUtil.getMerchant();
        this.dinteroAccount.locationId = this.locationId;

        SettingsService.editDinteroAccount(this.dinteroAccount).then(
            (response) => {
              let documentId = response.data;
              this.dinteroAccount.id = documentId;
              if(documentId){
                this.updated = true;
              } else {
                console.log("Editing dinteroAccount failed");
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      }
    }
  };
</script>

import authHeader from "@/services/auth/AuthHeader";
import axios from "axios";

class OrderService {
    /*
    listOrders(limit, page){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/order/listOrders' + '?merchant=' + user.merchant + '&limit=' + limit + '&page=' + page;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }
    */

    getOrder(orderDocumentId){
        let url = '/api/order/getOrder' + '?orderDocumentId=' + orderDocumentId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getDataForOrderDetailGui(orderDocumentId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/order/getDataForOrderDetailGui' + '?merchant=' + user.merchant + '&orderDocumentId=' + orderDocumentId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    updateOrderStatus(orderDocumentId, orderStatus){
        //let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/order/updateOrderStatusFromBackOffice';
        let basicAuth = authHeader();
        return axios.post(url,
            {
                orderDocumentId: orderDocumentId,
                orderStatus: orderStatus
            },
            {headers: basicAuth}
        );
    }
}

export default new OrderService();

<template>
  <div class="mb-5">
    <div class="pdf-icon">
      <!--a @click="createPdf">
        <img src="@/assets/images/pdf.png" alt="PDF" height="32" />
      </a -->
    </div>
    <div id="x-report" class="xz-report">
      <WebshopXReportHeaderRegenerere :company="company" :date="selectedDate"/>
      <WebshopXReportTotalSales :xReportCounters="xReportCounters" :vat="vat" />
      <WebshopXReportCategories :xReportCounters="xReportCounters" :vat="vat" />
      <WebshopXReportSalesPayments :xReportCounters="xReportCounters" :vat="vat" />
      <WebshopXReportRefund :xReportCounters="xReportCounters" :vat="vat" />
    </div>
  </div>
</template>

<script>
import WebshopXReportHeaderRegenerere from "@/components/report/xreport/WebshopXReportHeaderRegenerere";
import WebshopXReportSalesPayments from "@/components/report/xreport/WebshopXReportSalesPayments";
import WebshopXReportCategories from "@/components/report/xreport/WebshopXReportCategories";
import WebshopXReportTotalSales from "@/components/report/xreport/WebshopXReportTotalSales";
import WebshopXReportRefund from "@/components/report/xreport/WebshopXReportRefund";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
  components: {WebshopXReportHeaderRegenerere, WebshopXReportSalesPayments, WebshopXReportCategories, WebshopXReportTotalSales, WebshopXReportRefund},
  props: {
    xReportCounters: Object,
    company: Object,
    vat: Object,
    selectedDate: String
  },
  methods: {
    createPdf() {
      //PdfUtil.generatePdf('x-report', "Webshop_X-rapport.pdf"); //This creates misformed PDF
      const input = document.getElementById('x-report');
      html2canvas(input).then(function(canvas) {
        const imgData = canvas.toDataURL('image/png');
        //window.open(imgData); //Open image to see if canvas is rendered correctly.
        const width = canvas.width;
        const height = canvas.height;
        const pdf = new jsPDF('p', 'px', [width, height], true);
        pdf.addImage(imgData, 0, 0);
        pdf.save('Webshop_X-rapport.pdf');
      });
    }
  }
}
</script>

import axios from "axios";
import authHeader from '@/services/auth/AuthHeader';

class UserService {
    listUsers(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/user/listUsers' + '?merchant=' + user.merchant;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    checkUserPincode(pincode){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/user/checkUserPincode';
        let basicAuth = authHeader();
        return axios.post(url,
            {
                merchant: user.merchant,
                pincode: pincode.toString()
            },
            {
            headers: basicAuth });
    }

    getUser(userId){
        let url = '/api/user/getUser' + '?userId=' + userId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    createUser(user){
        let url = '/api/user/createUser';
        let basicAuth = authHeader();
        return axios.post(url, user,{
            headers: basicAuth });
    }

    editUser(user){
        let url = '/api/user/editUser';
        let basicAuth = authHeader();
        return axios.post(url, user,{
            headers: basicAuth });
    }

    deleteUser(user){
        console.log("User: " + JSON.stringify(user));
        let url = '/api/user/deleteUser';
        let basicAuth = authHeader();
        return axios.post(url, user, {
            headers: basicAuth}
        );
    }
}

export default new UserService();
<template>
  <div class="col-md-12 d-flex align-items-center justify-content-center" style="height:100vh">
    <div class="card card-container">
      <img
        id="profile-img"
        src="@/assets/images/user.svg"
        class="bg-image profile-img-card"
      />
      <Form @submit="handleSubmit" :validation-schema="schema">
        <div class="form-group">
          <label for="username">Brukernavn</label>
          <Field id="username" name="username" type="text" class="form-control" />
          <ErrorMessage name="username" class="error-feedback" />
        </div>
        <div class="form-group">
          <label for="password">Passord</label>
          <Field id="password" name="password" type="password" class="form-control" />
          <ErrorMessage name="password" class="error-feedback" />
        </div>

        <div class="form-group">
          <button class="btn btn-bg btn- btn-block mt-4" style="width:100%" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm me-1"
            ></span>
            <span>Logg inn</span>
          </button>
        </div>

        <div class="form-group">
          <div v-if="message" class="alert alert-danger mt-1" role="alert">
            {{ message }}
          </div>
        </div>
      </Form>
    </div>
  </div>

  <div class="col-md-12 d-flex align-items-center justify-content-center text-center">
    <div style="color: rgba( 112, 123, 124, 0.9);">
          <span>
              Nettstedet er beskyttet av reCaptcha, se Googles <a href="https://policies.google.com/privacy?hl=no">personvern</a> og <a href="https://policies.google.com/terms?hl=no">vilkår</a>
          </span>
    </div>
  </div>
</template>

<script>
 /* eslint-disable */
  import Recaptcha from "@/services/auth/Recaptcha";
  import { Form, Field, ErrorMessage } from "vee-validate";
  import * as yup from "yup";
  import MerchantUtil from "@/util/MerchantUtil";

  export default {
    setup() {
      const recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
          "src",
          "https://www.google.com/recaptcha/api.js?render=6LfYDhQdAAAAAOkHPvIPsQz3iVMMdAzXUFnXiRjz"
      );
      document.head.appendChild(recaptchaScript);
    },
    components: {
      Form,
      Field,
      ErrorMessage,
    },
    data() {
      const schema = yup.object().shape({
        username: yup.string().required("Fyll ut brukernavn"),
        password: yup.string().required("Fyll ut passord"),
      });

      return {
        loading: false,
        message: "",
        schema,
      };
    },
    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },
    },
    methods: {
      handleSubmit(user) {
        //https://stackoverflow.com/questions/20279484/how-to-access-the-correct-this-inside-a-callback
        var self = this; //To refer to the right object in the context.
        this.loading = true;

        //self.login(user); //Uncomment this line for local development

        //Comment this function for local development
        grecaptcha.ready(function() {
          grecaptcha.execute('6LfYDhQdAAAAAOkHPvIPsQz3iVMMdAzXUFnXiRjz', {action: 'login'}).then(function(token) {
            //Add your logic to submit to your backend server here.
            Recaptcha.checkRecaptchaToken(token, 'login').then(
                (response) => {
                  let result = response.data.result;
                  console.log('checkRecaptchaToken result: ' + response.data.result);
                  if(result == "SUCCEEDED") {
                    self.login(user);
                  } else {
                    self.loading = false;
                    self.message = "Feil brukernavn/passord";
                  }
                },
                (error) => {
                  console.log(error);
                  self.loading = false;
                }
            );
          });
        });
      },
      login(user){
        this.$store.dispatch("auth/login", user).then(
            () => {
              if(MerchantUtil.useFunctionality() === 'WEBSITE'){
                this.$router.push("/products");
              }else {
                this.$router.push("/overview");
              }
            },
            (error) => {
              this.loading = false;
              this.message = "Feil brukernavn/passord";
              console.log("Innlogging mislyktes: " + JSON.stringify(error));
            }
        );
      }
    },
  };
</script>

<style scoped>
  label {
    display: block;
    margin-top: 10px;
  }

  .card-container.card {
    max-width: 350px !important;
    padding: 0px 40px 40px 40px;
  }

  .card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  }

  .bg-image {
    background-color: #212529;
    color: #fff;
  }

  .btn-bg {
    background-color: #ECB109;
    color: #fff;
  }

  .profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto -20px;
    transform: translate(0px, -50px);
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }

  .error-feedback {
    color: #B30E10;
  }
</style>

<template>
  <div class="container">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div class="mt-4" style="max-width:545px">
        <h3>SMS rapport</h3>
        <p>Her kan du få ut en detaljert liste over hvor mange SMS som har blitt sendt i en periode.</p>

        <div v-if="hasMultiLocations()" class="mt-4">
          <span class="ingredient-title">Velg avdeling</span>
          <select class="form-select" v-model="locationId">
            <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
          </select>
        </div>

        <SelectDate ref="selectDateFrom" label="Velg fra og med dato"/>
        <SelectDate ref="selectDateTo" label="Velg til og med dato"/>

        <button type="button" class="bo_btn mt-3" :class="messages.length == 0 ? 'mb-5' : 'mb-0'" @click="showSMSReport"> Vis SMS rapport </button>

        <div v-if="messages.length == 0 && resultFetched" class="xz-report mb-5">
          <p class="no-report-found">Ingen SMS har blitt sendt i denne perioden.</p>
        </div>
      </div>

      <ShowSMSReport v-if="messages.length > 0"
                     :messages="messages"
                     :location="getLocation()"
                     :fromDate="fromDate"
                     :toDate="toDate" />
  </div>
</template>

<script>
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import SelectDate from "@/components/other/SelectDate";
  import ShowSMSReport from "@/components/report/sms/ShowSMSReport";
  import ReportService from "@/services/report/ReportService";
  import LocationUtil from "@/util/LocationUtil";
  import MerchantUtil from "@/util/MerchantUtil";

  export default {
    components: {LoadingSpinner, SelectDate, ShowSMSReport},
    data() {
      return {
        loading: true,

        locationId: '',
        locations: [],
        merchant: '',
        fromDate: new Date(),
        toDate: new Date(),
        messages: [],
        resultFetched: false
      };
    },
    mounted() {
      ReportService.getDataForReportGui().then(
          (response) => {
            let dataForGui = response.data;
            this.merchant = dataForGui.merchant;
            this.locations = dataForGui.locations;
            this.locationId = this.locations.length ? this.locations[0].id : '';

            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      useFunctionality(){
        return MerchantUtil.useFunctionality();
      },
      getLocation(){
        return LocationUtil.getLocation(this.locationId);
      },
      showSMSReport() {
        this.loading = true;
        let fromDate =  this.$refs.selectDateFrom.getSelectedDate();
        let toDate =  this.$refs.selectDateTo.getSelectedDate();
        this.fromDate = fromDate;
        this.toDate = toDate;

        ReportService.getSmsReport(this.locationId, fromDate, toDate).then(
            (response) => {
              if(response.data){
                //console.log(response.data.result);
                  this.messages =response.data;
              } else {
                  this.messages = [];
              }

              this.loading = false;
              this.resultFetched = true;
            },
            (error) => {
              console.log(error);
            }
        );
      }
    }
  };
</script>

<template>
  <div class="mt-4 mb-4 sidebar">
    <div class="component">
      <div class="bo_categories-header">
        <a class="sort-order-btn"
           @click="goToSortCategoriesPage">
          <img src="@/assets/images/sort_order.png" width="23" title="Endre rekkefølgen av kategorier" />
        </a>
        <span class="element-title">Kategorier</span>
        <span class="right-align">
          <button type="button" class="bo_btn" @click="goToAddCategoryPage"> + Legg til kategori </button>
        </span>
      </div>
      <div class="content">
        <ul class="bo_categoriesList">
          <li v-for="category in categories" :key="category.id" class="category-row">
            <a class="remove-category-btn"
               @click="removeCategory(category)">
              <img src="@/assets/images/remove.png" width="21" title="Slett kategori"/>
            </a>
            <span @click="goToCategoryDiv(category.id)" class="category-name">{{ category.name }}</span>
            <a class="edit-category-btn"
               @click="editCategory(category.id)">
              <img src="@/assets/images/edit.png" width="21" title="Rediger kategori"/>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
</template>

<script>
import ConfirmDialogue from '@/components/other/ConfirmDialogue.vue'
import ProductService from "@/services/product/ProductService";

export default {
  components: {ConfirmDialogue},
  props: {
    categories: Array
  },
  methods: {
      goToSortCategoriesPage(){
        this.$router.push({ path: 'categoriesSort'});
      },
      goToAddCategoryPage (){
        this.$router.push({ path: 'categoryCreate'});
      },
      async removeCategory(category){
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Slett kategorien "' + category.name + '"',
          message: 'Vil du slette kategorien "' + category.name + '"' + ' og dens tilhørende produkter?',
          okButton: 'Slett',
          cancelButton: 'Avbryt'
        })
        if(ok) {
          ProductService.deleteCategory(category).then(
              (response) => {
                if(response.data != null){
                  this.$router.go(); //Reload current page
                }else {
                  console.log("Deleting category failed");
                }
              },
              (error) => {
                console.log(error);
              }
          );
        } else {
          //Cancel, do nothing
        }
      },
      goToCategoryDiv (categoryId){
          var categoryElementId = 'cat_' + categoryId;
          var element = document.getElementById(categoryElementId);
          element.scrollIntoView();
          /*
          var marginTop = 86;
          var scrolledY = window.scrollY;
          if(scrolledY){
            window.scroll(0, scrolledY - marginTop);
          }*/
      },
      editCategory(categoryId){
        this.$router.push({ path: 'categoryEdit', query: {
            categoryId: categoryId
          }
        });
      }
  }
}
</script>

<style scoped>
  /**
   * lg: 992px breakpoint or larger
   */
  @media screen and (min-width: 992px) {
    .bo_categories-header{
      padding-right: 5px;
    }

    .sidebar {
      position: sticky;
      top: 0px;
    }
    .component {
      display: grid;
      grid-template-rows: auto 1fr auto;
    }
    .component .content {
      overflow-y: auto;
    }
    .sidebar .component {
      max-height: calc(100vh - 5px);
    }
  }
</style>

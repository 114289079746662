/* eslint-disable */
import MathUtil from '@/util/MathUtil';

class ItemUtil {
    getItemSubselectionPriceRange(item) {
        //console.log('itemId: ' + item.id);
        if(item.subselections.length == 0){
            return item.price; //Show two decimals
        }

        var prices = [];
        for (var i = 0; i < item.subselections.length; i++) {
            var subselection = item.subselections[i];
            prices.push(subselection.price); //Show two decimals
        }
        prices = this.unique(prices);
        prices.sort(function(a, b) {
            if(a == b) { return 0; }
            if(a > b) {
                return 1;
            }else {
                return -1;
            }
        });
        //log.info('prices: ' + JSON.stringify(prices));
        var text = "";
        for (var i = 0; i < prices.length; i++) {
            text = text + prices[i] + "/";
        }
        text = text.substring(0, text.length - 1); // remove / at the end
        return text;
    }

    getItemSubselectionPriceRangeTakeaway(item, vat) {
        //console.log('itemId: ' + item.id);
        if(item.subselections.length == 0){
            return this.getPriceWithVat(item.price, vat);
        }

        var prices = [];
        for (var i = 0; i < item.subselections.length; i++) {
            var subselection = item.subselections[i];
            prices.push(this.getPriceWithVat(parseFloat(subselection.price), vat));
        }
        prices = this.unique(prices);
        prices.sort(function(a, b) {
            if(a == b) { return 0; }
            if(a > b) {
                return 1;
            }else {
                return -1;
            }
        });
        //log.info('prices: ' + JSON.stringify(prices));
        var text = "";
        for (var i = 0; i < prices.length; i++) {
            text = text + prices[i] + "/";
        }
        text = text.substring(0, text.length - 1); // remove / at the end
        return text;
    }

    getItemSubselectionPriceRangeDineIn(item, vat) {
        //console.log('itemId: ' + item.id);
        if(item.subselections.length == 0){
            return this.getPriceDineIn(item.priceDineIn, vat);
        }

        var prices = [];
        for (var i = 0; i < item.subselections.length; i++) {
            var subselection = item.subselections[i];
            prices.push(this.getPriceDineIn(parseFloat(subselection.priceDineIn), vat));
        }
        prices = this.unique(prices);
        prices.sort(function(a, b) {
            if(a == b) { return 0; }
            if(a > b) {
                return 1;
            }else {
                return -1;
            }
        });
        //log.info('prices: ' + JSON.stringify(prices));
        var text = "";
        for (var i = 0; i < prices.length; i++) {
            text = text + prices[i] + "/";
        }
        text = text.substring(0, text.length - 1); // remove / at the end
        return text;
    }

    getPriceWithoutVat(vatRate, amountWithVat){
        var amountWithoutVat = amountWithVat/(100 + vatRate) * 100;
        //console.log("amountWithoutVat: " + amountWithoutVat);
        return MathUtil.roundTwoDecimals(amountWithoutVat); //With two decimals will the price with vat in Webshop be calculated right!
    }

    getPriceWithVat(priceWithoutVat, vat){
        let priceTakeaway = priceWithoutVat + (priceWithoutVat / 100 * vat.takeOut);
        return Math.round(priceTakeaway);
    }

    getPriceDineIn(priceWithoutVat, vat){
        let priceDineIn = priceWithoutVat + (priceWithoutVat / 100 * vat.dineIn);
        return Math.round(priceDineIn);
    }

    /**
     * Unique items in array.
     */
    unique(array) {
        var u = {}, a = [];
        for(var i = 0, l = array.length; i < l; ++i){
            if(!Object.prototype.hasOwnProperty.call(u, array[i])) {
                a.push(array[i]);
                u[array[i]] = 1;
            }
        }
        return a;
    }
}

export default new ItemUtil();


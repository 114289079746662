class TurnoverReportUtil {
    getTotalVippsPickupCount(zReports){
        var totalVippsPickupCount = 0;
        for (var i = 0; i < zReports.length; i++) {
            var zReport = zReports[i].cagistahospitality;
            totalVippsPickupCount = totalVippsPickupCount + zReport.vippsPickupCount;
        }
        return totalVippsPickupCount;
    }

    getTotalVippsPickupBrutto(zReports){
        var totalVippsPickupBrutto = 0;
        for (var i = 0; i < zReports.length; i++) {
            var zReport = zReports[i].cagistahospitality;
            totalVippsPickupBrutto = totalVippsPickupBrutto + zReport.vippsPickupBrutto;
        }
        return totalVippsPickupBrutto;
    }

    getTotalVippsDineInCount(){
        return 0;
    }

    getTotalVippsDeliveryCount(zReports){
        var totalVippsDeliveryCount = 0;
        for (var i = 0; i < zReports.length; i++) {
            var zReport = zReports[i].cagistahospitality;
            totalVippsDeliveryCount = totalVippsDeliveryCount + zReport.vippsDeliveryCount;
        }
        return totalVippsDeliveryCount;
    }

    getTotalVippsDeliveryAndDeliveryServiceBrutto(zReports){
        var totalVippsDeliveryAndDeliveryServiceBrutto = 0;
        for (var i = 0; i < zReports.length; i++) {
            var zReport = zReports[i].cagistahospitality;
            totalVippsDeliveryAndDeliveryServiceBrutto = totalVippsDeliveryAndDeliveryServiceBrutto + zReport.vippsDeliveryBrutto + zReport.vippsDeliveryServiceBrutto;
        }
        return totalVippsDeliveryAndDeliveryServiceBrutto;
    }

    getVippsTotalCount(zReports){
        return (this.getTotalVippsPickupCount(zReports) + this.getTotalVippsDineInCount()  + this.getTotalVippsDeliveryCount(zReports));
    }

    getVippsTotalBrutto(zReports){
        var VippsTotalBrutto = 0;
        for (var i = 0; i < zReports.length; i++) {
            var zReport = zReports[i].cagistahospitality;
            VippsTotalBrutto = VippsTotalBrutto + zReport.vippsPickupBrutto + zReport.vippsDeliveryBrutto + zReport.vippsDeliveryServiceBrutto;
        }
        return Math.round(VippsTotalBrutto);
    }

    getTotalCardPickupCount(zReports){
        var totalCardPickupCount = 0;
        for (var i = 0; i < zReports.length; i++) {
            var zReport = zReports[i].cagistahospitality;
            totalCardPickupCount = totalCardPickupCount + zReport.cardPickupCount;
        }
        return totalCardPickupCount;
    }

    getTotalCardDineInCount(zReports){
        var totalCardDineInCount = 0;
        for (var i = 0; i < zReports.length; i++) {
            var zReport = zReports[i].cagistahospitality;
            totalCardDineInCount = totalCardDineInCount + zReport.cardDineInCount;
        }
        return totalCardDineInCount;
    }

    getTotalCardPickupBrutto(zReports){
        var totalCardPickupBrutto = 0;
        for (var i = 0; i < zReports.length; i++) {
            var zReport = zReports[i].cagistahospitality;
            totalCardPickupBrutto = totalCardPickupBrutto + zReport.cardPickupBrutto;
        }
        return totalCardPickupBrutto;
    }

    getTotalCardDeliveryCount(zReports){
        var totalCardDeliveryCount = 0;
        for (var i = 0; i < zReports.length; i++) {
            var zReport = zReports[i].cagistahospitality;
            totalCardDeliveryCount = totalCardDeliveryCount + zReport.cardDeliveryCount;
        }
        return totalCardDeliveryCount;
    }

    getTotalCardDeliveryAndDeliveryServiceBrutto(zReports){
        var totalCardDeliveryAndDeliveryServiceBrutto = 0;
        for (var i = 0; i < zReports.length; i++) {
            var zReport = zReports[i].cagistahospitality;
            totalCardDeliveryAndDeliveryServiceBrutto = totalCardDeliveryAndDeliveryServiceBrutto + zReport.cardDeliveryBrutto + zReport.cardDeliveryServiceBrutto;
        }
        return totalCardDeliveryAndDeliveryServiceBrutto;
    }

    getCardTotalCount(zReports){
        return (this.getTotalCardPickupCount(zReports) + this.getTotalCardDineInCount(zReports) + this.getTotalCardDeliveryCount(zReports));
    }

    getCardTotalBrutto(zReports){
        var cardTotalBrutto = 0;
        for (var i = 0; i < zReports.length; i++) {
            var zReport = zReports[i].cagistahospitality;
            cardTotalBrutto = cardTotalBrutto + zReport.cardPickupBrutto + zReport.cardDeliveryBrutto + zReport.cardDeliveryServiceBrutto;
        }
        return Math.round(cardTotalBrutto);
    }

    getTotalTakeawayVat(zReports){
        var totalTakeawayVat = 0;
        for (var i = 0; i < zReports.length; i++) {
            var zReport = zReports[i].cagistahospitality;
            var cashPickupVat = zReport.cashPickupVat;
            var cashDeliveryVat = zReport.cashDeliveryVat;
            var vippsPickupVat = zReport.vippsPickupVat;
            var vippsDeliveryVat = zReport.vippsDeliveryVat;
            var cardPickupVat = zReport.cardPickupVat;
            var cardDeliveryVat = zReport.cardDeliveryVat;
            totalTakeawayVat = totalTakeawayVat + Math.round(cashPickupVat + cashDeliveryVat+ vippsPickupVat + vippsDeliveryVat + cardPickupVat + cardDeliveryVat);
        }
        return totalTakeawayVat;
    }

    getTotalTakeawayBrutto(zReports){
        var totalTakeawayBrutto = 0;
        for (var i = 0; i < zReports.length; i++) {
            var zReport = zReports[i].cagistahospitality;
            var cashPickupBrutto = zReport.cashPickupBrutto;
            var cashDeliveryBrutto = zReport.cashDeliveryBrutto;
            var vippsPickupBrutto = zReport.vippsPickupBrutto;
            var vippsDeliveryBrutto = zReport.vippsDeliveryBrutto;
            var cardPickupBrutto = zReport.cardPickupBrutto;
            var cardDeliveryBrutto = zReport.cardDeliveryBrutto
            totalTakeawayBrutto = totalTakeawayBrutto + cashPickupBrutto + cashDeliveryBrutto + vippsPickupBrutto + vippsDeliveryBrutto + cardPickupBrutto + cardDeliveryBrutto;
        }
        return totalTakeawayBrutto
    }

    getTotalTakeawayNetto(zReports){
        return (this.getTotalTakeawayBrutto(zReports) - this.getTotalTakeawayVat(zReports));
    }

    getTotalDineInAndServiceVat(zReports){
        var totalDineInAndServiceVat = 0;
        for (var i = 0; i < zReports.length; i++) {
            var zReport = zReports[i].cagistahospitality;
            var cashDineInVat = zReport.cashDineInVat;
            var cashDeliveryServiceVat = zReport.cashDeliveryServiceVat;
            var vippsDineInVat = zReport.vippsDineInVat;
            var vippsDeliveryServiceVat = zReport.vippsDeliveryServiceVat;
            var cardDineInVat = zReport.cardDineInVat;
            var cardDeliveryServiceVat = zReport.cardDeliveryServiceVat;
            totalDineInAndServiceVat = totalDineInAndServiceVat + Math.round(cashDineInVat + cashDeliveryServiceVat + vippsDineInVat + vippsDeliveryServiceVat + cardDineInVat + cardDeliveryServiceVat);
        }
        return totalDineInAndServiceVat;
    }

    getTotalDineInAndServiceBrutto(zReports){
        var totalDineInAndServiceBrutto = 0;
        for (var i = 0; i < zReports.length; i++) {
            var zReport = zReports[i].cagistahospitality;
            var cashDineInBrutto = zReport.cashDineInBrutto;
            var cashDeliveryServiceBrutto = zReport.cashDeliveryServiceBrutto;
            var vippsDineInBrutto = zReport.vippsDineInBrutto;
            var vippsDeliveryServiceBrutto = zReport.vippsDeliveryServiceBrutto;
            var cardDineInBrutto = zReport.cardDineInBrutto;
            var cardDeliveryServiceBrutto = zReport.cardDeliveryServiceBrutto;
            totalDineInAndServiceBrutto = totalDineInAndServiceBrutto + cashDineInBrutto + cashDeliveryServiceBrutto + vippsDineInBrutto + vippsDeliveryServiceBrutto + cardDineInBrutto + cardDeliveryServiceBrutto;
        }
        return totalDineInAndServiceBrutto;
    }

    getTotalDineInAndServiceNetto(zReports){
        return (this.getTotalDineInAndServiceBrutto(zReports) - this.getTotalDineInAndServiceVat(zReports));
    }

    getTotalSalesVat(zReports){
        return (this.getTotalTakeawayVat(zReports) + this.getTotalDineInAndServiceVat(zReports));
    }

    getTotalSalesNetto(zReports){
        return (this.getTotalTakeawayNetto(zReports) + this.getTotalDineInAndServiceNetto(zReports));
    }

    getTotalSalesBrutto(zReports){
        return (this.getTotalTakeawayBrutto(zReports) + this.getTotalDineInAndServiceBrutto(zReports));
    }
}

export default new TurnoverReportUtil();

<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container">
    <div class="row">
      <div class="col-md">
        <div class="mt-4 mb-4" style="max-width:560px">
          <h3>Legg til produkt i kategorien "{{category.name}}"</h3>
          <br/>
          <div>
            <span v-if="item.itemType != 'DELIVERY_SERVICE'" class="bo_label">Navn</span>
            <span v-else class="bo_label">Leveringsområde</span>
            <input id="name" class="form-control" type="text" v-model="item.name" />
          </div>

          <div v-if="item.itemType == 'DELIVERY_SERVICE'">
            <span class="bo_label">Tillatte postnr (separer postnr med komma, f.eks: 2010,2040)</span>
            <input id="zipCodes" class="form-control" type="text" v-model="item.zipCodes" />
          </div>

          <div v-if="item.itemType == 'DISH' || item.itemType == 'DRINK_BUBBLE_TEA'">
            <span class="bo_label">Meny nummer</span>
            <input id="menuNr" class="form-control" type="text" v-model="item.menuNr" />
          </div>

          <div v-if="hasMultiLocations()">
            <span class="ingredient-title">Tilgjengelig på følgende avdelinger</span>
            <fieldset>
              <label v-for="(location, index) in locations" :key="index" class="multiple-checkbox-label">
                <input type="checkbox" :name="location.name" :value="location.id" @click="setSelectedLocations" class="multiple-checkbox"
                       :checked="item.availableAtLocationIds.indexOf(location.id) > -1" /> {{ location.name }}
              </label>
            </fieldset>
          </div>

          <div v-if="showAvailableForOrderTypes()">
            <span class="ingredient-title">Tilgjengelig for</span>
            <fieldset>
              <label v-for="(orderType, index) in orderTypes" :key="index" class="multiple-checkbox-label">
                <input type="checkbox" :name="orderType" :value="orderType" @click="setSelectedOrderTypes" class="multiple-checkbox"
                       :checked="item.availableForOrderTypes.indexOf(orderType) > -1" /> {{ getOrderTypeName(orderType) }}
              </label>
            </fieldset>
          </div>

          <div v-if="showAvailableOnDays()">
            <span class="ingredient-title">Tilgjengelig på følgende dager</span>
            <fieldset>
              <label v-for="(day, index) in days" :key="index" class="multiple-checkbox-label">
                <input type="checkbox" :name="day" :value="day" @click="setSelectedDays" class="multiple-checkbox"
                       :checked="item.availableOnDays.indexOf(day) > -1" /> {{ getDayName(day) }}
              </label>
            </fieldset>
          </div>

          <div v-if="enableShowAsDiscount()">
            <div class="form-switch-wrapper mt-3 mb-4">
              <label class="form-switch">
                <div class="form-switch-container">
                  <span class="bold me-2">Vis som tilbud</span>
                  <input type="checkbox" v-model="item.showAsDiscount" />
                  <i></i>
                </div>
              </label>
            </div>
          </div>

          <div>
            <span class="bo_label">Bilde</span>
            <input type="file" class="form-control" accept="image/*" ref="file" @change="selectFile" />
          </div>

          <div>
            <span class="bo_label">Beskrivelse</span>
            <textarea id="description" class="form-control" type="text" v-model="item.description" rows="4" cols="50"></textarea>
          </div>

          <div v-if="item.itemType == 'DISH' || item.itemType == 'DRINK_BUBBLE_TEA'" class="mb-4">
            <span class="bo_label">Varianter (enten ingen eller minst to)</span>
            <div class="row">
              <ul class="addedSubselections">
                <li v-for="(subselection, index) in item.subselections" :key="index" class="container mb-1">
                  <div v-if="subselection.id != editingSubselectionId" class="d-flex align-items-center"> <!-- show variant -->
                    <div>
                        <a class="remove-addOn-btn"
                           @click="removeSubselection(subselection)">
                          <img src="@/assets/images/remove.png" width="21" title="Slett variant"/>
                        </a>
                    </div>
                    <div>
                        <div class="bold">{{ subselection.name }}</div>
                        <div class="subselection-text">
                           Kr {{ getPriceWithVat(subselection.price) }} (med {{vat.takeOut}}% mva for Ta Med)
                        </div>
                        <div class="subselection-text">
                          Kr {{ getPriceDineIn(subselection.priceDineIn) }} (med {{vat.dineIn}}% mva for Spise Her)
                        </div>
                        <div class="subselection-text">
                            <div v-for="(addOnGroupId, index) in subselection.addOnGroupIds" :key="index">
                              {{ getAddOnGroup(addOnGroupId).name }}
                            </div>
                        </div>
                    </div>
                    <div>
                        <a @click="editSubselection(subselection)" class="icon-btn">
                          <img src="@/assets/images/edit.png" width="21" title="Rediger variant"/>
                        </a>
                    </div>
                  </div>
                  <div v-else class="editing-variant"> <!-- editing variant -->
                    <div class="col-sm-12 d-inline">
                      <input class="form-control" type="text" placeholder="Navn på variant" v-model="subselectionName" />
                    </div>
                    <div class="row mt-2">
                      <div class="col-sm-6 d-inline-block">
                        <input class="form-control" type="number" min="0" placeholder="Pris på variant" v-model="subselectionPrice" @input="updateSubselectionPriceWithVat()" />
                        <span>(uten MVA)</span>
                      </div>
                      <div class="col-sm-6 d-inline-block">
                        <input class="form-control" type="number" min="0" placeholder="Pris på variant" v-model="subselectionPriceWithVat" @input="updateSubselectionPrice()" />
                        <span>
                           (med MVA for TAKEAWAY, {{ vat.takeOut }}%)
                         </span>
                      </div>
                    </div>

                    <div v-if="useFunctionality === 'POS' || useFunctionality === 'ALL' || websiteShowDineInMenu" class="row mt-2">
                      <div class="col-sm-6 d-inline-block">
                        <input class="form-control" type="number" min="0" placeholder="Pris på variant" v-model="subselectionPriceDineIn" @input="updateSubselectionPriceDineInWithVat()" />
                        <span>(uten MVA)</span>
                      </div>
                      <div v-if="useFunctionality === 'POS' || useFunctionality === 'ALL' || websiteShowDineInMenu" class="col-sm-6 d-inline-block">
                        <input class="form-control" type="number" min="0" v-model="subselectionPriceDineInWithVat" @input="updateSubselectionPriceDineIn()" />
                        <span>
                          (med MVA for SPISE HER, {{ vat.dineIn }}%)
                        </span>
                      </div>
                    </div>

                    <div v-if="useAddOns">
                      <span>Grupper for valg og tillegg for varianten</span>
                      <fieldset class="mt-2 mb-4">
                        <label v-for="addOnGroup in addOnGroups" :key="'edit_' + addOnGroup.id" class="multiple-checkbox-label">
                          <input type="checkbox" :name="addOnGroup.name" :value="addOnGroup.id" @click="setSelectedSubselectionAddOnGroupIds" class="multiple-checkbox"
                                 :checked="subselectionAddOnGroupIds.indexOf(addOnGroup.id) > -1"/> {{ addOnGroup.name }}
                        </label>
                      </fieldset>
                    </div>
                    <div>
                      <button type="button" class="save-btn me-4" @click="cancelEditingSubselection"> Avbryt </button>
                      <button v-if="activateAddSubselectionButton" type="button" class="save-btn" @click="saveSubselection()"> Lagre variant </button>
                      <button v-else type="button" class="save-btn" disabled="true"> Lagre </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div v-if="editingSubselectionId == null" class="variant"> <!-- add variant -->
              <div class="col-sm-12 d-inline">
                <input class="form-control" type="text" placeholder="Navn på variant" v-model="subselectionName" />
              </div>
              <div class="row mt-2">
                <div class="mt-3">TA MED/TAKEAWAY</div>
                <div class="col-sm-6 d-inline-block">
                  <input class="form-control" type="number" min="0" placeholder="Pris på variant" v-model="subselectionPrice" @input="updateSubselectionPriceWithVat()" />
                  <span class="ms-2">(uten MVA)</span>
                </div>
                <div class="col-sm-6 d-inline-block">
                  <input class="form-control" type="number" min="0" placeholder="Pris på variant" v-model="subselectionPriceWithVat" @input="updateSubselectionPrice()" />
                   <span class="ms-2">
                     (med MVA for TAKEAWAY, {{ vat.takeOut }}%)
                   </span>
                </div>
              </div>
              <div v-if="useFunctionality === 'POS' || useFunctionality === 'ALL' || websiteShowDineInMenu" class="row mt-2">
                <div>SPISE HER</div>
                <div class="col-sm-6 d-inline-block">
                  <input class="form-control" type="number" min="0" placeholder="Pris på variant" v-model="subselectionPriceDineIn" @input="updateSubselectionPriceDineInWithVat()" />
                  <span class="ms-2">(uten MVA)</span>
                </div>
                <div class="col-sm-6 d-inline-block">
                  <input class="form-control" type="number" min="0" placeholder="Pris på variant" v-model="subselectionPriceDineInWithVat" @input="updateSubselectionPriceDineIn()" />
                  <span class="ms-2">
                      (med MVA for SPISE HER, {{ vat.dineIn }}%)
                    </span>
                </div>
              </div>

              <div v-if="useAddOns" class="mt-3">
                <span>Grupper for valg og tillegg for varianten</span>
                <fieldset class="mt-2 mb-4">
                  <label v-for="addOnGroup in addOnGroups" :key="'add_' + addOnGroup.id" class="multiple-checkbox-label">
                    <input type="checkbox" :name="addOnGroup.name" :value="addOnGroup.id" @click="setSelectedSubselectionAddOnGroupIds" class="multiple-checkbox"
                           :checked="subselectionAddOnGroupIds.indexOf(addOnGroup.id) > -1"/> {{ addOnGroup.name }}
                  </label>
                </fieldset>
              </div>
              <div>
                <button v-if="activateAddSubselectionButton" type="button" class="save-btn" @click="addSubselection"> Legg til variant </button>
                <button v-else type="button" class="save-btn" disabled="true"> Legg til variant </button>
              </div>
            </div>
          </div>

          <div v-if="item.subselections.length == 0">
              <span v-if="item.itemType == 'DISH' || item.itemType == 'DRINK_BUBBLE_TEA'" class="bo_label">
                  Pris (hvis det er ingen variant)
              </span>
              <span v-else class="bo_label">
                  Pris
              </span>
              <div class="row">
                  <div v-if="item.itemType == 'DELIVERY_SERVICE'">
                    <div class="col-sm-6 d-inline-block">
                      <input class="form-control" type="number" min="0" v-model="priceDineIn" @input="updatePriceDineInWithVat()" />
                      <span>
                             (uten MVA)
                      </span>
                    </div>
                    <!-- vat for delivery service is the same as dineIn vat -->
                    <input class="form-control" type="number" min="0" v-model="priceDineInWithVat" @input="updatePriceDineIn()" />
                    <span>
                      (med MVA for tjeneste, {{ this.vat.dineIn }}%)
                    </span>
                  </div>
                  <div v-else>
                    <div class="row">
                      <div class="mt-3">TA MED/TAKEAWAY</div>
                      <div class="col-sm-6 d-inline-block">
                        <input class="form-control" type="number" min="0" v-model="price" @input="updatePriceWithVat()" />
                        <span>
                             (uten MVA)
                      </span>
                      </div>
                        <div class="col-sm-6 d-inline-block">
                          <input class="form-control" type="number" min="0" v-model="priceWithVat" @input="updatePrice()" />
                          <span>
                            (med MVA for TAKEAWAY, {{ vat.takeOut }}%)
                          </span>
                        </div>
                    </div>
                    <div v-if="useFunctionality === 'POS' || useFunctionality === 'ALL' || websiteShowDineInMenu" class="row">
                      <div class="mt-3">SPISE HER</div>
                      <div class="col-sm-6 d-inline-block">
                        <input class="form-control" type="number" min="0" v-model="priceDineIn" @input="updatePriceDineInWithVat()" />
                        <span>
                             (uten MVA)
                      </span>
                      </div>
                      <div  class="col-sm-6 d-inline-block">
                        <input class="form-control" type="number" min="0" v-model="priceDineInWithVat" @input="updatePriceDineIn()" />
                        <span>
                            (med MVA for SPISE HER, {{ vat.dineIn }}%)
                          </span>
                      </div>
                    </div>
                  </div>
              </div>
          </div>

          <div v-if="(item.itemType == 'DISH' || item.itemType == 'DRINK_BUBBLE_TEA') && showSpicyLevelSelections()">
            <span class="bo_label">Angi de mulige valgene for sterkhetsgrad</span>
            <fieldset>
                <label v-for="(spicyLevel, index) in spicyLevels" :key="index" class="multiple-checkbox-label">
                  <input type="checkbox" :name="spicyLevel.name" :value="spicyLevel.name" @click="setSelectedSpicyLevels" class="multiple-checkbox" /> {{ spicyLevel.name }}
                </label>
            </fieldset>
          </div>

          <div v-if="item.itemType == 'DISH' || item.itemType == 'DRINK_BUBBLE_TEA'">
              <div v-if="useAddOns">
                <span class="bo_label">Grupper for valg og tillegg</span>
                <fieldset class="mt-2 mb-4">
                  <label v-for="(addOnGroup, index) in addOnGroups" :key="index" class="multiple-checkbox-label">
                    <input type="checkbox" :name="addOnGroup.name" :value="addOnGroup.id" @click="setSelectedItemAddOnGroupIds" class="multiple-checkbox" /> {{ addOnGroup.name }}
                  </label>
                </fieldset>
              </div>
          </div>

          <div v-if="item.itemType == 'DISH' || item.itemType == 'DRINK_BUBBLE_TEA'">
            <span class="bo_label">Hvilke allergener inneholder dette?</span>
            <fieldset>
                <label v-for="(allergen, index) in allergens" :key="index" class="multiple-checkbox-label">
                  <input type="checkbox" :name="allergen.name" :value="JSON.stringify(allergen)" @click="setSelectedAllergens" class="multiple-checkbox"  /> {{ allergen.name }}
                </label>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="buttonbar">
          <div class="button-panel">
            <button v-if="activateSaveButton" type="button" class="bo_btn" @click="saveItem"> Lagre produkt </button>
            <button v-else type="button" class="bo_btn" disabled="true"> Lagre produkt </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import ProductService from "@/services/product/ProductService";
import MediaFileService from "@/services/media/MediaFileService";
import ItemUtil from "@/util/ItemUtil";
import LocationUtil from "@/util/LocationUtil";
import MerchantUtil from "@/util/MerchantUtil";
import MathUtil from "@/util/MathUtil";

export default {
  components: {LoadingSpinner},
  data() {
    return {
      loading: true,

      locations: [], //The availableAtLocationIds of the category decides which locations an item belonging to the category can be available at.
      orderTypes: [], //The availableForOrderTypes of the category decides which orderTypes an item belonging to the category can be available at.
      days: ["MONDAY", "TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY","SUNDAY"],
      categoryId: '',
      category: {},
      categories: [],
      addOnGroups: [],
      addOnGroupId:'',
      spicyLevels: [],
      allergens: [],
      vat: {},
      imageFile: undefined,
      imageUrl: "",

      subselectionName: "",
      subselectionPrice: "",              //Takeaway price without VAT
      subselectionPriceWithVat: "",       //Takeaway price with VAT
      subselectionPriceDineIn: "",        //Dine-in price without VAT
      subselectionPriceDineInWithVat: "", //Dine-in price with VAT
      subselectionAddOnGroupIds: [],
      editingSubselectionId: null,

      price: 0,
      priceWithVat: "",
      priceDineIn: "",
      priceDineInWithVat: "",

      item: {
        type: "item",
        merchant: "",
        itemType: "",
        name: "",
        menuNr: "",
        availableAtLocationIds: [],
        availableForOrderTypes: [],
        availableOnDays: ["MONDAY", "TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY","SUNDAY"], //default checked
        showAsDiscount: false,
        imageUrl: "",
        description: "",
        ingredientsText: "",
        categoryId: this.categoryId,
        subselections: [],
        price: 0,
        priceDineIn: 0,
        addOnGroupIds: [],
        includedToppingsCount: 0,
        spicyLevelSelections: [],
        enableComment: false,
        allergens: [],
        zipCodes: "", //Used for DELIVERY_SERVICE itemType, separated with comma, f.ex 2010,2044,
        sortOrder: -1
      }
    }
  },
  mounted() {
    if(this.showAvailableOnDaysHolidayOption){
      this.days.push("HOLIDAY");
    }

    this.categoryId = this.$route.query.categoryId;
    ProductService.getDataForItemAdminGui(this.categoryId).then(
        (response) => {
          let dataForGui = response.data;
          //console.log("dataForGui: " +  JSON.stringify(dataForGui));

          this.category = dataForGui.category;
          if(this.category.availableAtLocationIds === undefined){
            this.category.availableAtLocationIds = []; //init it
          }

          this.categories = dataForGui.categories;
          this.addOnGroups = dataForGui.addOnGroups;
          this.spicyLevels = dataForGui.spicyLevels;
          this.allergens = dataForGui.allergens;
          this.vat = dataForGui.vat;
          this.item.merchant = this.category.merchant;
          this.item.itemType = this.category.itemType;
          this.item.categoryId = this.categoryId;

          /*
           * The availableAtLocationIds of the category decides
           * which locations an item belonging to the category can be available at.
           */
          let allLocations = dataForGui.locations;
          for (let i = 0; i < this.category.availableAtLocationIds.length; i++) {
            let locationId = this.category.availableAtLocationIds[i];
            this.item.availableAtLocationIds.push(locationId); //All available locations selected by default

            for (let l = 0; l < allLocations.length; l++) {
              let location = allLocations[l];
              if (location.id === locationId) {
                this.locations.push(location);
                break;
              }
            }
          }

          /*
           * The availableForOrderTypes of the category decides
           * which orderTypes an item belonging to the category can be available for.
           */
          for (let i = 0; i < this.category.availableForOrderTypes.length; i++) {
            let orderType = this.category.availableForOrderTypes[i];
            this.item.availableForOrderTypes.push(orderType); //All available orderTypes selected by default
            this.orderTypes.push(orderType);
          }

          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
    );
  },
  computed: {
    useFunctionality(){
      return MerchantUtil.useFunctionality();
    },
    useAddOns(){
      return MerchantUtil.useAddOns();
    },
    showAvailableOnDaysHolidayOption(){
      return MerchantUtil.showAvailableOnDaysHolidayOption();
    },
    websiteShowDineInMenu(){
      return MerchantUtil.websiteShowDineInMenu();
    },
    activateAddSubselectionButton() {
      if(this.subselectionName.length > 0 && (this.subselectionPrice > 0 || this.subselectionPriceDineIn > 0)){
          return true;
      } else {
          return false;
      }
    },
    activateSaveButton(){
      if(this.item.itemType == 'DISH' || this.item.itemType == 'DRINK_BUBBLE_TEA'){
        //console.log('item: ' + JSON.stringify(this.item()));
        if(this.item.name.length > 0){
            return true;
        } else {
            return false;
        }
      }else{
        //For itemType: DRINK, ADD_ON or DELIVERY_SERVICE
        if(this.item.name.length > 0 && this.price > 0){
            return true;
        } else {
          return false;
        }
      }
    }
  },
  methods: {
    getOrderTypeName(orderType){
      if(orderType === 'DINE_IN'){
        return 'Spise Her';
      } else if(orderType === 'TAKEAWAY'){
        return 'Takeaway';
      }
    },
    hasMultiLocations(){
      return LocationUtil.hasMultiLocations();
    },
    showAvailableForOrderTypes(){
      if(MerchantUtil.useFunctionality() === 'POS' || MerchantUtil.useFunctionality() === 'ALL' || MerchantUtil.websiteShowDineInMenu()){
        return true;
      }else {
        return false;
      }
    },
    showAvailableOnDays(){
      if((this.item.itemType == 'DISH' || this.item.itemType == 'DRINK_BUBBLE_TEA' ||
          this.item.itemType == 'DRINK') && MerchantUtil.showAvailableOnDays()){
        return true;
      }else {
        return false;
      }
    },
    enableShowAsDiscount(){
      if((this.item.itemType == 'DISH' || this.item.itemType == 'DRINK_BUBBLE_TEA' ||
          this.item.itemType == 'DRINK') && MerchantUtil.enableShowAsDiscount()){
        return true;
      }else {
        return false;
      }
    },
    showSpicyLevelSelections(){
      return MerchantUtil.showSpicyLevelSelections();
    },
    setSelectedLocations(event){
      //console.log(JSON.stringify(this.item.availableAtLocationIds));
      let locationId = event.target.value;
      if(event.target.checked){
        this.item.availableAtLocationIds.push(locationId);
      } else {
        for (let i=0; i < this.item.availableAtLocationIds.length; i++){
          if (this.item.availableAtLocationIds[i] === locationId) {
            this.item.availableAtLocationIds.splice(i,1);
            break;
          }
        }
      }
      //console.log(JSON.stringify(this.item.availableAtLocationIds));
    },
    setSelectedOrderTypes(event){
      //console.log(JSON.stringify(this.item.availableForOrderTypes));
      let orderType = event.target.value;
      if(event.target.checked){
        this.item.availableForOrderTypes.push(orderType);
      } else {
        for (let i=0; i < this.item.availableForOrderTypes.length; i++){
          if (this.item.availableForOrderTypes[i] === orderType) {
            this.item.availableForOrderTypes.splice(i,1);
            break;
          }
        }
      }
      //console.log(JSON.stringify(this.item.availableForOrderTypes));
    },
    getDayName(day){
      if(day === 'MONDAY'){
        return 'Mandag';
      } else if(day == 'TUESDAY'){
        return 'Tirsdag';
      } else if(day == 'WEDNESDAY'){
        return 'Onsdag';
      } else if(day == 'THURSDAY'){
        return 'Torsdag';
      } else if(day == 'FRIDAY'){
        return 'Fredag';
      } else if(day == 'SATURDAY'){
        return 'Lørdag';
      } else if(day == 'SUNDAY'){
        return 'Søndag';
      } else if(day == 'HOLIDAY'){
        return 'Hellig dag';
      }
      return 'DAY_NOT_DEFINED';
    },
    setSelectedDays(event){
      //console.log(JSON.stringify(this.item.availableOnDays));
      let day = event.target.value;
      if(event.target.checked){
        this.item.availableOnDays.push(day);
      } else {
        for (let i=0; i < this.item.availableOnDays.length; i++){
          if (this.item.availableOnDays[i] === day) {
            this.item.availableOnDays.splice(i,1);
            break;
          }
        }
      }
    },
    updatePrice(){
      this.price = ItemUtil.getPriceWithoutVat(this.vat.takeOut, this.priceWithVat);
    },
    updatePriceWithVat(){
      let priceWithVat = this.price + (this.price / 100 * this.vat.takeOut);
      this.priceWithVat = Math.round(priceWithVat);
    },
    updatePriceDineIn(){
       this.priceDineIn = ItemUtil.getPriceWithoutVat(this.vat.dineIn, this.priceDineInWithVat);
    },
    updatePriceDineInWithVat(){
      let priceWithVat = this.priceDineIn + (this.priceDineIn / 100 * this.vat.dineIn);
      this.priceDineInWithVat = Math.round(priceWithVat);
    },

    //Prices for subselection
    updateSubselectionPricesWithVat(){
      let priceWithVat = this.subselectionPrice + (this.subselectionPrice / 100 * this.vat.takeOut);
      this.subselectionPriceWithVat = Math.round(priceWithVat);

      let priceDineInWithVat = this.subselectionPriceDineIn + (this.subselectionPriceDineIn / 100 * this.vat.dineIn);
      this.subselectionPriceDineInWithVat = Math.round(priceDineInWithVat);
    },
    updateSubselectionPriceDineInWithVat(){
      let priceWithVat = this.subselectionPriceDineIn + (this.subselectionPriceDineIn / 100 * this.vat.dineIn);
      this.subselectionPriceDineInWithVat = Math.round(priceWithVat);
    },
    updateSubselectionPriceWithVat(){
      let priceWithVat = this.subselectionPrice + (this.subselectionPrice / 100 * this.vat.takeOut);
      this.subselectionPriceWithVat = Math.round(priceWithVat);
    },
    updateSubselectionPrice(){
      this.subselectionPrice = ItemUtil.getPriceWithoutVat(this.vat.takeOut, this.subselectionPriceWithVat);
    },
    updateSubselectionPriceDineIn(){
      this.subselectionPriceDineIn = ItemUtil.getPriceWithoutVat(this.vat.dineIn, this.subselectionPriceDineInWithVat);
    },
    //End prices for subselection

    selectFile(){
      this.imageFile = this.$refs.file.files[0];
      console.log("imageFile: " + this.imageFile.name)
    },
    removeSubselection(subselection){
      for (let i =0; i < this.item.subselections.length; i++){
        if (this.item.subselections[i].name == subselection.name && this.item.subselections[i].price == subselection.price) {
          this.item.subselections.splice(i,1);
          break;
        }
      }
    },
    addSubselection(){
      let subselection = {
        id : MathUtil.createUUID(),
        name: this.subselectionName,
        price: this.subselectionPrice,
        priceDineIn: this.subselectionPriceDineIn,
        addOnGroupIds: Object.assign([], this.subselectionAddOnGroupIds) //Array is passed by reference
      };
      this.item.subselections.push(subselection);
      this.resetSubselection();
    },
    saveSubselection(){
      this.item.subselections.find((subselection) => {
        if(subselection.id == this.editingSubselectionId){
          subselection.name = this.subselectionName;
          subselection.price = this.subselectionPrice;
          subselection.priceDineIn = this.subselectionPriceDineIn;
          subselection.addOnGroupIds = Object.assign([], this.subselectionAddOnGroupIds); //Array is passed by reference
        }
      });

      this.resetSubselection();
      this.editingSubselectionId = null;
      //console.log('item.subselections: ' + JSON.stringify(this.item.subselections));
    },
    editSubselection(subselection){
      this.editingSubselectionId = subselection.id;
      this.subselectionName = subselection.name;
      this.subselectionPrice = subselection.price;
      this.subselectionPriceDineIn = subselection.priceDineIn;
      this.subselectionAddOnGroupIds = subselection.addOnGroupIds;
      this.updateSubselectionPricesWithVat();
    },
    cancelEditingSubselection(){
      this.editingSubselectionId = null;
      this.resetSubselection();
    },
    resetSubselection(){
      this.subselectionName = "";
      this.subselectionPrice = "";
      this.subselectionPriceWithVat = "";
      this.subselectionPriceDineIn = "";
      this.subselectionPriceDineInWithVat = "";
      this.subselectionAddOnGroupIds = [];
    },
    setSelectedAddOnGroups(event){
      let addOnGroupId = event.target.value;
      if(this.item.addOnGroupIds.length == 0){
        this.item.addOnGroupIds.push(addOnGroupId);
      } else {
        for (let i = 0; i < this.item.addOnGroupIds.length; i++){
          if (this.item.addOnGroupIds[i] === addOnGroupId) {
            this.item.addOnGroupIds.splice(i,1);
            break;
          }
        }
      }
    },
    setSelectedSpicyLevels(event){
      let spiceLevelName = event.target.value;
      if(event.target.checked){
        this.item.spicyLevelSelections.push(spiceLevelName);
      } else {
        for (var i=0; i < this.item.spicyLevelSelections.length; i++){
          if (this.item.spicyLevelSelections[i] === spiceLevelName) {
            this.item.spicyLevelSelections.splice(i,1);
            break;
          }
        }
      }
    },
    setSelectedAllergens(event){
        //console.log(JSON.stringify(item.allergens));
        let allergenJson = JSON.parse(event.target.value);
        if(event.target.checked){
          this.item.allergens.push(allergenJson);
        } else {
          for (var i=0; i < this.item.allergens.length; i++){
            if (this.item.allergens[i].name === allergenJson.name && this.item.allergens[i].imageUrl === allergenJson.imageUrl) {
              this.item.allergens.splice(i,1);
              break;
            }
          }
        }
    },
    setSelectedSubselectionAddOnGroupIds(event){
      let addOnGroupId = event.target.value;
      if(event.target.checked){
        this.subselectionAddOnGroupIds.push(addOnGroupId);
      } else {
        for (let i = 0; i < this.subselectionAddOnGroupIds.length; i++){
          if (this.subselectionAddOnGroupIds[i] == addOnGroupId) {
            this.subselectionAddOnGroupIds.splice(i,1);
            break;
          }
        }
      }
    },
    setSelectedItemAddOnGroupIds(event){
      let addOnGroupId = event.target.value;
      if(event.target.checked){
        this.item.addOnGroupIds.push(addOnGroupId);
      } else {
        for (let i = 0; i < this.item.addOnGroupIds.length; i++){
          if (this.item.addOnGroupIds[i] == addOnGroupId) {
            this.item.addOnGroupIds.splice(i,1);
            break;
          }
        }
      }
    },
    getAddOnGroup(addOnGroupId){
      for (let i = 0; i < this.addOnGroups.length; i++){
        let addOnGroup = this.addOnGroups[i];
        if (addOnGroup.id == addOnGroupId) {
            return addOnGroup;
        }
      }
      return undefined;
    },
    getPriceWithVat(priceWithoutVat){
      return ItemUtil.getPriceWithVat(priceWithoutVat, this.vat);
    },
    getPriceDineIn(priceWithoutVat){
      return ItemUtil.getPriceDineIn(priceWithoutVat, this.vat);
    },
    saveItem(){
      this.loading = true;
      //Updating item properties
      this.item.price = this.price;
      this.item.priceDineIn = this.priceDineIn;
      //console.log(JSON.stringify(this.item));
      if(this.imageFile){
        MediaFileService.uploadImageFile(this.imageFile).then(
            (response) => {
              let imageUrl = response.data;
              if(imageUrl){
                //console.log("imageUrl: " + imageUrl);
                this.item.imageUrl = imageUrl;
                this.createItem(this.item);
              } else {
                console.log("Uploading image failed");
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      } else {
        this.createItem(this.item);
      }
    },
    createItem(item){
      ProductService.createItem(item).then(
          (response) => {
            let documentId = response.data;
            if(documentId){
              this.$router.push({ path: 'products'});
            } else {
              console.log("Creating item failed");
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    }
  }
}
</script>

<style scoped>

.button-panel {
  border-radius: 5px;
  border: 2px solid #f9fafb;
  padding: 17px 17px 17px 17px;
  background-color: #f9fafb;
  margin-bottom: 20px;
}

/**
 * lg: 992px breakpoint or larger
 */
@media screen and (min-width: 992px) {
  .buttonbar {
    position: sticky;
    top: 10px;
    margin-top: 35px;
  }
}

</style>

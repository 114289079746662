<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4">
    <div style="max-width:597px">
        <h3>Endre tidligst hentetid</h3>
        <p class="mt-4">Hentetid er den tiden det tar fra en bestilling kommer inn til den blir hentet.</p>

        <div v-if="hasMultiLocations()" class="select-location-box mt-4">
          <span class="ingredient-title">Velg avdeling</span>
          <select class="form-select" v-model="locationId">
            <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
          </select>
        </div>

        <div class="mt-4 mb-5">
          <span class="ingredient-title">Velg tidligst hentetid</span>

          <button type="button" :class="isCurrent(10) ? 'earliestPickupTime_btn_selected' : 'earliestPickupTime_btn'" @click="setEarliestPickupTime(10)"> 10 min <span v-if="earliestPickupTime == 10" class="check ms-2"></span></button>
          <button type="button" :class="isCurrent(15) ? 'earliestPickupTime_btn_selected' : 'earliestPickupTime_btn'" @click="setEarliestPickupTime(15)"> 15 min <span v-if="earliestPickupTime == 15" class="check ms-2"></span></button>
          <button type="button" :class="isCurrent(20) ? 'earliestPickupTime_btn_selected' : 'earliestPickupTime_btn'" @click="setEarliestPickupTime(20)"> 20 min <span v-if="earliestPickupTime == 20" class="check ms-2"></span></button>
          <button type="button" :class="isCurrent(25) ? 'earliestPickupTime_btn_selected' : 'earliestPickupTime_btn'" @click="setEarliestPickupTime(25)"> 25 min <span v-if="earliestPickupTime == 25" class="check ms-2"></span></button>
          <button type="button" :class="isCurrent(30) ? 'earliestPickupTime_btn_selected' : 'earliestPickupTime_btn'" @click="setEarliestPickupTime(30)"> 30 min <span v-if="earliestPickupTime == 30" class="check ms-2"></span></button>
          <button type="button" :class="isCurrent(35) ? 'earliestPickupTime_btn_selected' : 'earliestPickupTime_btn'" @click="setEarliestPickupTime(35)"> 35 min <span v-if="earliestPickupTime == 35" class="check ms-2"></span></button>
          <button type="button" :class="isCurrent(40) ? 'earliestPickupTime_btn_selected' : 'earliestPickupTime_btn'" @click="setEarliestPickupTime(40)"> 40 min <span v-if="earliestPickupTime == 40" class="check ms-2"></span></button>
          <button type="button" :class="isCurrent(45) ? 'earliestPickupTime_btn_selected' : 'earliestPickupTime_btn'" @click="setEarliestPickupTime(45)"> 45 min <span v-if="earliestPickupTime == 45" class="check ms-2"></span></button>
          <button type="button" :class="isCurrent(50) ? 'earliestPickupTime_btn_selected' : 'earliestPickupTime_btn'" @click="setEarliestPickupTime(50)"> 50 min <span v-if="earliestPickupTime == 50" class="check ms-2"></span></button>
          <button type="button" :class="isCurrent(55) ? 'earliestPickupTime_btn_selected' : 'earliestPickupTime_btn'" @click="setEarliestPickupTime(55)"> 55 min <span v-if="earliestPickupTime == 55" class="check ms-2"></span></button>
        </div>
      </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import MerchantUtil from "@/util/MerchantUtil";
import SettingsService from "@/services/settings/SettingsService";
import LocationUtil from "@/util/LocationUtil";
import LocationService from "@/services/location/LocationService";

export default {
    components: {LoadingSpinner},
    data() {
      return {
        loading: false,
        locationId: '',
        locations: [],
        earliestPickupTime: 0
      };
    },
    computed: {
      activateSaveButton(){
        if(this.earliestPickupTime > 0){
          return true;
        } else {
          return false;
        }
      }
    },
    mounted() {
      this.locationId = this.$route.query.locationId;

      LocationService.listLocations().then(
          (response) => {
            this.locations = response.data;

            let location = this.getLocation(this.locationId);
            this.earliestPickupTime = location.earliestPickupTime;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      getLocation(locationId){
        for (let i = 0; i < this.locations.length; i++) {
          let location = this.locations[i];
          if(location.id == locationId){
            return location;
          }
        }
        return undefined;
      },
      save(){
        this.loading = true;
        this.changeEarliestPickupTime(MerchantUtil.getMerchant(), this.locationId, this.earliestPickupTime);
      },
      changeEarliestPickupTime(merchant, locationId, earliestPickupTime){
        SettingsService.changeEarliestPickupTime(merchant, locationId, earliestPickupTime).then(
            (response) => {
              let documentId = response.data;
              if(documentId){
                this.$router.push({ path: 'overview' });
              } else {
                console.log("changeEarliestPickupTime failed");
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      isCurrent(minutes){
          return (minutes == this.earliestPickupTime);
      },
      setEarliestPickupTime(minutes){
        this.earliestPickupTime = minutes;
        this.save();
      }
    }
  };
</script>

<template>
  <div class="mb-5">
    <div class="pdf-icon">
      <a @click="createPdf">
        <img src="@/assets/images/pdf.png" alt="PDF" height="32" />
      </a>
    </div>
    <div id="x-report" class="xz-report">
      <XReportHeader :location="location" :company="company" :terminal="terminal" />
      <XReportTotalSales :xReportCounters="xReportCounters" :vat="vat" />
      <XReportCategories :xReportCounters="xReportCounters" :vat="vat" />
      <XReportSalesPayments :xReportCounters="xReportCounters" :vat="vat" />
      <XReportRefund :xReportCounters="xReportCounters" :vat="vat" />

      <!-- XReportVariousCounters :xReportCounters="xReportCounters" /-->
    </div>
  </div>
</template>

<script>
  import XReportHeader from "@/components/report/xreport/XReportHeader";
  import XReportTotalSales from "@/components/report/xreport/XReportTotalSales";
  import XReportCategories from "@/components/report/xreport/XReportCategories";
  import XReportSalesPayments from "@/components/report/xreport/XReportSalesPayments";
  import XReportRefund from "@/components/report/xreport/XReportRefund";
  //import XReportVariousCounters from "@/components/report/xreport/XReportVariousCounters";
  import PdfUtil from "@/util/PdfUtil";

  export default {
    //components: {XReportHeader, XReportSalesPayments, XReportTotalSales, XReportVariousCounters},
    components: {XReportHeader, XReportTotalSales, XReportCategories, XReportSalesPayments, XReportRefund},
    props: {
      location: Object,
      terminal: Object,
      xReportCounters: Object,
      company: Object,
      vat: Object
    },
    methods: {
      createPdf() {
        PdfUtil.generatePdf('x-report', "X-rapport.pdf");
      }
    }
  }
</script>

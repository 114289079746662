<template>
  <div>
    <div v-for="item in items" :key="item.id" :id="item.id">
      <Item :item="item" :vat="vat"/>
    </div>
  </div>
</template>

<script>
import Item from "@/components/product/Item";

export default {
  components: {Item},
  props: {
    items: Array,
    vat: Object
  },
  methods: {
  }
}
</script>

import { createWebHistory, createRouter } from "vue-router";
import Login from "./pages/users/Login.vue";
import LoginSuperAdmin from "@/pages/users/LoginSuperAdmin";

import Overview from "./pages/dashboard/Overview.vue";
import XReport from "./pages/reports/XReport.vue";
import ZReport from "./pages/reports/ZReport.vue";
import WebshopXReport from "./pages/reports/WebshopXReport.vue";
import WebshopZReport from "./pages/reports/WebshopZReport.vue";
import TurnoverReport from "./pages/reports/TurnoverReport.vue";
import SMSReport from "./pages/reports/SMSReport.vue";
import WebshopXReportRegenerate from "./pages/reports/WebshopXReportRegenerate.vue";
import WebshopXReportRegenerateAll from "./pages/reports/WebshopXReportRegenerateAll.vue";

import Orders from "./pages/orders/Orders.vue";
import OrderDetail from "./pages/orders/OrderDetail.vue";
import OrderRefund from "./pages/orders/OrderRefund.vue";
import SoftpayOrderRefund from "./pages/webview/SoftpayOrderRefund.vue";

import Products from "./pages/products/Products.vue";
import CategoryCreate from "./pages/products/CategoryCreate.vue";
import CategoryEdit from "./pages/products/CategoryEdit.vue";
import AddOnGroupCreate from "./pages/products/AddOnGroupCreate.vue";
import AddOnGroupEdit from "./pages/products/AddOnGroupEdit.vue";
import AddOnCreate from "./pages/products/AddOnCreate.vue";
import AddOnEdit from "./pages/products/AddOnEdit.vue";
import CategoriesSort from "./pages/products/CategoriesSort.vue";
import ItemCreate from "./pages/products/ItemCreate.vue";
import ItemEdit from "./pages/products/ItemEdit.vue";
import ItemsOfCategorySort from "./pages/products/ItemsOfCategorySort.vue";
import AddOnGroupsSort from "./pages/products/AddOnGroupsSort.vue";
import AddOnsOfAddOnGroupSort from "./pages/products/AddOnsOfAddOnGroupSort.vue";

import Users from "./pages/users/Users.vue";
import UserCreate from "./pages/users/UserCreate.vue";
import UserEdit from "./pages/users/UserEdit.vue";

import Terminals from "./pages/terminals/Terminals.vue";

import Discounts from "./pages/loyalty/Discounts.vue";
import DiscountCreate from "./pages/loyalty/DiscountCreate.vue";
import DiscountEdit from "./pages/loyalty/DiscountEdit.vue";
import Rewards from "./pages/loyalty/Rewards.vue";
import RewardCreate from "./pages/loyalty/RewardCreate.vue";
import RewardEdit from "./pages/loyalty/RewardEdit.vue";

import OpeningHours from "./pages/settings/OpeningHours.vue";

import Tables from "./pages/tables/Tables.vue";
import TableCreate from "./pages/tables/TableCreate.vue";

import Delivery from "./pages/settings/Delivery.vue";
import ClosingDays from "./pages/settings/ClosingDays.vue";
import ClosingDaysCreate from "./pages/settings/ClosingDaysCreate.vue";
import CloseTakeaway from "./pages/settings/CloseTakeaway.vue";
import OpenTakeaway from "./pages/settings/OpenTakeaway.vue";
import ChangeEarliestPickupTime from "./pages/settings/ChangeEarliestPickupTime.vue";
import CloseDishes from "./pages/settings/CloseDishes.vue";
import OpenDishes from "./pages/settings/OpenDishes.vue";
import Vat from "./pages/settings/Vat.vue";
import VatEdit from "./pages/settings/VatEdit.vue";
import Company from "./pages/settings/Company.vue";
import Locations from "./pages/settings/Locations.vue";
import LocationCreate from "./pages/settings/LocationCreate.vue";
import LocationEdit from "./pages/settings/LocationEdit.vue";
import Dintero from "./pages/settings/Dintero.vue";
import Merchant from "./pages/settings/Merchant.vue";
import CloudPRNT from "./pages/support/CloudPRNT.vue";

import Cache from "./pages/system/Cache.vue";

const Profile = () => import("./pages/users/Profile.vue") //lazy-loaded

const routes = [
   {
     path: "/",
     name: "overview",
     component: Overview
   },
   {
     path: "/overview",
     component: Overview
   },
   {
     path: "/login",
     component: Login
   },
    {
        path: "/superadmin/login",
        component: LoginSuperAdmin
    },
   {
     path: "/profile",
     name: "profile",
     component: Profile
    },
    {
        path: "/x-report",
        name: "x-report",
        component: XReport
    },
    {
        path: "/z-report",
        name: "z-report",
        component: ZReport
    },
    {
        path: "/webshop-xreport",
        name: "webshop-xreport",
        component: WebshopXReport
    },
    {
        path: "/webshop-zreport",
        name: "webshop-zreport",
        component: WebshopZReport
    },
    {
        path: "/totalSalesReport",
        name: "totalSalesReport",
        component: TurnoverReport
    },
    {
        path: "/smsReport",
        name: "smsReport",
        component: SMSReport
    },
    {
        path: "/webshopXReportRegenerate",
        name: "webshopXReportRegenerate",
        component: WebshopXReportRegenerate
    },
    {
        path: "/webshopXReportRegenerateAll",
        name: "webshopXReportRegenerateAll",
        component: WebshopXReportRegenerateAll
    },
    {
        path: "/orders",
        name: "orders",
        component: Orders
    },
    {
        path: "/orderDetail",
        name: "orderDetail",
        component: OrderDetail
    },
    {
        path: "/orderRefund",
        name: "orderRefund",
        component: OrderRefund
    },
    {
        path: "/softpayOrderRefund",
        name: "softpayOrderRefund",
        component: SoftpayOrderRefund
    },
    {
        path: "/products",
        name: "products",
        component: Products
    },
    {
        path: "/categoryCreate",
        name: "categoryCreate",
        component: CategoryCreate
    },
    {
        path: "/categoryEdit",
        name: "categoryEdit",
        component: CategoryEdit
    },
    {
        path: "/categoriesSort",
        name: "categoriesSort",
        component: CategoriesSort
    },
    {
        path: "/itemCreate",
        name: "itemCreate",
        component: ItemCreate
    },
    {
        path: "/itemEdit",
        name: "itemEdit",
        component: ItemEdit
    },
    {
        path: "/itemsOfCategorySort",
        name: "itemsOfCategorySort",
        component: ItemsOfCategorySort
    },
    {
        path: "/addOnGroupsSort",
        name: "addOnGroupsSort",
        component: AddOnGroupsSort
    },
    {
        path: "/addOnsOfAddOnGroupSort",
        name: "addOnsOfAddOnGroupSort",
        component: AddOnsOfAddOnGroupSort
    },
    {
        path: "/addOnGroupCreate",
        name: "addOnGroupCreate",
        component: AddOnGroupCreate
    },
    {
        path: "/addOnGroupEdit",
        name: "addOnGroupEdit",
        component: AddOnGroupEdit
    },
    {
        path: "/addOnCreate",
        name: "addOnCreate",
        component: AddOnCreate
    },
    {
        path: "/addOnEdit",
        name: "addOnEdit",
        component: AddOnEdit
    },
    {
        path: "/users",
        name: "users",
        component: Users
    },
    {
        path: "/userCreate",
        name: "userCreate",
        component: UserCreate
    },
    {
        path: "/userEdit",
        name: "userEdit",
        component: UserEdit
    },
    {
        path: "/terminals",
        name: "terminals",
        component: Terminals
    },
    {
        path: "/rewards",
        name: "rewards",
        component: Rewards
    },
    {
        path: "/rewardCreate",
        name: "rewardCreate",
        component: RewardCreate
    },
    {
        path: "/rewardEdit",
        name: "rewardEdit",
        component: RewardEdit
    },
    {
        path: "/discounts",
        name: "discounts",
        component: Discounts
    },
    {
        path: "/discountCreate",
        name: "discountCreate",
        component: DiscountCreate
    },
    {
        path: "/discountEdit",
        name: "discountEdit",
        component: DiscountEdit
    },
    {
        path: "/openinghours",
        name: "openinghours",
        component: OpeningHours
    },
    {
        path: "/tables",
        name: "tables",
        component: Tables
    },
    {
        path: "/tableCreate",
        name: "tableCreate",
        component: TableCreate
    },
    {
        path: "/delivery",
        name: "delivery",
        component: Delivery
    },
    {
        path: "/closingdays",
        name: "closingdays",
        component: ClosingDays
    },
    {
        path: "/closingDaysCreate",
        name: "closingDaysCreate",
        component: ClosingDaysCreate
    },
    {
        path: "/closeTakeaway",
        name: "closeTakeaway",
        component: CloseTakeaway
    },
    {
        path: "/openTakeaway",
        name: "openTakeaway",
        component: OpenTakeaway
    },
    {
        path: "/changeEarliestPickupTime",
        name: "changeEarliestPickupTime",
        component: ChangeEarliestPickupTime
    },
    {
        path: "/closeDishes",
        name: "closeDishes",
        component: CloseDishes
    },
    {
        path: "/openDishes",
        name: "openDishes",
        component: OpenDishes
    },
    {
        path: "/vat",
        name: "vat",
        component: Vat
    },
    {
        path: "/vatEdit",
        name: "vatEdit",
        component: VatEdit
    },
    {
        path: "/company",
        name: "company",
        component: Company
    },
    {
        path: "/locations",
        name: "locations",
        component: Locations
    },
    {
        path: "/locationCreate",
        name: "locationCreate",
        component: LocationCreate
    },
    {
        path: "/locationEdit",
        name: "locationEdit",
        component: LocationEdit
    },
    {
        path: "/dintero",
        name: "dintero",
        component: Dintero
    },
    {
        path: "/merchant",
        name: "merchant",
        component: Merchant
    },
    {
        path: "/cloudprnt",
        name: "cloudprnt",
        component: CloudPRNT
    },
    {
        path: "/cache",
        name: "cache",
        component: Cache
    }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
   const publicPages = ['/login', '/superadmin/login'];
   const authRequired = !publicPages.includes(to.path);
   const loggedIn = localStorage.getItem('user');

   //Trying to access a restricted page + not logged in, redirect to login page.
   if (authRequired && !loggedIn) {
     next('/login');
   } else {
     next();
   }
});

export default router;

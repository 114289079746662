<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4">
    <div style="max-width:597px">
        <h3>Steng takeaway midlertidig</h3>
        <p class="mt-4">Etter at takeaway er stengt, vil det ikke være mulig for brukere å bestille takeaway online for i dag.</p>

        <div v-if="hasMultiLocations()" class="select-location-box mt-4">
          <span class="ingredient-title">Velg avdeling</span>
          <select class="form-select" v-model="locationId">
            <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
          </select>
        </div>

        <div class="d-none">
          <SelectDate ref="selectDateFrom" label="Fra dato"/>
          <SelectDate ref="selectDateTo" label="Til dato"/>
        </div>
        <div class="mt-4 mb-5">
          <span class="ingredient-title">Informasjon som skal vises til brukere</span>
          <input type='text' id="message" class="form-control" v-model="message" />
        </div>

        <hr/>
        <button v-if="activateSaveButton" type="button" class="bo_btn" @click="save"> Steng takeaway </button>
        <button v-else type="button" class="bo_btn" @click="save" disabled="true"> Steng takeaway </button>
      </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import SelectDate from "@/components/other/SelectDate";
import MerchantUtil from "@/util/MerchantUtil";
import SettingsService from "@/services/settings/SettingsService";
import moment from "moment";
import LocationUtil from "@/util/LocationUtil";
import LocationService from "@/services/location/LocationService";

export default {
    components: {LoadingSpinner, SelectDate},
    data() {
      return {
        loading: false,
        locationId: '',
        locations: [],
        message: 'Online takeaway er midlertidig stengt for i dag.'
      };
    },
    computed: {
      activateSaveButton(){
        if(this.message.length > 0){
          return true;
        } else {
          return false;
        }
      }
    },
    mounted() {
      this.locationId = this.$route.query.locationId;

      LocationService.listLocations().then(
          (response) => {
            this.locations = response.data;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      save(){
        this.loading = true;

        let fromDate =  this.$refs.selectDateFrom.getSelectedDate();
        let toDate =  this.$refs.selectDateTo.getSelectedDate();
        let closingDays = {
          type: "closingdays",
          merchant: MerchantUtil.getMerchant(),
          locationId: this.locationId,
          fromDate: (moment(fromDate)).format('YYYY-MM-DD'),
          toDate: (moment(toDate)).format('YYYY-MM-DD'),
          message: this.message
        };
        this.createClosingDays(closingDays);
      },
      createClosingDays(closingDays){
        SettingsService.createClosingDays(closingDays).then(
            (response) => {
              let documentId = response.data;
              if(documentId){
                this.$router.push({ path: 'overview' });
              } else {
                console.log("Close online takeaway for today failed");
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      }
    }
  };
</script>

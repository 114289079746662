<template>
  <div class="container-fluid">
    <table class="xz-table">
      <thead>
      <tr>
        <th class="bold">Kategori</th>
        <th class="bold">MVA%</th>
        <th class="bold">MVA</th>
        <th class="bold">Brutto</th>
      </tr>
      </thead>
      <tbody>

      <tr v-for="category in categories" :key="category.id">
        <td>{{ category.name }}</td>
        <td>{{ category.vatRate }}%</td>
        <td>{{ displayTwoDecimals(getVatAmount(category)) }}</td>
        <td>{{ displayTwoDecimals(category.amountWithVat) }}</td>
      </tr>

      </tbody>
    </table>
    <hr class="horizontal-dashed"/>
  </div>
</template>

<script>
import MathUtil from "@/util/MathUtil";

export default {
    props: {
      zReports: Array,
      vat: Object
    },
    computed: {
      categories() {
        var totalCategories = [];

        for (var i = 0; i < this.zReports.length; i++) {
          var zReport = this.zReports[i].cagistahospitality;
          for (var c = 0; c < zReport.categories.length; c++) {
              //Make a clone of the category object, otherwise the accumulation below will modify the original category object in the original zReports array.
              //This will show wrong data in the TurnoverReportTotalSales component of the same page.
              let category = Object.assign({}, zReport.categories[c]);

              var existedCategory = this.getCategory(category.id, category.vatRate, totalCategories);
              if(existedCategory != null){
                existedCategory.vatAmount = existedCategory.vatAmount + category.vatAmount;
                existedCategory.amountWithVat = existedCategory.amountWithVat + category.amountWithVat;
              }else {
                totalCategories.push(category);
              }
          }
        }
        return totalCategories;
      }
    },
    methods: {
      getVatAmount(category){
        var vatAmount = (category.amountWithVat / (100 + category.vatRate)) * category.vatRate;
        return vatAmount;
      },
      getCategory(categoryId, vatRate, totalCategories){
        for (var i = 0; i < totalCategories.length; i++) {
          var category = totalCategories[i];
          if(categoryId == category.id && vatRate == category.vatRate){
            return category;
          }
        }
        return null;
      },
      displayTwoDecimals(num){
        if(num > 0 || num < 0){
          return MathUtil.displayTwoDecimals(num);
        }else {
          return num;
        }
      }
    }
  }
</script>

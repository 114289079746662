<template>
  <div class="container-fluid">
    <table class="xz-table">
      <thead>
      <tr>
        <th class="bold">Refusjon</th>
        <th class="bold">Antall</th>
        <th class="bold"></th>
        <th class="bold">Beløp</th>
      </tr>
      </thead>
      <tbody>

      <tr>
        <td>Tilbakebetalt</td>
        <td>{{zReport.refundReceiptsCount}}</td>
        <td></td>
        <td>{{zReport.refundReceiptsTotalAmount}}</td>
      </tr>

      </tbody>
    </table>
    <hr class="horizontal-dashed"/>
  </div>
</template>

<script>
export default {
  props: {
    zReport: Object,
    vat: Object
  },
  computed: {

  }
}
</script>

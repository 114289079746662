<template>
  <div class="container">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div class="bo_categories">
        <h3>Endre rekkefølgen av produkter i kategorien</h3>
        <div class="dragndrop-info">Dra og slipp for å endre rekkefølgen på hvordan produktene vises for brukere. Tilslutt klikk på "Lagre" knappen nederst.</div>

        <ul id="itemsList" class="bo_categoriesList">
          <li v-for="item in items" :key="item.id" :id="item.id" class="category-row" > {{ item.menuNr }} {{ item.name }} </li>
        </ul>

        <div class="bo_savebtn_container">
          <hr/>
          <button type="button" class="bo_btn" @click="saveOrderOfItems()">Lagre</button>
        </div>
      </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import ProductService from "@/services/product/ProductService";
import Sortable from 'sortablejs';

export default {
  components: {LoadingSpinner},
  data() {
    return {
      loading: true,
      items : []
    };
  },
  mounted() {
    this.categoryId = this.$route.query.categoryId;
    ProductService.getItemsOfCategory(this.categoryId).then(
        (response) => {
          this.items = response.data;
          let el = document.getElementById('itemsList');
          Sortable.create(el);

          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
    );
  },
  methods: {
   updateSortOrder(itemId, sortOrder) {
      //console.log(sortOrder + ' : ' + itemId);
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i].id == itemId) {
          this.items[i].sortOrder = sortOrder;
          break; //Stop this loop, we found it!
        }
      }
    },
    saveOrderOfItems(){
     this.loading = true;

      let listItems = document.getElementById('itemsList').getElementsByTagName('li');
      for (var i = 0; i < listItems.length; i++) {
        let li = listItems[i];
        let itemId = li.getAttribute('id');
        this.updateSortOrder(itemId, i);
      }

      ProductService.editItems(this.items).then(
          (response) => {
            if(response.data === "SUCCEEDED"){
              this.$router.push({ path: 'products'});
            } else {
              console.log("Updating sortOrder of items failed");
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    }
  }
};
</script>

<template>
  <div class="container">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div class="row vertical-align mt-3 ms-1 me-1">
        <div class="col-sm-6">
          <h3>Regenerere oppgjørsrapport for nettbutikken</h3>
          <p>1. BACKUP hele databasen.</p>
          <p>2. Om nødvendig, kall på servicen backoffice_orderFixVats for å regenerere og fikse vats array av ordre, eller kall på backoffice_orderUnsettle for å sette "settle=false".</p>
          <p>3. For riktig lokasjon: slett alle webshop z-report for alle dato fra den dato da det først feilet fordi z-report nr inkrementeres av antall.
                Regenerere z-report på nytt for alle dager etter denne datoen.</p>

          <div v-if="hasMultiLocations()" class="mt-5">
            <span class="ingredient-title">Velg avdeling</span>
            <select class="form-select" v-model="locationId" @change="showXReport()">
              <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
            </select>
          </div>

          <SelectDate ref="selectDate" :label="'Velg dato'" />
          <button type="button" class="bo_btn" :class="mb-5" @click="showXReport"> Vis dagens oppgjør for nettbutikken </button>

          <div v-if="xReportCounters != null">
              <div v-if="isXReportCountersEmpty" class="mt-4 mb-5">
                  <p>
                    Ingen omsetning fra nettbutikken siden forrige oppgjør.
                  </p>
              </div>
              <div v-else>
                  <ShowWebshopXReportRegenerere :xReportCounters="xReportCounters" :company="company" :vat="vat" :selectedDate="selectedDate"/>
                  <button type="button" class="bo_btn mb-5" @click="createWebshopZReport">Regenerere oppgjørsrapport for nettbutikken</button>
                  <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
              </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import ShowWebshopXReportRegenerere from "@/components/report/xreport/ShowWebshopXReportRegenerere";
  import ConfirmDialogue from '@/components/other/ConfirmDialogue.vue'
  import ReportService from "@/services/report/ReportService";
  import LocationUtil from "@/util/LocationUtil";
  import MerchantUtil from "@/util/MerchantUtil";
  import SelectDate from "@/components/other/SelectDate";

  export default {
    components: {LoadingSpinner, SelectDate, ShowWebshopXReportRegenerere, ConfirmDialogue},
    data() {
      return {
        loading: true,

        locationId: '',
        locations: [],
        merchant: '',
        company: {},
        vat: {},
        xReportCounters: null,
        selectedDate: ''
      };
    },
    computed: {
      useFunctionality(){
        return MerchantUtil.useFunctionality();
      },
      isXReportCountersEmpty: function () {
        if(this.xReportCounters.vippsPickupVat == 0 &&
            this.xReportCounters.vippsDeliveryVat == 0 &&
            this.xReportCounters.vippsDeliveryServiceVat == 0 &&
            this.xReportCounters.vippsPickupBrutto == 0 &&
            this.xReportCounters.vippsDeliveryBrutto == 0 &&
            this.xReportCounters.vippsDeliveryServiceBrutto == 0 &&
            this.xReportCounters.cardPickupVat == 0 &&
            this.xReportCounters.cardDeliveryVat == 0 &&
            this.xReportCounters.cardDeliveryServiceVat == 0 &&
            this.xReportCounters.cardPickupBrutto == 0 &&
            this.xReportCounters.cardDeliveryBrutto == 0 &&
            this.xReportCounters.cardDeliveryServiceBrutto == 0 &&
            this.xReportCounters.refundReceiptsCount == 0 &&
            this.xReportCounters.refundReceiptsTotalAmount == 0){
          return true;
        } else {
          return false;
        }
      }
    },
    mounted() {
      ReportService.getDataForReportGui().then(
          (response) => {
            let dataForGui = response.data;
            this.locations = dataForGui.locations;
            this.company = dataForGui.company;
            this.vat = dataForGui.vat;
            this.merchant = dataForGui.merchant;

            this.locationId = this.locations.length ? this.locations[0].id : '';
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      showXReport(){
        this.loading = true;

        let selectedDate = this.$refs.selectDate.getSelectedDate();
        this.selectedDate = selectedDate;
        console.log("selectedDate: " + selectedDate);

        ReportService.getWebshopXReportCountersForDate(this.locationId, selectedDate).then(
            (response) => {
              this.xReportCounters = response.data;
              console.log("xReportCounters: " + JSON.stringify(this.xReportCounters));
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      async createWebshopZReport(){
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Regenerere dagens oppgjør for nettbutikken',
          message: 'Vil du generere oppgjørsrapport for nettbutikken?',
          okButton: 'Ja',
          cancelButton: 'Avbryt'
        })
        if(ok) {
            ReportService.createWebshopZReportForDate(this.locationId, this.selectedDate).then(
                  (response) => {
                    if(response.data != null){ //Z-report is created, response.data :== documentId
                        this.showXReport(); //refresh GUI
                    }else {
                      console.log("Generering av oppgjørsrapport for nettbutikken feiler.");
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
              );
        } else {
          //Cancel, do nothing
        }
      }
    }
  };
</script>

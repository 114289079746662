class LocationUtil {
    hasMultiLocations(){
        let locations = this.getLocations();
        return (locations.length > 1);
    }

    getLocations(){
        let user = JSON.parse(localStorage.getItem('user'));
        let locations = user.locations;
        return locations;
    }

    getLocation(locationId){
        let user = JSON.parse(localStorage.getItem('user'));
        let locations = user.locations;
        for (let i = 0; i < locations.length; i++) {
            let location = locations[i];
            if(location.id == locationId){
                return location;
            }
        }
        return undefined;
    }
}

export default new LocationUtil();

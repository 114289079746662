<template>
  <div class="col-md-12 d-flex align-items-center justify-content-center" style="height:100vh">
    <div class="card card-container">
      <img
        id="profile-img"
        src="@/assets/images/user.svg"
        class="bg-image profile-img-card"
      />
      <Form @submit="handleLogin" :validation-schema="schema">
        <div class="form-group">
          <label for="username">Brukernavn</label>
          <Field id="username" name="username" type="text" class="form-control" />
          <ErrorMessage name="username" class="error-feedback" />
        </div>
        <div class="form-group">
          <label for="password">Passord</label>
          <Field id="password" name="password" type="password" class="form-control" />
          <ErrorMessage name="password" class="error-feedback" />
        </div>

        <div class="form-group">
          <button class="btn btn-bg btn- btn-block mt-4" style="width:100%" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm me-1"
            ></span>
            <span>Logg inn</span>
          </button>
        </div>

        <div class="form-group">
          <div v-if="message" class="alert alert-danger mt-1" role="alert">
            {{ message }}
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
  import { Form, Field, ErrorMessage } from "vee-validate";
  import * as yup from "yup";

  export default {
    components: {
      Form,
      Field,
      ErrorMessage,
    },
    data() {
      const schema = yup.object().shape({
        username: yup.string().required("Fyll ut brukernavn"),
        password: yup.string().required("Fyll ut passord"),
      });

      return {
        loading: false,
        message: "",
        schema
      };
    },
    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      }
    },
    created() {
      if (this.loggedIn) {
        this.$router.push("/home");
      }
    },
    methods: {
      handleLogin(user) {
        this.loading = true;

        user.merchant = this.$route.query.merchant; //Get merchant from query param
        this.$store.dispatch("auth/superAdminLogin", user).then(
          () => {
            this.$router.push("/overview");
          },
          (error) => {
            this.loading = false;
            this.message = "SuperAdmin: Feil brukernavn/passord eller merchant param missing";
            console.log("Innlogging mislyktes: " + JSON.stringify(error));
          }
        );
      },
    },
  };
</script>

<style scoped>
  label {
    display: block;
    margin-top: 10px;
  }

  .card-container.card {
    max-width: 350px !important;
    padding: 0px 40px 40px 40px;
  }

  .card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  }

  .bg-image {
    background-color: #212529;
    color: #fff;
  }

  .btn-bg {
    background-color: #B30E10;
    color: #fff;
  }

  .profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto -20px;
    transform: translate(0px, -50px);
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }

  .error-feedback {
    color: #B30E10;
  }
</style>

import axios from "axios";
import authHeader from '@/services/auth/AuthHeader';

class TableService {
    listTables(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/table/listTables' + '?merchant=' + user.merchant;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getTable(tableId){
        let url = '/api/table/getTable' + '?tableId=' + tableId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    createTable(table){
        let url = '/api/table/createTable';
        let basicAuth = authHeader();
        return axios.post(url, table,{
            headers: basicAuth });
    }

    editTable(table){
        let url = '/api/table/editTable';
        let basicAuth = authHeader();
        return axios.post(url, table,{
            headers: basicAuth });
    }

    deleteTable(table){
        let url = '/api/table/deleteTable';
        let basicAuth = authHeader();
        return axios.post(url, table, {
            headers: basicAuth}
        );
    }
}

export default new TableService();

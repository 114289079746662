<template>
  <div class="mb-5">
    <div class="pdf-icon">
      <a @click="createPdf">
        <img src="@/assets/images/pdf.png" alt="PDF" height="32" />
      </a>
    </div>
    <div id="z-report" className="xz-report">
      <WebshopZReportHeader :location="location" :zReport="zReport" />
      <WebshopZReportTotalSales :zReport="zReport" :vat="vat" />
      <WebshopZReportCategories :zReport="zReport" :vat="vat" />
      <WebshopZReportSalesPayments :zReport="zReport" :vat="vat" />
      <WebshopZReportRefund :zReport="zReport" :vat="vat" />
    </div>
  </div>
</template>

<script>
  import WebshopZReportHeader from "@/components/report/zreport/WebshopZReportHeader";
  import WebshopZReportTotalSales from "@/components/report/zreport/WebshopZReportTotalSales";
  import WebshopZReportCategories from "@/components/report/zreport/WebshopZReportCategories";
  import WebshopZReportSalesPayments from "@/components/report/zreport/WebshopZReportSalesPayments";
  import WebshopZReportRefund from "@/components/report/zreport/WebshopZReportRefund";

  import html2canvas from "html2canvas";
  import jsPDF from "jspdf";

  export default {
    components: {WebshopZReportHeader, WebshopZReportTotalSales, WebshopZReportCategories, WebshopZReportSalesPayments, WebshopZReportRefund},
    props: {
      zReport: Object,
      company: Object,
      location: Object,
      vat: Object
    },
    methods: {
      createPdf() {
        //PdfUtil.generatePdf('z-report', "Webshop_Z-rapport.pdf"); //This creates misformed PDF
        const input = document.getElementById('z-report');
        html2canvas(input).then(function(canvas) {
          const imgData = canvas.toDataURL('image/png');
          //window.open(imgData); //Open image to see if canvas is rendered correctly.
          const width = canvas.width;
          const height = canvas.height;
          const pdf = new jsPDF('p', 'px', [width, height], true);
          pdf.addImage(imgData, 0, 0);
          pdf.save('Webshop_Z-rapport.pdf');
        });
      }
    }
  }
</script>

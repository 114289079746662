class VatUtil {
    getVatRate(vatDoc, orderType, itemType) {
        if(itemType === 'DELIVERY_SERVICE'){
            return Math.round(vatDoc.dineIn); //Service vat is the same as dineIn
        } else {
            if(orderType === 'DINE_IN'){
                return Math.round(vatDoc.dineIn);
            } else if(orderType === 'PICKUP'){
                return Math.round(vatDoc.takeOut);
            }else if(orderType === 'DELIVERY'){
                return Math.round(vatDoc.delivery);
            }
        }
        return 0;
    }
}

export default new VatUtil();
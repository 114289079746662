<template>
  <div class="container">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div class="row vertical-align mt-3 ms-1 me-1">
        <div class="col-sm-6">
          <h3>Regenerere ALLE webshop oppgjørsrapporter for en merchant</h3>
          <p>1. BACKUP hele databasen.</p>
          <p>2. Slett alle webshop z-report fra og med den dagen dvs query nr > xx.</p>
          <p>3. Etter ferdig generert, sjekk om nr stemmer fra og med den dagen.</p>

          <div v-if="!loading">
              <div v-if="hasMultiLocations()" class="mt-5">
                <span class="ingredient-title">Velg avdeling</span>
                <select class="form-select" v-model="locationId" @change="showXReport()">
                    <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
                </select>
              </div>

              <SelectDate ref="selectDate" :label="'Fra og med dato'" />

              <button type="button" class="bo_btn mb-5" @click="createWebshopZReports">Regenerere ALLE webshop oppgjørsrapporter</button>
              <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import ConfirmDialogue from '@/components/other/ConfirmDialogue.vue'
  import ReportService from "@/services/report/ReportService";
  import LocationUtil from "@/util/LocationUtil";
  import MerchantUtil from "@/util/MerchantUtil";
  import SelectDate from "@/components/other/SelectDate";

  export default {
    components: {LoadingSpinner, SelectDate, ConfirmDialogue},
    data() {
      return {
        loading: false,

        locationId: '',
        locations: [],
        merchant: '',
        company: {},
        vat: {},
        xReportCounters: {},
        selectedDate: ''
      };
    },
    computed: {
      useFunctionality(){
        return MerchantUtil.useFunctionality();
      },
      isXReportCountersEmpty: function () {
        if(this.xReportCounters.vippsPickupVat == 0 &&
            this.xReportCounters.vippsDeliveryVat == 0 &&
            this.xReportCounters.vippsDeliveryServiceVat == 0 &&
            this.xReportCounters.vippsPickupBrutto == 0 &&
            this.xReportCounters.vippsDeliveryBrutto == 0 &&
            this.xReportCounters.vippsDeliveryServiceBrutto == 0 &&
            this.xReportCounters.cardPickupVat == 0 &&
            this.xReportCounters.cardDeliveryVat == 0 &&
            this.xReportCounters.cardDeliveryServiceVat == 0 &&
            this.xReportCounters.cardPickupBrutto == 0 &&
            this.xReportCounters.cardDeliveryBrutto == 0 &&
            this.xReportCounters.cardDeliveryServiceBrutto == 0){
          return true;
        } else {
          return false;
        }
      }
    },
    mounted() {
      ReportService.getDataForReportGui().then(
          (response) => {
            let dataForGui = response.data;
            this.locations = dataForGui.locations;
            this.company = dataForGui.company;
            this.vat = dataForGui.vat;
            this.merchant = dataForGui.merchant;

            this.locationId = this.locations.length ? this.locations[0].id : '';
            //this.showXReport();
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      showXReport(){
        this.loading = true;

        let selectedDate = this.$refs.selectDate.getSelectedDate();
        this.selectedDate = selectedDate;
        console.log("selectedDate: " + selectedDate);

        ReportService.getWebshopXReportCountersForDate(this.locationId, selectedDate).then(
            (response) => {
              this.xReportCounters = response.data;
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      async createWebshopZReports(){
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Regenerere ALLE webshop oppgjørsrapporter',
          message: 'Vil du regenerere ALLE webshop oppgjørsrapporter?',
          okButton: 'Ja',
          cancelButton: 'Avbryt'
        })
        if(ok) {
            this.loading = true;

            let selectedDate = this.$refs.selectDate.getSelectedDate();
            this.selectedDate = selectedDate;
            console.log("selectedDate: " + selectedDate);

            ReportService.createAllWebshopZReports(this.locationId, this.selectedDate).then(
                  (response) => {
                    if(response.data != null){ //Z-reports is created
                      this.loading = false;
                    }else {
                      console.log("Generering av ALLE webshop oppgjørsrapporter feiler.");
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
              );
        } else {
          //Cancel, do nothing
        }
      }
    }
  };
</script>

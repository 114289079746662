<template>
  <div class="container-fluid">
    <table class="xz-table">
      <thead>
      <tr>
        <th class="bold">Refusjon</th>
        <th class="bold">Antall</th>
        <th class="bold"></th>
        <th class="bold">Beløp</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Tilbakebetalt</td>
        <td>{{xReportCounters.refundReceiptsCount}}</td>
        <td></td>
        <td>{{ displayTwoDecimals(xReportCounters.refundReceiptsTotalAmount) }}</td>
      </tr>
      </tbody>
    </table>
    <hr class="horizontal-dashed"/>
  </div>
</template>

<script>
  import MathUtil from "@/util/MathUtil";

  export default {
    props: {
      xReportCounters: Object,
      vat: Object
    },
    methods: {
      displayTwoDecimals(num){
        if(num > 0 || num < 0){
          return MathUtil.displayTwoDecimals(num);
        }else {
          return num;
        }
      }
    }
  }
</script>

<template>
  <div class="mb-5">
    <div class="pdf-icon">
      <!--a @click="createPdf">
        <img src="@/assets/images/pdf.png" alt="PDF" height="32" />
      </a -->
    </div>
    <div id="sms-report" class="xz-report">
          <div>
              <div class="center">
                <span class="xz-title bold">{{location.name}}</span><br/>
                <span>Org.nr. {{location.vatNumber}} MVA</span>
                <hr class="horizontal-dashed"/>
                <span class="xz-title bold">SMS rapport</span>
              </div>
              <div class="center">
                <div>Fra og med dato: {{ formatDate(fromDate) }}</div>
                <div>Til og med dato: {{ formatDate(toDate) }}</div>

                <div class="mt-3">Total antall sendt SMS: {{ totalSmsCount() }}</div>
                <div>Pris per utsendt SMS: 1 NOK</div>
                <div class="bold">SMS kostnad for perioden: {{ totalSmsCount() }} NOK</div>
              </div>
              <hr class="horizontal-dashed"/>
          </div>

          <div> 1 SMS inneholder max 160 tegn. Hvis en melding inneholder flere enn 160 tegn, da blir meldingen satt sammen av flere SMS. </div>
          <div class="table-responsive-sm mt-3">
            <table class="table table-striped">
              <thead>
              <tr>
                <th v-if="hasMultiLocations()">Avdeling</th>
                <th>Meldingstype</th>
                <th>Meldingstekst</th>
                <th style="width: 110px">Antall SMS</th>
                <th style="width: 110px">Sendt tid</th>
                <th>MeldingsID</th>
                <th>OrdreID #</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="sms in messages" :key="sms.id">
                <td v-if="hasMultiLocations()">
                  <span>{{ getLocation(sms.locationId).name }}</span>
                </td>
                <td>
                  <span>{{ getMessageTypeText(sms.messageType) }}</span>
                </td>
                <td>
                  <span style="white-space: pre-wrap;">{{ sms.message }}</span>
                </td>
                <td>
                  <span>{{ sms.smsCount }}</span>
                </td>
                <td>
                  <span>{{ getDateTimeText(sms.sentTime) }}</span>
                </td>
                <td>
                  <span>{{ sms.messageId }}</span>
                </td>
                <td>
                  <span>{{ sms.orderId }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import LocationUtil from "@/util/LocationUtil";
import moment from "moment/moment";

export default {
  props: {
    messages: Array,
    location: Object,
    fromDate: String,
    toDate: String
  },
  methods: {
    hasMultiLocations(){
      return LocationUtil.hasMultiLocations();
    },
    totalSmsCount(){
      let count = 0;
      for (let i = 0; i < this.messages.length; i++) {
         let message = this.messages[i];
          count = count + message.smsCount;
      }
      return count;
    },
    getMessageTypeText(messageType){
      if(messageType == 'USER_REGISTRATION'){
          return 'Brukerregistrering';
      } else if(messageType == 'ORDER_CONFIRMATION'){
          return 'Ordrebekreftelse';
      } else if(messageType == 'FOOD_READY'){
        return 'Maten klar melding';
      }
      return '';
    },
    getLocation(locationId){
      return LocationUtil.getLocation(locationId);
    },
    formatDate(date) {
      return (moment(date)).format('DD.MM.YYYY');
    },
    getDateTimeText(dateTime){
      let dateTimeMoment = moment(dateTime);
      return dateTimeMoment.format('DD.MM.YYYY HH:mm:ss');
    },
    createPdf() {
      const fileName = 'SMS_Rapport_' + this.location.name.replace(' ', '_') + '.pdf';
      const input = document.getElementById('sms-report');
      html2canvas(input).then(function(canvas) {
        const imgData = canvas.toDataURL('image/png');
        //window.open(imgData); //Open image to see if canvas is rendered correctly.
        const width = canvas.width;
        const height = canvas.height;
        const pdf = new jsPDF('l', 'pt', [width, height], true);
        pdf.addImage(imgData, 0, 0);
        pdf.save(fileName);
      });
    }
  }
}
</script>

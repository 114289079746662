<template>
  <div class="container">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div class="mt-4" style="max-width:597px">
        <div v-if="step == 1">
            <div>
              <h3>Opprett rabatt</h3>
              <p class="mt-4">Hvis rabatt skal gjelde i kun én dag, velger du samme dag på gyldig-fra-og-med-dato og til-og-med-dato.</p>

              <div>
                <span class="bo_label">Rabattnavn</span>
                <input type='text' class="form-control" v-model="discount.name" />
              </div>

              <div class="form-switch-wrapper mt-2 mb-3">
                <label class="form-switch">
                  <div class="form-switch-container">
                    <span class="me-2">Vis som en kategory</span>
                    <input type="checkbox" v-model="discount.showAsCategory" />
                    <i></i>
                  </div>
                </label>
              </div>

              <div>
                <span class="bo_label">Beskrivelse</span>
                <input type='text' class="form-control" v-model="discount.description" />
              </div>

              <div>
                <span class="bo_label">Prosent(%) rabatt</span>
                <input class="form-control" type="number" placeholder="f.eks 5" min="0" v-model="discount.percent" />
              </div>

              <SelectDate ref="selectDateFrom" label="Gyldig fra og med dato" :selectedDate="discount.fromDate" />
              <SelectDate ref="selectDateTo" label="Til og med dato" :selectedDate="discount.toDate" />

              <div class="discount-select-product">
                <div class="d-flex clearfix">
                  <div class="bo_label left-align mt-2 ms-2">Gjelder for produkter</div>
                  <div class="right-align mt-2 mb-2 me-2">
                    <button v-if="selectedItems.length == 0" type="button" class="bo_btn" @click="selectProduct"> Velg produkt </button>
                    <button v-else type="button" class="bo_select_remove_product_btn" @click="selectProduct"> Velg eller fjerne produkt </button>
                  </div>
                </div>

                <div v-for="item in selectedItems" :key="item.id">
                  <div class="item">
                    <div class="item-col-left">
                    </div>
                    <div class="item-col-right">
                      <div class="flex-box">
                        <div>
                          <span>{{ item.menuNr }} {{item.name}} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="hasMultiLocations()">
                <span class="ingredient-title">Gjelder for følgende avdelinger</span>
                <fieldset>
                  <label v-for="(location, index) in locations" :key="index" class="multiple-checkbox-label">
                    <input type="checkbox" :name="location.name" :value="location.id" @click="setSelectedLocations" class="multiple-checkbox"
                           :checked="discount.validForLocationIds.indexOf(location.id) > -1" /> {{ location.name }}
                  </label>
                </fieldset>
              </div>

              <hr/>
              <button v-if="activateSaveButton" type="button" class="bo_btn mb-5" @click="save"> Lagre </button>
              <button v-else type="button" class="bo_btn mb-5" @click="save" disabled="true"> Lagre </button>
            </div>
        </div>

        <div v-if="step == 2">
          <div>
            <div class="clearfix">
              <h3 class="float-start">Velg produkt</h3>
              <div class="float-end">
                  <button class="bo_btn" @click="back"> Tilbake </button>
                  <button v-if="selectedItems.length > 0" class="bo_btn ms-3" @click="ok"> OK ({{ selectedItems.length }})</button>
                  <button v-else class="bo_btn ms-3" @click="ok"> OK </button>
              </div>
            </div>

            <hr class="mb-0"/>

            <div class="mt-4">
              <button type="button" class="bo_btn" @click="selectAllOrRemoveAll"> {{selectAllOrRemoveAllButtonText()}} </button>
            </div>

            <div class="mt-3">
              <div v-for="itemsOfCategory in itemsOfCategories" :key="itemsOfCategory.id" class="bo_items-of-category">
                <div class="bo_items_of_category_panel">
                  <span class="bo_items-of-category-header"> {{ itemsOfCategory.name }} </span>
                </div>

                <div v-for="item in itemsOfCategory.items" :key="item.id">
                  <div class="item">
                    <div class="item-col-left">
                      <span> <img :src="item.imageUrl" width="100" height="100"/> </span>
                    </div>
                    <div class="item-col-right">
                      <div class="flex-box">
                        <div>
                          <span class="item-title">{{ item.menuNr }} &nbsp;&nbsp;  {{item.name}} </span>
                          <br/>
                          <span class="description">{{item.description}}</span><br/>
                        </div>
                        <div class="cart-buttons-panel">
                          <input type="checkbox" :name="item.id" :value="item.id" :checked="selectedItemsContains(item)" @click="updateSelectedItems(item)" class="checkbox" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import SelectDate from "@/components/other/SelectDate";
import MerchantUtil from "@/util/MerchantUtil";
import LoyaltyService from "@/services/loyalty/LoyaltyService";
import moment from "moment";
import LocationUtil from "@/util/LocationUtil";

export default {
    components: {LoadingSpinner, SelectDate},
    data() {
      return {
        loading: false,
        step: 1,

        locations: [],
        selectedItems: [],
        items: [],
        itemsOfCategories: [],

        discount: {
          merchant: '',
          type: 'discount',
          name: '',
          showAsCategory: false,
          description: '',
          percent: '',
          fromDate: (moment(new Date())).format('YYYY-MM-DD'),
          toDate: (moment(new Date())).format('YYYY-MM-DD'),
          itemIds: [],
          validForLocationIds: [],
          active: true
        }
      };
    },
    computed: {
      activateSaveButton(){
        if(this.discount.name.length > 0 && this.discount.percent > 0 && this.selectedItems.length > 0){
          return true;
        } else {
          return false;
        }
      }
    },
    mounted() {
      LoyaltyService.getDataForDiscountAdminGui().then(
          (response) => {
            this.locations = response.data.locations;
            for (let i = 0; i < this.locations.length; i++) {
              let locationId = this.locations[i].id;
              this.discount.validForLocationIds.push(locationId); //All available locations selected by default
            }

            let categories = response.data.categories;
            let items = response.data.items;
            this.items = items;
            this.itemsOfCategories = this.getItemsOfCategories(categories, items);
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      selectAllOrRemoveAllButtonText(){
        if(this.selectedItems.length == this.items.length){
          return 'Fjern alle valgte';
        }else {
          return 'Velg alle';
        }
      },
      selectAllOrRemoveAll(){
        //console.log('BEFORE: ' + this.selectedItems.length);
        if(this.selectedItems.length == this.items.length){
          //Remove all
          this.selectedItems = [];
        }else {
          //Add all
          for (let i = 0; i < this.items.length; i++) {
            if(!this.selectedItemsContains(this.items[i])){
              this.selectedItems.push(this.items[i]);
            }
          }
        }
        //console.log('AFTER: ' + this.selectedItems.length);
      },
      getItemsOfCategories(categories, items){
        var itemsOfCategories = [];
        for (var i = 0; i < categories.length; i++) {
          var category = categories[i];
          var tempItems = [];
          for (var x = 0; x < items.length; x++) {
            var item = items[x];
            if(item.categoryId == category.id){
              tempItems.push(item)
            }
          }
          //Remove already found items of category
          items = items.filter(function(item) {
            return tempItems.indexOf(item) === -1;
          });

          //Sort tempItems by asc sortOrder
          tempItems.sort(function(a, b) {
            if(a.sortOrder == b.sortOrder) { return 0; }
            if(a.sortOrder > b.sortOrder) {
              return 1;
            }else {
              return -1;
            }
          });

          var itemsOfCategory = {
            id  : category.id,
            sortOrder: category.sortOrder,
            name : category.name,
            items : tempItems
          };
          itemsOfCategories.push(itemsOfCategory);
        }

        //Sort itemsOfCategory by asc sortOrder
        itemsOfCategories.sort(function(a, b) {
          if(a.sortOrder == b.sortOrder) { return 0; }
          if(a.sortOrder > b.sortOrder) {
            return 1;
          }else {
            return -1;
          }
        });

        return itemsOfCategories;
      },
      setSelectedLocations(event){
        //console.log(JSON.stringify(this.discount.validForLocationIds));
        let locationId = event.target.value;
        if(event.target.checked){
          this.discount.validForLocationIds.push(locationId);
        } else {
          for (let i=0; i < this.discount.validForLocationIds.length; i++){
            if (this.discount.validForLocationIds[i] == locationId) {
              this.discount.validForLocationIds.splice(i,1);
              break;
            }
          }
        }
        //console.log(JSON.stringify(this.discount.validForLocationIds));
      },
      selectProduct(){
        let fromDate =  this.$refs.selectDateFrom.getSelectedDate();
        let toDate =  this.$refs.selectDateTo.getSelectedDate();
        this.discount.fromDate = (moment(fromDate)).format('YYYY-MM-DD'); //bind it otherwise it will reset when go back
        this.discount.toDate = (moment(toDate)).format('YYYY-MM-DD');

        this.step = 2;
      },
      back(){
        this.step = 1;
        this.selectedItems = []; //reset it if just go back.
      },
      ok(){
        this.step = 1;
      },
      selectedItemsContains(Item){
        for (let i = 0; i < this.selectedItems.length; i++) {
          let selectedItem = this.selectedItems[i];
          if(selectedItem.id == Item.id){
            return true;
          }
        }
        return false;
      },
      updateSelectedItems(item){
        //console.log('BEFORE Count: ' + this.selectedItems.length);
        //console.log('BEFORE: ' + JSON.stringify(this.selectedItems));
        if(this.selectedItemsContains(item)){
          //Already selected, remove it
          for (let i = 0; i < this.selectedItems.length; i++) {
            if (this.selectedItems[i].id == item.id) {
              this.selectedItems.splice(i, 1);
              break;
            }
          }
        }else {
          //Add it
          this.selectedItems.push(item);
        }
        //console.log('AFTER: ' + JSON.stringify(this.selectedItems));
        //console.log('AFTER Count: ' + this.selectedItems.length);
      },
      save(){
        this.loading = true;

        let fromDate =  this.$refs.selectDateFrom.getSelectedDate();
        let toDate =  this.$refs.selectDateTo.getSelectedDate();

        this.discount.merchant = MerchantUtil.getMerchant();
        this.discount.fromDate = (moment(fromDate)).format('YYYY-MM-DD')
        this.discount.toDate = (moment(toDate)).format('YYYY-MM-DD')
        this.discount.itemIds = this.getItemIds();

        this.createDiscount(this.discount);
      },
      getItemIds(){
        let itemIds = [];
        for (let i = 0; i < this.selectedItems.length; i++) {
          let selectedItem = this.selectedItems[i];
          itemIds.push(selectedItem.id);
        }
        return itemIds;
      },
      createDiscount(discount){
        LoyaltyService.createDiscount(discount).then(
            (response) => {
              let documentId = response.data;
              if(documentId){
                this.$router.push({ path: 'discounts' });
              } else {
                console.log("Creating discount failed");
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      }
    }
  };
</script>

<style scoped>
.checkbox{
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
</style>

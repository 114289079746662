<template>
  <div class="container-fluid text-center">
    <h6>Månedlig salgsstatistikk</h6>
    <vue3-chart-js v-bind="{ ...barChart }" />
  </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

export default {
  props: {
    totalSalesPerMonthLabels: Array,
    totalSalesPerMonth: Array
  },
  components: {
    Vue3ChartJs,
  },
  setup(props) {
    const barChart = {
      type: "bar",
      data: {
        labels: props.totalSalesPerMonthLabels,
        datasets: [
          {
            label: "Total kr",
            backgroundColor: "#B30E10",
            data: props.totalSalesPerMonth
          }
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            ticks: {
              beginAtZero: true
            }
          },
        },
      }
    };

    return {
      barChart,
    };
  },
};
</script>

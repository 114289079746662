<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4">
    <h3>Rabatter</h3>
    <p class="mt-4" style="max-width:790px">Du kan sette opp hvor mange prosent rabatt det gjelder for et utvalg av produkter i en periode.</p>
    <div class="bo_responsive-users-table-header">
          <span class="right-align">
              <button type="button" class="bo_btn" @click="goToCreatePage"> + Opprett rabatt </button>
          </span>
    </div>

    <div class="table-responsive-sm mt-3">
        <table class="table table-striped">
        <thead>
          <tr>
              <th></th>
              <th>Rabattnavn</th>
              <th>Beskrivelse</th>
              <th>% rabatt</th>
              <th>Gyldig fra og med dato</th>
              <th>Til og med dato</th>
              <th>Gjelder for produkter</th>
              <th>Gjelder for avdelinger</th>
              <th></th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="discount in discounts" :key="discount.id">
              <td>
                <a class="remove-user-btn"
                   @click="removeDiscount(discount)">
                  <img src="@/assets/images/remove.png" width="21" title="Slett"/>
                </a>
              </td>
              <td>
                {{ discount.name }}
              </td>
              <td>
                {{ discount.description }}
              </td>
              <td>
                {{ discount.percent }}
              </td>
              <td>
                {{ getDateText(discount.fromDate) }}
              </td>
              <td>
                {{ getDateText(discount.toDate) }}
              </td>
              <td>
                {{ getItemsText(discount) }}
              </td>
              <td>
                {{ getLocationsText(discount.validForLocationIds) }}
              </td>
              <td>
                <a class="edit-location-btn"
                   @click="goToEditDiscountPage(discount.id)">
                  <img src="@/assets/images/edit.png" width="21" title="Rediger"/>
                </a>
              </td>
            </tr>
        </tbody>
      </table>
    </div>
  </div>
  <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
</template>

<script>
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import LoyaltyService from "@/services/loyalty/LoyaltyService";
  import ConfirmDialogue from "@/components/other/ConfirmDialogue";
  import moment from "moment";
  import MerchantUtil from "@/util/MerchantUtil";
  import LocationUtil from "@/util/LocationUtil";

  export default {
    components: {LoadingSpinner, ConfirmDialogue},
    data() {
      return {
        loading: true,
        discounts: [],
        items: []
      };
    },
    computed: {
      useFunctionality(){
        return MerchantUtil.useFunctionality();
      }
    },
    mounted() {
      this.getDiscounts();
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      getLocationName(locationId){
        let location = LocationUtil.getLocation(locationId);
        return location.name;
      },
      goToCreatePage(){
        this.$router.push({ path: 'discountCreate'});
      },
      goToEditDiscountPage(discountId){
        this.$router.push({ path: 'discountEdit', query: {
            discountId: discountId
          }
        });
      },
      getDiscounts(){
        LoyaltyService.getDiscounts().then(
            (response) => {
              //console.log(JSON.stringify(response.data));
              this.discounts = response.data.discounts;
              this.items = response.data.items;

              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      getItemsText(discount){
        let text = '';
        for(let i = 0; i < discount.itemIds.length; i++){
          let itemId = discount.itemIds[i];
          let item = this.items.find(x => x.id === itemId);
          if(item.menuNr){
            text = text + ', ' + item.menuNr + ' ' + item.name;
          }else{
            text = text + ', ' + item.name;
          }
        }
        text = text.slice(2, text.length); //remove the first ', '
        return text;
      },
      getDateText(date){
        var dateMoment = moment(date);
        return dateMoment.format('DD.MM.YYYY');
      },
      getLocationsText(locationIds){
        let text = '';
        for(let i = 0; i < locationIds.length; i++){
          let locationId = locationIds[i];
          let locationName = LocationUtil.getLocation(locationId).name;
          text = text + ', ' + locationName;
        }
        text = text.slice(2, text.length); //remove the first ', '
        return text;
      },
      async removeDiscount(discount){
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Slett rabatt ' + discount.name,
          message: 'Vil du slette rabatt: ' + discount.name + '?',
          okButton: 'Slett',
          cancelButton: 'Avbryt'
        })
        if(ok) {
          LoyaltyService.deleteDiscount(discount).then(
              (response) => {
                if(response.data === 'SUCCEEDED'){
                  this.$router.go(); //Reload current page
                }else {
                  console.log("Deleting discount failed");
                }
              },
              (error) => {
                console.log(error);
              }
          );
        } else {
          //Cancel, do nothing
        }
      }
    }
  };
</script>

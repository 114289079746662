<template>
  <div class="container">
      <LoadingSpinner :loading='loading'> </LoadingSpinner>

      <div class='mt-4 mb-4' style='max-width:560px'>
        <div class="mt-5">
          <h3>dinteroAccessTokenCache</h3>

          <div class="mt-3">
            <span style="white-space: pre-wrap;">
              {{ cacheInfoText() }}
            </span>
          </div>

          <div class='bo_hr mt-4'>
            <hr/>
          </div>

          <div class='mb-5'>
            <button type='button' class='bo_btn' @click='emptyCache'> Empty cache </button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import SystemService from "@/services/system/SystemService";

  export default {
    components: {LoadingSpinner},
    data() {
      return {
        loading: false,
        cacheInfo: {}
      };
    },
    mounted() {
      SystemService.getCacheInfo().then(
          (response) => {
            if(response.data !== null){
              this.cacheInfo = response.data;
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      cacheInfoText(){
        return JSON.stringify(this.cacheInfo, null, 2); //Pretty `JSON.stringify()` Output
      },
      emptyCache(){
        this.loading = true;
        SystemService.emptyCache().then(
            (response) => {
              if(response.data !== null){
                this.cloudprnt = response.data;
              }
              this.loading = false;
              this.$router.go(); //Reload current page
            },
            (error) => {
              console.log(error);
            }
        );
      }
    }
  };
</script>

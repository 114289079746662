<template>
  <loading v-model:active="isLoading"
           :height=50 :width=50 color="#212529" :loader="loaderType"/>
</template>

<script>
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    components: {Loading},
    props: {
      loading: Boolean
    },
    data: () => {
      return {
        loaderType: 'spinner'
      }
    },
    computed: {
      isLoading() {
        return this.loading;
      }
    }
  }
</script>


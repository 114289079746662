import authHeader from "@/services/auth/AuthHeader";
import axios from "axios";

class TerminalService {
    getTerminals(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/terminal/getTerminals' + '?merchant=' + user.merchant;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getTerminalsForLocation(locationId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/terminal/getTerminalsForLocation' + '?merchant=' + user.merchant + '&locationId=' + locationId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

}

export default new TerminalService();

<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4 mb-4">
    <div style="max-width:560px">
        <h3>Legg til bord</h3>
        <div v-if="hasMultiLocations()" class="select-location-box mt-5">
          <span class="ingredient-title">Velg avdeling</span>
          <select class="form-select" v-model="table.locationId">
            <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
          </select>
        </div>
        <div class="mt-4">
          <span class="ingredient-title">Navn</span>
          <input id="name" class="form-control" type="text" v-model="table.name" placeholder="f.eks. Bord 1"/>
        </div>

        <div>
          <span class="ingredient-title">Antall sitteplass</span>
          <input id="capacity" class="form-control" type="number" min="0" v-model="table.capacity" placeholder="f.eks. 4"/>
        </div>

        <div>
          <span class="ingredient-title">Beskrivelse</span>
          <input id="description" class="form-control" type="text" v-model="table.description" placeholder="f.eks. Dine egne notater angående bordet"/>
        </div>

        <div class="bo_hr mt-4">
          <hr/>
        </div>

        <div class="mb-5">
          <button v-if="activateSaveButton" type="button" class="bo_btn" @click="saveTable()"> Lagre </button>
          <button v-else type="button" class="bo_btn" @click="saveTable()" disabled="true"> Lagre </button>
        </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import MerchantUtil from "@/util/MerchantUtil";
import TableService from "@/services/table/TableService";
import LocationUtil from "@/util/LocationUtil";
import LocationService from "@/services/location/LocationService";

export default {
  components: {LoadingSpinner},
  data() {
    return {
      loading: false,

      locations: [],

      table: {
        type: "table",
        merchant: "",
        locationId: '',
        name: "",
        capacity: "",
        description: "",
        status: "ACTIVE"
      }
    };
  },
  computed: {
    activateSaveButton(){
      if(this.table.name.length > 0 && this.table.capacity > 0){
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    LocationService.listLocations().then(
        (response) => {
          this.locations = response.data;
          this.table.locationId = this.locations.length ? this.locations[0].id : '';
        },
        (error) => {
          console.log(error);
        }
    );
  },
  methods: {
    hasMultiLocations(){
      return LocationUtil.hasMultiLocations();
    },
    saveTable(){
      this.loading = true;

      this.table.merchant = MerchantUtil.getMerchant();
      console.log(JSON.stringify(this.table));
      this.createTable(this.table);
    },
    createTable(table){
      TableService.createTable(table).then(
          (response) => {
            let documentId = response.data;
            if(documentId){
              this.$router.push({ path: 'tables'});
            } else {
              console.log("Creating table failed");
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    }
  }
};
</script>

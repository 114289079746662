<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4">
    <h3 class="mb-4">Bord</h3>

    <div class="bo_responsive-users-table-header mt-4">
          <span class="right-align">
              <button type="button" class="bo_btn" @click="goToAddTablePage()"> + Legg til bord </button>
          </span>
    </div>

    <div class="row gx-4" ref='table'>
      <div v-for="table in tables" :key="table.id" class="col-lg-4" style="min-width: 300px">
        <div class="p-3 table">
          <div v-if="hasMultiLocations()">
            <span style="font-weight: bold">{{ getLocationName(table.locationId) }}</span>
          </div>
          <div class="bold">
            <span style="text-transform: uppercase"> {{ table.name }} </span>
          </div>

          <div class="right-align vertical-center" style="font-size: 28px; color:#ECB109;">
            <div>
              <a class="add-to-cart-btn"
                 @click="deleteTable(table)">
                <img src="@/assets/images/remove.png" width="21" title="Slett produkt"/>
              </a>
            </div>
          </div>

          <div>
            <span>Antall sitteplass: {{ table.capacity }}</span>
          </div>
          <div>
            <span>Beskrivelse: </span> <span> {{ table.description }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import LocationUtil from "@/util/LocationUtil";
import TableService from "@/services/table/TableService";
import ConfirmDialogue from "@/components/other/ConfirmDialogue";

export default {
  components: {LoadingSpinner, ConfirmDialogue},
  data() {
    return {
      loading: true,
      tables: []
    };
  },
  mounted() {
    TableService.listTables().then(
        (response) => {
          this.tables = response.data;
          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
    );
  },
  methods: {
     hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
     getLocationName(locationId){
        let location = LocationUtil.getLocation(locationId);
        return location.name;
      },
     goToAddTablePage(){
       this.$router.push({ path: 'tableCreate' });
      },
    async deleteTable(table){
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Slett bordet "' + table.name + '"',
        message: 'Vil du slette bordet "' + table.name + '"?',
        okButton: 'Slett',
        cancelButton: 'Avbryt'
      })
      if(ok) {
        TableService.deleteTable(table).then(
            (response) => {
              if(response.data != null){
                this.$router.go(); //Reload current page
              }else {
                console.log("Deleting table failed");
              }
            },
            (error) => {
              console.log(error);
            }
        );
      } else {
        //Cancel, do nothing
      }
    }

  }
}
</script>

<style scoped>
.table {
   border-radius: 10px;
   border: 2px solid #eaecee;
   background-color:  #eaecee;
   cursor: pointer;
}

.vertical-center{
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

</style>

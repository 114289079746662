import moment from "moment";

class OrderUtil {
    getOnlinePaymentProvider(payments){
        if(typeof(payments) !== "undefined" && payments !== null && payments.length > 0){
            for (let i = 0; i < payments.length; i++) {
                let payment = payments[i];
                return payment.onlinePaymentProvider;
            }
        } else {
            return 'NOT_ANY';
        }
    }

    getOrderStatusText(orderStatus){
        if(orderStatus == 'ORDERED'){
            return 'Bestilt';
        } else if(orderStatus == 'CONFIRMED'){
            return 'Bekreftet';
        } else if(orderStatus == 'COMPLETED'){
            return 'Fullført';
        } else {
            return "NOT_SET";
        }
    }

    getPaymentStatus(payments){
        if(payments !== undefined){
            if(payments.length > 0){
                return 'PAID';
            } else {
                return 'NOT_PAID_YET';
            }
        }else{
            return 'NOT_PAID_YET';
        }
    }

    getPaymentStatusText(payments){
        if(typeof(payments) !== "undefined" && payments !== null && payments.length > 0){
            return 'Betalt';
        } else {
            return 'Ikke betalt';
        }
    }

    getPaymentTypeText(paymentType){
        if(paymentType == 'CASH'){
            return 'Kontant';
        } else if(paymentType == 'MOBILE_APP'){
            return 'Vipps';
        } else if(paymentType.includes('vipps')){
            return 'Vipps';
        } else if(paymentType.includes('creditcard')){
            return 'Kort';
        } else if(paymentType == 'payex.applepay'){
            return 'Apple Pay';
        } else if(paymentType == 'payex.googlepay'){
            return 'Google Pay';
        } else if(paymentType == 'DEBIT_CARD' || paymentType == 'CREDIT_CARD'){
            return 'Kort';
        }else {
            return "Kort";
        }
    }

    getOrderTypeText(orderType){
        if(orderType == 'DINE_IN'){
            return 'Spise her';
        } else if(orderType == 'PICKUP'){
            return 'Ta med';
        } else if(orderType == 'DELIVERY'){
            return 'Levere';
        } else {
            return "NOT_SET";
        }
    }

    getChannelText(channel){
        if(channel == 'APP_IOS'){
            return 'iPhone';
        } else if(channel == 'APP_ANDROID'){
            return 'Android';
        } else if(channel == 'WEBSHOP'){
            return 'Nettbutikk';
        } else if(channel == 'POS'){
            return 'POS';
        } else if(channel == 'KIOSK'){
            return 'Selvbetjeningskiosk';
        } else {
            return "NOT_SET";
        }
    }

    getDateTimeText(dateTime){
        var dateTimeMoment = moment(dateTime);
        return dateTimeMoment.format('DD.MM.YYYY HH:mm:ss');
    }

    getDateTimeTextShort(dateTime){
        var dateTimeMoment = moment(dateTime);
        return dateTimeMoment.format('DD.MM.YYYY HH:mm');
    }

    getPriceWithVat(vatRate, price){
        let priceWithVat = parseFloat(price) + ((parseFloat(price) / 100) * vatRate);
        return priceWithVat;
    }

    /** DELETED
    getRefundTotalPricesWithVatInCart(cartItems, orderType, vatDoc) {
        let totalPricesInCart = 0.00;
        for (let i = 0; i < cartItems.length; i++) {
            let cartItem = cartItems[i];
            let vatRate = VatUtil.getVatRate(vatDoc, orderType, cartItem.itemType);
            if(cartItem.discountPercent == 0){
                let cartItemPrice = Math.round(this.getPriceWithVat(vatRate, cartItem.price)) * cartItem.count; //make it exact as it is in order description.
                totalPricesInCart = totalPricesInCart + cartItemPrice;
            } else {
                let cartItemPrice = this.getPriceWithVat(vatRate, cartItem.price) * cartItem.count * (100 - cartItem.discountPercent) / 100;
                totalPricesInCart = totalPricesInCart + cartItemPrice;
            }
        }
        return totalPricesInCart;
    }
     */

}

export default new OrderUtil();

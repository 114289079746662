<template>
  <popup-modal ref="popup">
    <h3>{{ title }}</h3>
    <p class="mt-3 mb-4">{{ message }}</p>
    <div class="btn-panel">
      <button v-if="showCancelButton" class="bo_btn btn-width" @click="_cancel">{{ cancelButton }}</button>
      <button :class="showCancelButton ? 'bo_btn btn-width ms-5' : 'bo_btn btn-width'" @click="_confirm">{{ okButton }}</button>
    </div>
  </popup-modal>
</template>

<script>
  import PopupModal from './PopupModal.vue'

  export default {
    name: 'ConfirmDialogue',

    components: { PopupModal },

    data: () => ({
      // Parameters that change depending on the type of dialogue
      title: undefined,
      message: undefined, // Main text content
      okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
      cancelButton: 'NOT_SET', // text for cancel button

      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined,
    }),
    computed: {
      showCancelButton(){
          return this.cancelButton !== 'NOT_SET';
      }
    },
    methods: {
      show(opts = {}) {
        this.title = opts.title
        this.message = opts.message
        this.okButton = opts.okButton
        if (opts.cancelButton) {
          this.cancelButton = opts.cancelButton
        }
        // Once we set our config, we tell the popup modal to open
        this.$refs.popup.open()
        // Return promise so the caller can get results
        return new Promise((resolve, reject) => {
          this.resolvePromise = resolve
          this.rejectPromise = reject
        })
      },

      _confirm() {
        this.$refs.popup.close()
        this.resolvePromise(true)
      },

      _cancel() {
        this.$refs.popup.close()
        this.resolvePromise(false)
        // Or you can throw an error
        // this.rejectPromise(new Error('User cancelled the dialogue'))
      },
    },
  }
</script>

<style scoped>
    .btn-panel {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-top: #CCCCCC solid 1px;
      padding-top: 15px;
    }

    .btn-width {
      width: 80px;
      text-align: center;
    }
</style>

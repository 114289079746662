<template>
  <div id="scrollTopBtn" class="container">
    <a @click="scrollTop" v-show="visible" class="btn btn-light bottom-right">
        <font-awesome-icon :icon="['fas', 'angle-double-up']" size="3x" />
    </a>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false
    }
  },
  methods: {
    scrollTop: function () {
      window.scrollTo(0,0);
    },
    scrollListener: function () {
      this.visible = window.scrollY > 150
    }
  },
  mounted: function () {
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeUnmount: function () {
    window.removeEventListener('scroll', this.scrollListener)
  }
}
</script>

<style scoped>
.btn {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.bottom-right {
  position: fixed;
  bottom: 60px;
  right: 10px;
  cursor: pointer;
}
</style>


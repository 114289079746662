<template>
  <div class="container-fluid">
    <span class="bold">Total omsetning</span><br/>
    <table class="xz-table">
      <thead>
      <tr>
        <th class="bold"></th>
        <th class="bold">MVA%</th>
        <th class="bold">MVA</th>
        <th class="bold">Netto</th>
        <th class="bold">Brutto</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Mat</td>
        <td>{{ vat.takeOut }}%</td>
        <td>{{ displayTwoDecimals(totalFoodTakeawayVat) }}</td>
        <td>{{ displayTwoDecimals(totalFoodTakeawayNetto) }}</td>
        <td>{{ displayTwoDecimals(totalFoodTakeawayBrutto) }}</td>
      </tr>
      <tr>
        <td>Kalddrikker</td>
        <td>{{ vat.takeOut }}%</td>
        <td>{{ displayTwoDecimals(totalColdDrinksTakeawayVat) }}</td>
        <td>{{ displayTwoDecimals(totalColdDrinksTakeawayNetto) }}</td>
        <td>{{ displayTwoDecimals(totalColdDrinksTakeawayBrutto) }}</td>
      </tr>
      <tr>
        <td>Mat</td>
        <td>{{vat.dineIn}}%</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr>
        <td>Kalddrikker</td>
        <td>{{vat.dineIn}}%</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr>
        <td>Utkjøringer</td>
        <td>{{vat.dineIn}}%</td>
        <td>{{totalDeliveryServiceVat}}</td>
        <td>{{totalDeliveryServiceNetto}}</td>
        <td>{{totalDeliveryServiceBrutto}}</td>
      </tr>
      <tr>
        <td class="bold">Total</td>
        <td class="bold"></td>
        <td class="bold">{{ displayTwoDecimals(totalSalesVat) }}</td>
        <td class="bold">{{ displayTwoDecimals(totalSalesNetto) }}</td>
        <td class="bold">{{ displayTwoDecimals(totalSalesBrutto) }}</td>
      </tr>
      <tr v-if="totalTips > 0">
        <td class="bold">Tips</td>
        <td class="bold"></td>
        <td class="bold"></td>
        <td class="bold"></td>
        <td class="bold">{{ displayTwoDecimals(totalTips) }}</td>
      </tr>
      </tbody>
    </table>
    <hr class="horizontal-dashed"/>
  </div>
</template>

<script>
  import MathUtil from "@/util/MathUtil";

  export default {
    props: {
      zReport: Object,
      vat: Object
    },
    computed: {
      /*
       * itemType = DISH, DRINK, ADD_ON or DELIVERY_SERVICE
       */
      totalFoodTakeawayVat() {
        let foodTakeawayVat = (this.totalFoodTakeawayBrutto / (100 + this.vat.takeOut)) * this.vat.takeOut;
        return MathUtil.roundTwoDecimals(foodTakeawayVat);
      },
      totalFoodTakeawayBrutto() {
        var foodTakeawayBrutto = 0;
        for (let i = 0; i < this.zReport.categories.length; i++) {
          const category = this.zReport.categories[i];
          if((category.itemType == 'DISH' || category.itemType == 'ADD_ON') && category.vatRate == this.vat.takeOut){
            foodTakeawayBrutto = foodTakeawayBrutto + category.amountWithVat;
          }
        }
        return MathUtil.roundTwoDecimals(foodTakeawayBrutto);
      },
      totalFoodTakeawayNetto() {
        return (this.totalFoodTakeawayBrutto - this.totalFoodTakeawayVat)
      },

      totalColdDrinksTakeawayVat() {
        let coldDrinksTakeawayVat = (this.totalColdDrinksTakeawayBrutto / (100 + this.vat.takeOut)) * this.vat.takeOut;
        return MathUtil.roundTwoDecimals(coldDrinksTakeawayVat);
      },
      totalColdDrinksTakeawayBrutto() {
        var coldDrinksTakeawayBrutto = 0;
        for (let i = 0; i < this.zReport.categories.length; i++) {
          const category = this.zReport.categories[i];
          if((category.itemType == 'DRINK_BUBBLE_TEA' ||category.itemType == 'DRINK') && category.vatRate == this.vat.takeOut){
            coldDrinksTakeawayBrutto = coldDrinksTakeawayBrutto + category.amountWithVat;
          }
        }
        return MathUtil.roundTwoDecimals(coldDrinksTakeawayBrutto);
      },
      totalColdDrinksTakeawayNetto() {
        return (this.totalColdDrinksTakeawayBrutto - this.totalColdDrinksTakeawayVat);
      },

      totalDeliveryServiceVat() {
        let deliveryServiceVat = (this.totalDeliveryServiceBrutto / (100 + this.vat.dineIn)) * this.vat.dineIn;
        return MathUtil.roundTwoDecimals(deliveryServiceVat);
      },
      totalDeliveryServiceBrutto() {
        var deliveryServiceBrutto = 0;
        for (let i = 0; i < this.zReport.categories.length; i++) {
          const category = this.zReport.categories[i];
          if(category.itemType == 'DELIVERY_SERVICE' && category.vatRate == this.vat.dineIn){
            deliveryServiceBrutto = deliveryServiceBrutto + category.amountWithVat;
          }
        }
        return MathUtil.roundTwoDecimals(deliveryServiceBrutto);
      },
      totalDeliveryServiceNetto() {
        return (this.totalDeliveryServiceBrutto - this.totalDeliveryServiceVat);
      },

      totalSalesVat() {
        return (this.totalFoodTakeawayVat + this.totalColdDrinksTakeawayVat + this.totalDeliveryServiceVat);
      },
      totalSalesBrutto() {
        return (this.totalFoodTakeawayBrutto + this.totalColdDrinksTakeawayBrutto + this.totalDeliveryServiceBrutto);
      },
      totalSalesNetto() {
        return (this.totalSalesBrutto - this.totalSalesVat);
      },
      totalTips() {
        return (this.zReport.tips);
      }
    },
    methods: {
      displayTwoDecimals(num){
        if(num > 0 || num < 0){
          return MathUtil.displayTwoDecimals(num);
        }else {
          return num;
        }
      }
    }
  }
</script>

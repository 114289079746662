<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4">
    <h3>Åpningstider</h3>
    <div id="updatedMessage" v-if="openingHoursUpdated">
      <span class="message-info">Åpningstidene er oppdatert</span>
    </div>
    <br/>
    <span v-if="useFunctionality !== 'WEBSITE'">
      Online bestillinger i nettbutikk skal hentes eller leveres innenfor åpningstidene.
    </span>

    <div v-if="hasMultiLocations()" class="select-location-box mt-4">
      <span class="ingredient-title">For avdeling</span>
      <select class="form-select" v-model="locationId" @change="getOpeningHours()">
        <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
      </select>
    </div>

    <div class="opening-hours-flex-box">
      <div class="opening-hours">
        <div>
          <span class="item-title">Mandag</span>
          <select id="mondayOpenOrClose" class="openOrClose">
            <option value="open">Åpen</option>
            <option value="close">Stengt</option>
          </select>
        </div>
        Fra: <input type="text" id="monday_fromTime" name="monday_fromTime" class="time_element"/>
        Til: <input type="text" id="monday_toTime" name="monday_toTime" class="time_element"/>
      </div>

      <div class="opening-hours">
        <div>
          <span class="item-title">Tirsdag</span>
          <select id="tuesdayOpenOrClose" class="openOrClose">
            <option value="open">Åpen</option>
            <option value="close">Stengt</option>
          </select>
        </div>
        Fra: <input type="text" id="tuesday_fromTime" name="tuesday_fromTime" class="time_element"/>
        Til: <input type="text" id="tuesday_toTime" name="tuesday_toTime" class="time_element"/>
      </div>

      <div class="opening-hours">
        <div>
          <span class="item-title">Onsdag</span>
          <select id="wednesdayOpenOrClose" class="openOrClose">
            <option value="open">Åpen</option>
            <option value="close">Stengt</option>
          </select>
        </div>
        Fra: <input type="text" id="wednesday_fromTime" name="wednesday_fromTime" class="time_element"/>
        Til: <input type="text" id="wednesday_toTime" name="wednesday_toTime" class="time_element"/>
      </div>

      <div class="opening-hours">
        <div>
          <span class="item-title">Torsdag</span>
          <select id="thursdayOpenOrClose" class="openOrClose">
            <option value="open">Åpen</option>
            <option value="close">Stengt</option>
          </select>
        </div>
        Fra: <input type="text" id="thursday_fromTime" name="thursday_fromTime" class="time_element"/>
        Til: <input type="text" id="thursday_toTime" name="thursday_toTime" class="time_element"/>
      </div>

      <div class="opening-hours">
        <div>
          <span class="item-title">Fredag</span>
          <select id="fridayOpenOrClose" class="openOrClose">
            <option value="open">Åpen</option>
            <option value="close">Stengt</option>
          </select>
        </div>
        Fra: <input type="text" id="friday_fromTime" name="friday_fromTime" class="time_element"/>
        Til: <input type="text" id="friday_toTime" name="friday_toTime" class="time_element"/>
      </div>

      <div class="opening-hours">
        <div>
          <span class="item-title">Lørdag</span>
          <select id="saturdayOpenOrClose" class="openOrClose">
            <option value="open">Åpen</option>
            <option value="close">Stengt</option>
          </select>
        </div>
        Fra: <input type="text" id="saturday_fromTime" name="saturday_fromTime" class="time_element"/>
        Til: <input type="text" id="saturday_toTime" name="saturday_toTime" class="time_element"/>
      </div>

      <div class="opening-hours">
        <div>
          <span class="item-title">Søndag</span>
          <select id="sundayOpenOrClose" class="openOrClose">
            <option value="open">Åpen</option>
            <option value="close">Stengt</option>
          </select>
        </div>
        Fra: <input type="text" id="sunday_fromTime" name="sunday_fromTime" class="time_element"/>
        Til: <input type="text" id="sunday_toTime" name="sunday_toTime" class="time_element"/>
      </div>
    </div>

    <div class="bo_savebtn_container">
      <hr/>
      <button type="button" class="bo_btn" @click="save"> Lagre </button>
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery';
import $ from 'jquery';
window.jQuery = jQuery;

import SettingsService from "@/services/settings/SettingsService";
import LoadingSpinner from "@/components/other/LoadingSpinner";
import MerchantUtil from "@/util/MerchantUtil";
import LocationUtil from "@/util/LocationUtil";
import LocationService from "@/services/location/LocationService";

export default {
  components: {LoadingSpinner},
  data() {
    return {
      loading: true,

      locationId: '',
      locations: [],
      openingHoursDocument: {},
      openingHoursUpdated: false
    };
  },
  computed: {
    useFunctionality(){
      return MerchantUtil.useFunctionality();
    }
  },
  mounted() {
    const timepicki = document.createElement("script");
    timepicki.setAttribute(
        "src",
        require('@/assets/jquery/timepicki.js')
    );
    timepicki.async = true;
    document.head.appendChild(timepicki);

    $(".time_element").timepicki({
      show_meridian:false,
      min_hour_value:0,
      max_hour_value:23,
      step_size_hours:1,
      step_size_minutes:15,
      overflow_minutes:true,
      increase_direction:'up',
      disable_keyboard_mobile: true,
      start_time: ["11", "00"]
    });

    LocationService.listLocations().then(
        (response) => {
          this.locations = response.data;
          this.locationId = this.locations.length ? this.locations[0].id : '';

          this.getOpeningHours();
        },
        (error) => {
          console.log(error);
        }
    );
  },
  methods: {
    hasMultiLocations(){
      return LocationUtil.hasMultiLocations();
    },
    getOpeningHours(){
      this.loading = true;
      SettingsService.getOpeningHours(this.locationId).then(
          (response) => {
            if(response.data){
              this.openingHoursDocument = response.data;
            }
            this.initData();

            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    initData(){
      if(this.openingHoursDocument){
        this.setDayChangeListeners();

        var openingHours = this.openingHoursDocument.openingHours;
        var monday = this.getDayOpenHours('MONDAY', openingHours);
        if(monday != null){
          $('#mondayOpenOrClose').val('open');
          $('#monday_fromTime').val(monday.fromTime);
          $('#monday_toTime').val(monday.toTime);
        }else{
          $('#mondayOpenOrClose').val('close');
          $('#monday_fromTime').prop('disabled', true);
          $('#monday_toTime').prop('disabled', true);
        }

        var tuesday = this.getDayOpenHours('TUESDAY', openingHours);
        if(tuesday != null){
          $('#tuesdayOpenOrClose').val('open');
          $('#tuesday_fromTime').val(tuesday.fromTime);
          $('#tuesday_toTime').val(tuesday.toTime);
        }else{
          $('#tuesdayOpenOrClose').val('close');
          $('#tuesday_fromTime').prop('disabled', true);
          $('#tuesday_toTime').prop('disabled', true);
        }

        var wednesday = this.getDayOpenHours('WEDNESDAY', openingHours);
        if(wednesday != null){
          $('#wednesdayOpenOrClose').val('open');
          $('#wednesday_fromTime').val(wednesday.fromTime);
          $('#wednesday_toTime').val(wednesday.toTime);
        }else{
          $('#wednesdayOpenOrClose').val('close');
          $('#wednesday_fromTime').prop('disabled', true);
          $('#wednesday_toTime').prop('disabled', true);
        }

        var thursday = this.getDayOpenHours('THURSDAY', openingHours);
        if(thursday != null){
          $('#thursdayOpenOrClose').val('open');
          $('#thursday_fromTime').val(thursday.fromTime);
          $('#thursday_toTime').val(thursday.toTime);
        }else{
          $('#thursdayOpenOrClose').val('close');
          $('#thursday_fromTime').prop('disabled', true);
          $('#thursday_toTime').prop('disabled', true);
        }

        var friday = this.getDayOpenHours('FRIDAY', openingHours);
        if(friday != null){
          $('#fridayOpenOrClose').val('open');
          $('#friday_fromTime').val(friday.fromTime);
          $('#friday_toTime').val(friday.toTime);
        }else{
          $('#fridayOpenOrClose').val('close');
          $('#friday_fromTime').prop('disabled', true);
          $('#friday_toTime').prop('disabled', true);
        }

        var saturday = this.getDayOpenHours('SATURDAY', openingHours);
        if(saturday != null){
          $('#saturdayOpenOrClose').val('open');
          $('#saturday_fromTime').val(saturday.fromTime);
          $('#saturday_toTime').val(saturday.toTime);
        }else{
          $('#saturdayOpenOrClose').val('close');
          $('#saturday_fromTime').prop('disabled', true);
          $('#saturday_toTime').prop('disabled', true);
        }

        var sunday = this.getDayOpenHours('SUNDAY', openingHours);
        if(sunday != null){
          $('#sundayOpenOrClose').val('open');
          $('#sunday_fromTime').val(sunday.fromTime);
          $('#sunday_toTime').val(sunday.toTime);
        }else{
          $('#sundayOpenOrClose').val('close');
          $('#sunday_fromTime').prop('disabled', true);
          $('#sunday_toTime').prop('disabled', true);
        }
      }
    },
    setDayChangeListeners(){
      $("#mondayOpenOrClose" ).change(function() {
        if($('#mondayOpenOrClose').val() == 'open'){
          $('#monday_fromTime').prop('disabled', false);
          $('#monday_toTime').prop('disabled', false);
        }else{
          $('#monday_fromTime').prop('disabled', true);
          $('#monday_toTime').prop('disabled', true);
        }
      });

      $("#tuesdayOpenOrClose" ).change(function() {
        if($('#tuesdayOpenOrClose').val() == 'open'){
          $('#tuesday_fromTime').prop('disabled', false);
          $('#tuesday_toTime').prop('disabled', false);
        }else{
          $('#tuesday_fromTime').prop('disabled', true);
          $('#tuesday_toTime').prop('disabled', true);
        }
      });

      $("#wednesdayOpenOrClose" ).change(function() {
        if($('#wednesdayOpenOrClose').val() == 'open'){
          $('#wednesday_fromTime').prop('disabled', false);
          $('#wednesday_toTime').prop('disabled', false);
        }else{
          $('#wednesday_fromTime').prop('disabled', true);
          $('#wednesday_toTime').prop('disabled', true);
        }
      });

      $("#thursdayOpenOrClose" ).change(function() {
        if($('#thursdayOpenOrClose').val() == 'open'){
          $('#thursday_fromTime').prop('disabled', false);
          $('#thursday_toTime').prop('disabled', false);
        }else{
          $('#thursday_fromTime').prop('disabled', true);
          $('#thursday_toTime').prop('disabled', true);
        }
      });

      $("#fridayOpenOrClose" ).change(function() {
        if($('#fridayOpenOrClose').val() == 'open'){
          $('#friday_fromTime').prop('disabled', false);
          $('#friday_toTime').prop('disabled', false);
        }else{
          $('#friday_fromTime').prop('disabled', true);
          $('#friday_toTime').prop('disabled', true);
        }
      });

      $("#saturdayOpenOrClose" ).change(function() {
        if($('#saturdayOpenOrClose').val() == 'open'){
          $('#saturday_fromTime').prop('disabled', false);
          $('#saturday_toTime').prop('disabled', false);
        }else{
          $('#saturday_fromTime').prop('disabled', true);
          $('#saturday_toTime').prop('disabled', true);
        }
      });

      $("#sundayOpenOrClose" ).change(function() {
        if($('#sundayOpenOrClose').val() == 'open'){
          $('#sunday_fromTime').prop('disabled', false);
          $('#sunday_toTime').prop('disabled', false);
        }else{
          $('#sunday_fromTime').prop('disabled', true);
          $('#sunday_toTime').prop('disabled', true);
        }
      });
    },
    /**
     * The day is opoen if it exists the openingHours array.
     * day: MONDAY, TUESDAY, WEDNESDAY, etc..
     */
    getDayOpenHours(day, openingHours){
      if(openingHours){
        for (var i = 0; i < openingHours.length; i++) {
          if(day == openingHours[i].day){
            return openingHours[i];
          }
        }
      }
      return null;
    },
    getOpeningHoursInputs(){
      var openingHours = [];
      if($('#mondayOpenOrClose').val() == "open"){
        var mondayOpeningHours = {
          day: "MONDAY",
          weekday: 1,
          fromTime: $('#monday_fromTime').val(),
          toTime: $('#monday_toTime').val()
        }
        openingHours.push(mondayOpeningHours);
      }
      if($('#tuesdayOpenOrClose').val() == "open"){
        var tuesdayOpeningHours = {
          day: "TUESDAY",
          weekday: 2,
          fromTime: $('#tuesday_fromTime').val(),
          toTime: $('#tuesday_toTime').val()
        }
        openingHours.push(tuesdayOpeningHours);
      }
      if($('#wednesdayOpenOrClose').val() == "open"){
        var wednesdayOpeningHours = {
          day: "WEDNESDAY",
          weekday: 3,
          fromTime: $('#wednesday_fromTime').val(),
          toTime: $('#wednesday_toTime').val()
        }
        openingHours.push(wednesdayOpeningHours);
      }
      if($('#thursdayOpenOrClose').val() == "open"){
        var thursdayOpeningHours = {
          day: "THURSDAY",
          weekday: 4,
          fromTime: $('#thursday_fromTime').val(),
          toTime: $('#thursday_toTime').val()
        }
        openingHours.push(thursdayOpeningHours);
      }
      if($('#fridayOpenOrClose').val() == "open"){
        var fridayOpeningHours = {
          day: "FRIDAY",
          weekday: 5,
          fromTime: $('#friday_fromTime').val(),
          toTime: $('#friday_toTime').val()
        }
        openingHours.push(fridayOpeningHours);
      }
      if($('#saturdayOpenOrClose').val() == "open"){
        var saturdayOpeningHours = {
          day: "SATURDAY",
          weekday: 6,
          fromTime: $('#saturday_fromTime').val(),
          toTime: $('#saturday_toTime').val()
        }
        openingHours.push(saturdayOpeningHours);
      }
      if($('#sundayOpenOrClose').val() == "open"){
        var sundayOpeningHours = {
          day: "SUNDAY",
          weekday: 7,
          fromTime: $('#sunday_fromTime').val(),
          toTime: $('#sunday_toTime').val()
        }
        openingHours.push(sundayOpeningHours);
      }
      return openingHours;
    },
    save(){
      this.loading = true;

      var openingHours = this.getOpeningHoursInputs();
      if(JSON.stringify(this.openingHoursDocument) === '{}'){
        //Blank object, CREATE
        this.openingHoursDocument = {
          type: "openinghours",
          merchant: MerchantUtil.getMerchant(),
          locationId: this.locationId,
          openingHours: openingHours
        }
      }else{
        //UPDATE
        this.openingHoursDocument.openingHours = openingHours;
      }
      //console.log(JSON.stringify(this.openingHoursDocument));
      SettingsService.editOpeningHours(this.openingHoursDocument).then(
          (response) => {
            let documentId = response.data;
            if(documentId){
              this.openingHoursUpdated = true;
            } else {
              console.log("Editing openingHours failed");
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    }
  }
};
</script>

<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4">
    <h3>Bonusprogram</h3>
    <p class="mt-4" style="max-width:790px">Bonusprogram gir kunder bonuspoeng for hvert kjøp. Du kan sette opp hvor mange prosent av et ordrebeløp kunder får tilbake i bonuspoeng.
      Produkter med rabattert pris gir ikke bonus. Ett poeng tilsvarer 1 NOK.</p>
    <div class="bo_responsive-users-table-header">
          <span class="right-align">
              <button type="button" class="bo_btn" @click="goToCreatePage"> + Opprett program </button>
          </span>
    </div>

    <div class="table-responsive-sm mt-3">
        <table class="table table-striped">
        <thead>
          <tr>
              <th></th>
              <th>Programnavn</th>
              <th>Prosent(%) bonus</th>
              <th>Gyldig fra og med dato</th>
              <th>Til og med dato</th>
              <th>Gjelder for avdelinger</th>
              <th></th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="rewardProgram in rewardPrograms" :key="rewardProgram.id">
              <td>
                <a class="remove-user-btn"
                   @click="removeRewardProgram(rewardProgram)">
                  <img src="@/assets/images/remove.png" width="21" title="Slett"/>
                </a>
              </td>
              <td>
                {{ rewardProgram.name }}
              </td>
              <td>
                {{ rewardProgram.percent }}
              </td>
              <td>
                {{ getDateText(rewardProgram.fromDate) }}
              </td>
              <td>
                {{ getDateText(rewardProgram.toDate) }}
              </td>
              <td>
                {{ getLocationsText(rewardProgram.validForLocationIds) }}
              </td>
              <td>
                <a class="edit-location-btn"
                   @click="goToEditRewardPage(rewardProgram.id)">
                  <img src="@/assets/images/edit.png" width="21" title="Rediger"/>
                </a>
              </td>
            </tr>
        </tbody>
      </table>
    </div>
  </div>
  <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
</template>

<script>
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import LoyaltyService from "@/services/loyalty/LoyaltyService";
  import ConfirmDialogue from "@/components/other/ConfirmDialogue";
  import moment from "moment";
  import MerchantUtil from "@/util/MerchantUtil";
  import LocationUtil from "@/util/LocationUtil";

  export default {
    components: {LoadingSpinner, ConfirmDialogue},
    data() {
      return {
        loading: true,
        rewardPrograms: []
      };
    },
    computed: {
      useFunctionality(){
        return MerchantUtil.useFunctionality();
      }
    },
    mounted() {
      this.getRewardPrograms();
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      getLocationName(locationId){
        let location = LocationUtil.getLocation(locationId);
        return location.name;
      },
      goToCreatePage(){
        this.$router.push({ path: 'rewardCreate'});
      },
      goToEditRewardPage(rewardProgramId){
        this.$router.push({ path: 'rewardEdit', query: {
            rewardProgramId: rewardProgramId
          }
        });
      },
      getRewardPrograms(){
        LoyaltyService.getRewardPrograms().then(
            (response) => {
              console.log(JSON.stringify(response.data));
              this.rewardPrograms = response.data;

              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      getDateText(date){
        var dateMoment = moment(date);
        return dateMoment.format('DD.MM.YYYY');
      },
      getLocationsText(locationIds){
        let text = '';
        for(let i = 0; i < locationIds.length; i++){
          let locationId = locationIds[i];
          let locationName = LocationUtil.getLocation(locationId).name;
          text = text + ', ' + locationName;
        }
        text = text.slice(2, text.length); //remove the first ', '
        return text;
      },
      async removeRewardProgram(rewardProgram){
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Slett program ' + rewardProgram.name,
          message: 'Vil du slette bonusprogram: ' + rewardProgram.name + '?',
          okButton: 'Slett',
          cancelButton: 'Avbryt'
        })
        if(ok) {
          LoyaltyService.deleteRewardProgram(rewardProgram).then(
              (response) => {
                if(response.data === 'SUCCEEDED'){
                  this.$router.go(); //Reload current page
                }else {
                  console.log("Deleting rewardProgram failed");
                }
              },
              (error) => {
                console.log(error);
              }
          );
        } else {
          //Cancel, do nothing
        }
      }
    }
  };
</script>

<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4">
    <h3>Avdelinger</h3>

    <div v-if="isSuperAdmin()" class="bo_responsive-users-table-header mt-4">
          <span class="right-align">
              <button type="button" class="bo_btn" @click="goToAddLocationPage"> + Legg til utsalgssted </button>
          </span>
    </div>

    <div class="table-responsive-sm mt-3">
      <table class="table table-striped">
        <thead>
            <tr>
              <th v-if="isSuperAdmin()"></th>
              <th>Navn</th>
              <th>Organisasjonsnr</th>
              <th>Adresse</th>
              <th>Telefon</th>
              <th>Epost</th>
              <th v-if="isSuperAdmin()"></th>
            </tr>
          </thead>
        <tbody>
            <tr v-for="location in locations" :key="location.id">
              <td v-if="isSuperAdmin()">
                <a class="remove-location-btn"
                   @click="removeLocation(location)">
                   <img src="@/assets/images/remove.png" width="21" title="Slett"/>
                </a>
              </td>

              <td data-label="Navn">
                <span>{{ location.name }}</span>
              </td>
              <td data-label="Organisasjonsnummer">
                <span>{{ location.vatNumber }}</span>
              </td>
              <td data-label="Adresse">
                <span>{{ location.street }}, {{ location.zipCode }} {{ location.city }}</span>
              </td>
              <td data-label="Telefon">
                <span>{{ location.phone }}</span>
              </td>
              <td data-label="Epost">
                <span>{{ location.email }}</span>
              </td>

              <td v-if="isSuperAdmin()">
                <a class="edit-location-btn"
                   @click="editLocation(location.id)">
                  <img src="@/assets/images/edit.png" width="21" title="Rediger"/>
                </a>
              </td>
            </tr>
        </tbody>
      </table>
    </div>
    <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
  </div>
</template>

<script>
  import UserUtil from "@/util/UserUtil";
  import LocationService from "@/services/location/LocationService";
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import ConfirmDialogue from "@/components/other/ConfirmDialogue";

  export default {
    components: {LoadingSpinner, ConfirmDialogue},
    data() {
      return {
        loading: true,
        locations: []
      };
    },
    mounted() {
      LocationService.listLocations().then(
          (response) => {
            this.locations = response.data;
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      isSuperAdmin(){
        return UserUtil.isSuperAdmin();
      },
      goToAddLocationPage(){
        this.$router.push({ path: 'locationCreate'});
      },
      editLocation(locationId){
        this.$router.push({ path: 'locationEdit', query: {
            locationId: locationId
          }
        });
      },
      async removeLocation(location){
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Slett utsalgssted "' + location.name + '"',
          message: 'Vil du slette utsalgssted "' + location.name + '"?',
          okButton: 'Slett',
          cancelButton: 'Avbryt'
        })
        if(ok) {
          LocationService.deleteLocation(location).then(
              (response) => {
                if(response.data === 'SUCCEEDED'){
                  this.$router.go(); //Reload current page
                }else {
                  console.log("Deleting location failed");
                }
              },
              (error) => {
                console.log(error);
              }
          );
        } else {
          //Cancel, do nothing
        }
      }
    }
  };
</script>

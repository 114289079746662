import authHeader from "@/services/auth/AuthHeader";
import axios from "axios";

class MediaFileService {
    uploadImageFile(multipartFile){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/media/uploadImageFile';
        let basicAuth = authHeader();
        basicAuth['content-type'] = 'multipart/form-data';
        //console.log('headers: ' + JSON.stringify(basicAuth))
        let formData = new FormData();
        formData.append('merchant', user.merchant);
        formData.append('file', multipartFile);
        return axios.post(url, formData,{ headers: basicAuth });
    }

    removeImageFile(imageUrl){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/media/removeImageFile';
        let basicAuth = authHeader();
        let formData = new FormData();
        formData.append('merchant', user.merchant);
        formData.append('imageUrl', imageUrl);
        return axios.post(url, formData,{ headers: basicAuth });
    }
}

export default new MediaFileService();
<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>
  
  <div class="container mt-4">
    <h3>Ansatte</h3>
    <div class="bo_responsive-users-table-header mt-4">
          <span class="right-align">
              <button type="button" class="bo_btn" @click="goToAddUserPage"> + Legg til ansatt </button>
          </span>
    </div>

    <div class="table-responsive-sm mt-3">
        <table class="table table-striped">
          <thead>
          <tr>
            <th></th>
            <th>Navn</th>
            <th>Brukernavn</th>
            <th>Epost</th>
            <th>Telefon</th>
            <th>Rolle</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>
                <a class="remove-user-btn"
                   @click="removeUser(user)">
                  <img src="@/assets/images/remove.png" width="21" title="Slett"/>
                </a>
              </td>
              <td data-label="Navn">
                <span>{{ user.firstname }} {{ user.lastname }}</span>
              </td>
              <td data-label="Brukernavn">
                <span>{{ user.username }}</span>
              </td>
              <td data-label="Epost">
                <span>{{ user.email }}</span>
              </td>
              <td data-label="Telefon">
                <span>{{ user.phone }}</span>
              </td>
              <td data-label="Rolle">
                <span>{{ user.userRole }}</span>
              </td>
              <td>
                <a class="edit-user-btn"
                   @click="editUser(user.id)">
                  <img src="@/assets/images/edit.png" width="21" title="Rediger"/>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
    <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import ConfirmDialogue from '@/components/other/ConfirmDialogue.vue'
import UserService from "@/services/user/UserService";

export default {
  components: {LoadingSpinner, ConfirmDialogue},
  data() {
    return {
      loading: true,
      users: []
    };
  },
  mounted() {
    UserService.listUsers().then(
      (response) => {
          this.users = response.data;
          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
    );
  },
  methods: {
    goToAddUserPage (){
      this.$router.push({ path: 'userCreate'});
    },
    editUser(userId){
      this.$router.push({ path: 'userEdit', query: {
          userId: userId
        }
      });
    },
    async removeUser(user){
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Slett ansatt "' + user.firstname + ' ' + user.lastname + '"',
        message: 'Vil du slette ansatt "' + user.firstname + ' ' + user.lastname + '"?',
        okButton: 'Slett',
        cancelButton: 'Avbryt'
      })
      if(ok) {
        UserService.deleteUser(user).then(
            (response) => {
              if(response.data === 'SUCCEEDED'){
                this.$router.go(); //Reload current page
              }else {
                console.log("Deleting user failed");
              }
            },
            (error) => {
              console.log(error);
            }
        );
      } else {
        //Cancel, do nothing
      }
    }
  }

}
</script>

<template>
  <LoadingSpinner :loading='loading'> </LoadingSpinner>

  <div class="container mt-4 mb-4">
    <div style="max-width:560px">
        <h3>Firmainnstillinger</h3>
        <div v-if='companyUpdated'>
          <span class='message-info'>Firmainnstillingene er oppdatert</span>
        </div>

        <div class="mt-4">
          <span class='ingredient-title'>Firmanavn (som det står i Brønnøysundregistrene)</span>
          <input class='form-control' type='text' v-model='company.name' />
        </div>

        <div>
          <span class='ingredient-title'>Organisasjonsnummer</span>
          <input class='form-control' type='text' v-model='company.vatNumber' />
        </div>

        <div>
          <span class='ingredient-title'>Adresse</span>
          <input class='form-control' type='text' v-model='company.street' />
        </div>

        <div>
          <span class='ingredient-title'>Postnummer</span>
          <input class='form-control' type='number' min='0' v-model='company.zipCode' />
        </div>

        <div>
          <span class='ingredient-title'>Poststed</span>
          <input class='form-control' type='text' v-model='company.city' />
        </div>

        <div>
          <span class='ingredient-title'>Telefon</span>
          <input class='form-control' type='number' min='0' v-model='company.phone' />
        </div>

        <div>
          <span class='ingredient-title'>Epost</span>
          <input class='form-control' type='text' v-model='company.email' />
        </div>

        <div class='bo_hr mt-4'>
          <hr/>
        </div>

        <div class='mb-5'>
          <button v-if='activateSaveButton' type='button' class='bo_btn' @click='save'> Lagre </button>
          <button v-else type='button' class='bo_btn' @click='save' disabled='true'> Lagre </button>
        </div>
    </div>
  </div>
</template>

<script>
  import SettingsService from '@/services/settings/SettingsService';
  import LoadingSpinner from "@/components/other/LoadingSpinner";

  export default {
    components: {LoadingSpinner},
    data() {
      return {
        loading: true,
        companyUpdated: false,
        company: {
          type: "company",
          merchant: '',
          name: '',
          vatNumber: '',
          street: '',
          zipCode: '',
          city: '',
          phone: '',
          email: '',
          useFunctionality: ''
        }
      };
    },
    mounted() {
        this.getCompany();
    },
    computed: {
      activateSaveButton(){
        if(this.company.name.length > 0 && this.company.vatNumber.length > 0 && this.company.street.length > 0 &&
            this.company.zipCode > 0 && this.company.city.length > 0 && this.company.phone > 0 && this.company.email.length > 3){
          return true;
        } else {
          return false;
        }
      }
    },
    methods: {
      getCompany(){
        SettingsService.getCompany().then(
            (response) => {
              if(response.data !== null){
                this.company = response.data;
              }

              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      save(){
        this.loading = true;

        this.company.zipCode = this.company.zipCode.toString();
        this.company.phone = this.company.phone.toString();

        SettingsService.editCompany(this.company).then(
            (response) => {
              let documentId = response.data;
              this.company.id = documentId;
              if(documentId){
                this.companyUpdated = true;
              } else {
                console.log("Editing company failed");
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      }
    }
  };
</script>

<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4">
    <div style="max-width:597px">
        <h3>Rediger utsalgssted</h3>
        <br/>

        <div>
          <span class="ingredient-title">Navn</span>
          <input class="form-control" type="text" v-model="location.name" />
        </div>

        <div>
          <span class="ingredient-title">Forkortet Navn</span>
          <input class="form-control" type="text" v-model="location.shortName" />
        </div>

        <div>
          <span class="bo_label">Header bilde</span>
          <input type="file" class="form-control" accept="image/*" ref="headerFile" @change="selectHeaderFile" />
          <button type='button' class='bo_btn mt-2' @click='uploadHeaderFile' :disabled="!activateUploadHeaderFileButton"> Last opp </button>
        </div>

        <div>
          <span class="ingredient-title">Google Maps Iframe Url</span>
          <input class="form-control" type="text" v-model="location.googleMapsIframeUrl" />
        </div>

        <div>
          <span class="ingredient-title">Organisasjonsnummer</span>
          <input class="form-control" type="text" v-model="location.vatNumber" />
        </div>

        <div>
          <span class="ingredient-title">Gate</span>
          <input class="form-control" type="text" v-model="location.street" />
        </div>

        <div>
          <span class="ingredient-title">Postnummer</span>
          <input class="form-control" type="number" v-model="location.zipCode" />
        </div>

        <div>
          <span class="ingredient-title">Poststed</span>
          <input class="form-control" type="text" v-model="location.city" />
        </div>

        <div>
          <span class="ingredient-title">Telefon</span>
          <input class="form-control" type="number" v-model="location.phone" />
        </div>

        <div>
          <span class="ingredient-title">Epost</span>
          <input class="form-control" type="email" v-model="location.email" @input="validateEmail" />
        </div>
        <div v-if="!validEmail">
          <span class="validation-failed">(Ugyldig epost)</span>
        </div>

        <div class="bo_hr mt-4">
          <hr/>
        </div>

        <div class="mb-5">
          <button v-if="activateSaveButton" type="button" class="bo_btn" @click="saveLocation"> Lagre </button>
          <button v-else type="button" class="bo_btn" @click="saveLocation" disabled="true"> Lagre </button>
        </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import UserUtil from "@/util/UserUtil";
import LocationService from "@/services/location/LocationService";
import MediaFileService from "@/services/media/MediaFileService";

export default {
  components: {LoadingSpinner},
  data() {
    return {
      loading: false,

      locationId: '',
      validEmail: true,
      location: {
        type: "location",
        merchant: "",
        name: "",
        shortName: "",
        headerImageFile: "",
        googleMapsIframeUrl: "",
        vatNumber: "",
        street: "",
        zipCode: "",
        city: "",
        phone: "",
        email: "",
        status: "ACTIVE"
      }
    };
  },
  mounted() {
    this.locationId = this.$route.query.locationId;
    LocationService.getLocation(this.locationId).then(
        (response) => {
          this.location = response.data;
          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
    );
  },
  computed: {
    activateUploadHeaderFileButton(){
      if(this.location.headerImageFile !== ''){
        return true;
      } else {
        return false;
      }
    },
    activateSaveButton(){
      if(this.location.name.length > 0 && this.location.shortName.length > 0 && this.location.vatNumber.length > 0 && this.location.street.length > 0 && this.location.zipCode > 0
          && this.location.city.length > 0 && this.location.phone > 0 && this.validEmail){

        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    isSuperAdmin(){
      return UserUtil.isSuperAdmin();
    },
    validateEmail(){
        var re = /\S+@\S+\.\S+/;
        let validatedResult = re.test(this.location.email);
        this.validEmail = validatedResult;
    },
    selectHeaderFile(){
      this.location.headerImageFile = this.$refs.headerFile.files[0];
      console.log("headerImageFile: " + this.location.headerImageFile.name)
    },
    uploadHeaderFile(){
      this.loading = true;
      MediaFileService.uploadImageFile(this.location.headerImageFile).then(
          (response) => {
            let imageUrl = response.data;
            if(imageUrl){
              console.log("headerImageFile: " + imageUrl);
              this.location.headerImageFile = imageUrl;
            } else {
              console.log("Uploading headerImageFile failed");
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    saveLocation(){
      this.loading = true;

      this.location.locationId = this.locationId;
      this.location.zipCode = this.location.zipCode.toString();
      this.location.phone = this.location.phone.toString();
      this.location.updatedDateTime = (new Date()).toISOString();
      this.editLocation(this.location);
    },
    editLocation(location){
      LocationService.editLocation(location).then(
          (response) => {
            let documentId = response.data;
            if(documentId){
              this.$router.push({ path: 'locations'});
            } else {
              console.log("Editing location failed");
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    }
  }
};
</script>

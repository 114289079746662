class UserUtil {
    isManager() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user.userRole) {
            return user.userRole === 'MANAGER';
        }
        return false;
    }
    isMerchantAdmin() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user.userRole) {
            return user.userRole === 'ADMIN';
        }
        return false;
    }
    isSuperAdmin() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user.userRole) {
            return user.userRole === 'SUPER_ADMIN';
        }
        return false;
    }
}

export default new UserUtil();
<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4 mb-4">
    <div style="max-width:560px">
        <h3>Legg til ansatt</h3>
        <br/>
        <div>
          <span class="ingredient-title">Fornavn</span>
          <input id="firstname" class="form-control" type="text" v-model="user.firstname"/>
        </div>

        <div>
          <span class="ingredient-title">Etternavn</span>
          <input id="lastname" class="form-control" type="text" v-model="user.lastname"/>
        </div>

        <div>
          <span class="ingredient-title">Rolle</span>
          <select class="form-select" v-model="user.userRole">
            <option value="">Velg rolle</option>
            <option value="MANAGER">Manager</option>
            <!-- Only super admin can create merchant admin user -->
            <option v-if="isSuperAdmin()" value="ADMIN">Admin</option>
          </select>
        </div>

        <div>
          <span class="ingredient-title">Brukernavn</span>
          <input id="username" class="form-control" type="text" v-model="user.username"/>
        </div>

        <div>
          <span class="ingredient-title">Passord</span>
          <input id="userPassword" class="form-control" type="password" v-model="user.userPassword"/>
        </div>

        <div>
          <span class="ingredient-title">Gjenta passord</span>
          <input id="confirmPassword" class="form-control" type="password" v-model="confirmPassword"/>
        </div>
        <div v-if="user.userPassword !== confirmPassword">
          <span class="validation-failed">(Passordene er ikke like)</span>
        </div>

        <div>
          <span class="ingredient-title">Pinkode(4 sifre)</span>
          <input id="pincode" class="form-control" type="number" maxlength="4" v-model="user.pincode" @input="checkUserPincode" />
        </div>
        <div v-if="!validPin" class="form-group">
          <span class="validation-failed">(Pin-koden er allerede i bruk)</span>
        </div>

        <div>
          <span class="ingredient-title">Epost</span>
          <input id="email" class="form-control" type="text" v-model="user.email" @input="validateEmail" />
        </div>
        <div v-if="!validEmail">
          <span class="validation-failed">(Ugyldig epost)</span>
        </div>

        <div>
          <span class="ingredient-title">Telefon</span>
          <input id="phone" class="form-control" type="number" v-model="user.phone"/>
        </div>

        <div class="bo_hr mt-4">
          <hr/>
        </div>

        <div class="mb-5">
          <button v-if="activateSaveButton" type="button" class="bo_btn" @click="saveUser"> Lagre </button>
          <button v-else type="button" class="bo_btn" @click="saveUser" disabled="true"> Lagre </button>
        </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import MerchantUtil from "@/util/MerchantUtil";
import UserUtil from "@/util/UserUtil";
import UserService from "@/services/user/UserService";

export default {
  components: {LoadingSpinner},
  data() {
    return {
      loading: false,

      confirmPassword: "",
      validPin: true,
      validEmail: true,

      user: {
        type: "user",
        merchant: "",
        firstname: "",
        lastname: "",
        userRole: "",
        username: "",
        userPassword: "",
        pincode: "",
        email: "",
        phone: "",
        status: "ACTIVE"
      }
    };
  },
  mounted() {

  },
  computed: {
    activateSaveButton(){
      //console.log('user: ' + JSON.stringify(this.user));
      if(this.user.firstname.length > 0 && this.user.lastname.length > 0 && this.user.userRole.length > 0 && this.user.username.length > 0
          && this.user.userPassword.length > 0 && this.user.pincode.toString().length == 4 && this.user.email.length > 0 && this.user.phone.toString().length > 0
          && this.user.userPassword == this.confirmPassword && this.validEmail && this.validPin
      ){
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    isSuperAdmin(){
      return UserUtil.isSuperAdmin();
    },
    checkUserPincode(event){
      this.validPin = true; //reset it

      if (event.target.value.length > 4) {
        //Allow max 4 numbers
        event.target.value = event.target.value.slice(0,4);
        this.user.pincode = event.target.value;
      }

      console.log('pincode: ' + this.user.pincode);
      if(event.target.value.length == 4){
        //console.log('Check Pin');
        UserService.checkUserPincode(this.user.pincode).then(
            (response) => {
              let result = response.data;
              if(result === 'TRUE'){
                //result := true -> pincode exists
                //console.log(result + ": exists -> Ugyldig");
                this.validPin = false;
              } else {
                //result := false -> pincode doesn't exist
                //console.log(result + ": not exists -> Gyldig");
                this.validPin = true;
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      }

    },
    validateEmail(){
        var re = /\S+@\S+\.\S+/;
        let validatedResult = re.test(this.user.email);
        console.log('validatedResult: ' + validatedResult);
        this.validEmail = validatedResult;
    },
    saveUser(){
      this.loading = true;

      this.user.merchant = MerchantUtil.getMerchant();
      this.createUser(this.user);
    },
    createUser(user){
      //console.log('user: ' + JSON.stringify(user));
      user.phone = user.phone.toString();
      user.pincode = user.pincode.toString();

      UserService.createUser(user).then(
          (response) => {
            let documentId = response.data;
            if(documentId){
              this.$router.push({ path: 'users'});
            } else {
              console.log("Creating user failed");
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    }
  }
};
</script>

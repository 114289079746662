/* eslint-disable */

import MathUtil from "@/util/MathUtil";

class WebshopXReportUtil {
    getCashTotalCount(xReportCounters){
        return 0;
    }

    getCashTotalVat(xReportCounters){
        return 0;
    }

    getCashTotalBrutto(xReportCounters){
        return 0;
    }

    getVippsTotalCount(xReportCounters){
        var vippsPickupCount = xReportCounters.vippsPickupCount;
        var vippsDeliveryCount = xReportCounters.vippsDeliveryCount;
        return (vippsPickupCount + vippsDeliveryCount);
    }

    getVippsTotalVat(xReportCounters){
        var vippsPickupVat = xReportCounters.vippsPickupVat;
        var vippsDeliveryVat = xReportCounters.vippsDeliveryVat;
        var vippsDeliveryServiceVat =  xReportCounters.vippsDeliveryServiceVat;
        return MathUtil.roundTwoDecimals(vippsPickupVat + vippsDeliveryVat + vippsDeliveryServiceVat);
    }

    getVippsTotalBrutto(xReportCounters){
        var vippsPickupBrutto = xReportCounters.vippsPickupBrutto;
        var vippsDeliveryBrutto = xReportCounters.vippsDeliveryBrutto;
        var vippsDeliveryServiceBrutto = xReportCounters.vippsDeliveryServiceBrutto;
        return Math.round(vippsPickupBrutto + vippsDeliveryBrutto + vippsDeliveryServiceBrutto);
    }

    getCardTotalCount(xReportCounters){
        var cardPickupCount = xReportCounters.cardPickupCount;
        var cardDeliveryCount = xReportCounters.cardDeliveryCount;
        return (cardPickupCount + cardDeliveryCount);
    }

    getCardTotalVat(xReportCounters){
        var cardDineInVat = xReportCounters.cardDineInVat;
        var cardPickupVat = xReportCounters.cardPickupVat;
        var cardDeliveryVat = xReportCounters.cardDeliveryVat;
        var cardDeliveryServiceVat =  xReportCounters.cardDeliveryServiceVat;
        return Math.round(cardDineInVat + cardPickupVat + cardDeliveryVat + cardDeliveryServiceVat);
    }

    getCardTotalBrutto(xReportCounters){
        var cardPickupBrutto = xReportCounters.cardPickupBrutto;
        var cardDeliveryBrutto = xReportCounters.cardDeliveryBrutto;
        var cardDeliveryServiceBrutto = xReportCounters.cardDeliveryServiceBrutto;
        return Math.round(cardPickupBrutto + cardDeliveryBrutto + cardDeliveryServiceBrutto);
    }

    getTotalTakeawayVat(xReportCounters){
        var vippsPickupVat = xReportCounters.vippsPickupVat;
        var vippsDeliveryVat = xReportCounters.vippsDeliveryVat;
        var cardPickupVat = xReportCounters.cardPickupVat;
        var cardDeliveryVat = xReportCounters.cardDeliveryVat;
        return Math.round( vippsPickupVat + vippsDeliveryVat + cardPickupVat + cardDeliveryVat);
    }

    getTotalTakeawayBrutto(xReportCounters){
        var vippsPickupBrutto = xReportCounters.vippsPickupBrutto;
        var vippsDeliveryBrutto = xReportCounters.vippsDeliveryBrutto;
        var cardPickupBrutto = xReportCounters.cardPickupBrutto;
        var cardDeliveryBrutto = xReportCounters.cardDeliveryBrutto
        return Math.round( vippsPickupBrutto + vippsDeliveryBrutto + cardPickupBrutto + cardDeliveryBrutto);
    }

    getTotalTakeawayNetto(xReportCounters){
        return (this.getTotalTakeawayBrutto(xReportCounters) - this.getTotalTakeawayVat(xReportCounters));
    }

    getTotalServiceVat(xReportCounters){
        var vippsDeliveryServiceVat = xReportCounters.vippsDeliveryServiceVat;
        var cardDeliveryServiceVat = xReportCounters.cardDeliveryServiceVat;
        return Math.round(  vippsDeliveryServiceVat + cardDeliveryServiceVat);
    }

    getTotalServiceBrutto(xReportCounters){
        var vippsDeliveryServiceBrutto = xReportCounters.vippsDeliveryServiceBrutto;
        var cardDeliveryServiceBrutto = xReportCounters.cardDeliveryServiceBrutto;
        return Math.round(  vippsDeliveryServiceBrutto + cardDeliveryServiceBrutto);
    }

    getTotalServiceNetto(xReportCounters){
        return (this.getTotalServiceBrutto(xReportCounters) - this.getTotalServiceVat(xReportCounters));
    }

    getTotalSalesVat(xReportCounters){
        return (this.getTotalTakeawayVat(xReportCounters) + this.getTotalServiceVat(xReportCounters));
    }

    getTotalSalesNetto(xReportCounters){
        return (this.getTotalTakeawayNetto(xReportCounters) + this.getTotalServiceNetto(xReportCounters));
    }

    getTotalSalesBrutto(xReportCounters){
        return (this.getTotalTakeawayBrutto(xReportCounters) + this.getTotalServiceBrutto(xReportCounters));
    }
}

export default new WebshopXReportUtil();
<template>
  <div class="controlContainer">
    <div class="mb-3">
      <span v-if="hasMultiLocations()" class="quick-button-title">Hurtigknapper for</span>
      <span v-else class="quick-button-title">Hurtigknapper</span>
      <span v-if="hasMultiLocations()" class="ms-2">
        <select v-model="locationId">
          <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
        </select>
      </span>
    </div>

    <div class="d-flex flex-wrap">
      <div>
          <div>
            <div v-if="isTakeawayClosedToday()" class="messageAfterActivated"> Takeaway stengt </div>
            <div v-else class="messageAfterActivated"> &nbsp; </div> <!-- just make GUI look good -->
          </div>
          <div>
            <button v-if="!isTakeawayClosedToday()" type="button" class="bo_btn quick-button" @click="goToCloseTakeawayPage">Steng takeaway</button>
            <button v-else type="button" class="bo_btn quick-button" @click="goToOpenTakeawayPage">Åpne takeaway</button>
          </div>
      </div>
      <div>
          <div>
            <div class="earliestPickupTime"> Tidligst hentetid: {{ earliestPickupTime }} min. </div>
          </div>
          <div>
            <button type="button" class="bo_btn quick-button" @click="goToChangeEarliestPickupTimePage">Endre tidligst hentetid</button>
          </div>
      </div>
      <div v-if="customActions">
        <div v-for="(action, index) in customActions" :key="index">
          <div v-if="showMessageAfterActivated(action)" class="messageAfterActivated"> {{ action.closedMessage }} </div>
          <div v-else class="messageAfterActivated"> &nbsp; </div> <!-- just make GUI look good -->
          <button v-if="!showMessageAfterActivated(action)" type="button" class="bo_btn quick-button" @click="goToCloseDishesPage">{{ action.closeName }}</button>
          <button v-else type="button" class="bo_btn quick-button" @click="goToOpenDishesPage">{{ action.openName }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MerchantUtil from "@/util/MerchantUtil";
import moment from "moment";
import LocationUtil from "@/util/LocationUtil";

export default {
  props: {
    locations: Array,
    closingDaysArray: Array,
    categories: Array
  },
  data() {
    return {
      locationId: ''
    };
  },
  computed: {
    merchant(){
        return MerchantUtil.getMerchant();
    },
    earliestPickupTime(){
      for (let i = 0; i < this.locations.length; i++) {
        let location = this.locations[i];
        if(location.id == this.locationId){
          return location.earliestPickupTime;
        }
      }
      return 0;
    },
    customActions(){
      for (let i = 0; i < this.locations.length; i++) {
        let location = this.locations[i];
        if(location.id == this.locationId){
          return location.customActions;
        }
      }
      return undefined;
    }
  },
  mounted() {
    this.locationId = this.locations.length ? this.locations[0].id : '';
  },
  methods: {
    hasMultiLocations(){
      return LocationUtil.hasMultiLocations();
    },

    isTakeawayClosedToday(){
      //console.log('closingDaysArray: ' + JSON.stringify(this.closingDaysArray));
      let today = moment(new Date());
      for (let i = 0; i < this.closingDaysArray.length; i++) {
        let closingDays = this.closingDaysArray[i];
        //console.log('closingDays.locationId: ' + closingDays.locationId);
        //console.log('this.locationId: ' + this.locationId);
        if(closingDays.locationId == this.locationId){
          let fromDate = moment(closingDays.fromDate)
          let toDate = moment(closingDays.toDate)
          //Docs: http://momentjs.com/docs/#/query/is-between/
          if(moment(today.format('YYYY-MM-DD')).isBetween(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), null, '[]')){
            console.log('return true');
            return true;
          }
        }
      }
      console.log('return false');
      return false;
    },
    showMessageAfterActivated(action){
      let activated = false;
      if(action.actionType === 'CLOSE_OPEN_DISHES'){
        //Check all categories
        for (let i = 0; i < action.categoryIds.length; i++) {
          let categoryId = action.categoryIds[i];
          let category = this.getCategory(categoryId);
          if(category.availableOnDays.length == 0){
            activated = true;
          } else{
            activated = false;
          }
        }
      }
      return activated;
    },
    getCategory(categoryId){
      for (let i = 0; i < this.categories.length; i++) {
        let category = this.categories[i];
        if(category.id == categoryId){
          return category;
        }
      }
      return null;
    },
    goToCloseTakeawayPage(){
      this.$router.push({ path: 'closeTakeaway', query: {locationId: this.locationId} });
    },
    goToOpenTakeawayPage(){
      this.$router.push({ path: 'openTakeaway', query: {locationId: this.locationId} });
    },
    goToChangeEarliestPickupTimePage() {
      this.$router.push({ path: 'changeEarliestPickupTime', query: {locationId: this.locationId} });
    },
    goToCloseDishesPage(){
      this.$router.push({ path: 'closeDishes', query: {locationId: this.locationId} });
    },
    goToOpenDishesPage(){
      this.$router.push({ path: 'openDishes', query: {locationId: this.locationId} });
    }
  }
}
</script>

<style scoped>
.controlContainer{
  background-color: #E8E8E8;
  padding-top: 10px;
  padding-bottom: 20px;
}

.quick-button-title{
  font-weight: bold;
  font-size: 17px;
  margin-left: 10px;
  margin-bottom: 15px;
}

.quick-button{
  margin-left: 10px;
}

.messageAfterActivated{
  margin-top: 10px;
  margin-left: 10px;
  color: #dd143b;
}

.earliestPickupTime{
  margin-top: 10px;
  margin-left: 10px;
}
</style>

<template>
  <div class="container-fluid">
    <table class="xz-table">
      <thead>
      <tr>
        <th class="bold">Refusjon</th>
        <th class="bold">Antall</th>
        <th class="bold"></th>
        <th class="bold">Beløp</th>
      </tr>
      </thead>
      <tbody>

      <tr>
        <td>Tilbakebetalt</td>
        <td>{{totalRefundReceiptsCount}}</td>
        <td></td>
        <td>{{ displayTwoDecimals(totalRefundReceiptsTotalAmount) }}</td>
      </tr>

      </tbody>
    </table>
    <hr class="horizontal-dashed"/>
  </div>
</template>

<script>
import MathUtil from "@/util/MathUtil";

export default {
  props: {
    zReports: Array,
    vat: Object
  },
  computed: {
    totalRefundReceiptsCount(){
      var refundReceiptsCount = 0;
      for (var i = 0; i < this.zReports.length; i++) {
        var zReport = this.zReports[i].cagistahospitality;
        refundReceiptsCount = refundReceiptsCount + zReport.refundReceiptsCount;
      }
      return refundReceiptsCount;
    },
    totalRefundReceiptsTotalAmount(){
      var refundReceiptsTotalAmount = 0;
      for (var i = 0; i < this.zReports.length; i++) {
        var zReport = this.zReports[i].cagistahospitality;
        refundReceiptsTotalAmount = refundReceiptsTotalAmount + zReport.refundReceiptsTotalAmount;
      }
      return refundReceiptsTotalAmount;
    }
  },
  methods: {
    displayTwoDecimals(num){
      if(num > 0 || num < 0){
        return MathUtil.displayTwoDecimals(num);
      }else {
        return num;
      }
    }
  }
}
</script>

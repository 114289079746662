<template>
  <div class="container-fluid text-center">
    <h6>Daglig salgsstatistikk for de siste 30 dagene</h6>
    <vue3-chart-js v-bind="{ ...barChart }" />
  </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import moment from 'moment'

export default {
  props: {
    totalSalesPerDateLabels: Array,
    totalSalesPerDate: Array
  },
  components: {
    Vue3ChartJs,
  },
  setup(props) {
    const barChart = {
      type: "bar",
      data: {
        labels: props.totalSalesPerDateLabels,
        datasets: [
          {
            label: "Total kr",
            backgroundColor: "#B30E10",
            data: props.totalSalesPerDate
          }
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              title: function(context) {
                var chartDate = moment(context[0].label);
                return chartDate.format('DD.MM.YYYY');
              }
            }
          }
        },
        scales: {
          x: {
            ticks: {
              callback: function(value) {
                var chartDate = moment(this.getLabelForValue(value));
                return chartDate.format('DD.MM.YYYY');
              }
            }
          },
          y: {
            ticks: {
              beginAtZero: true
            }
          },
        },
      }
    };

    return {
      barChart,
    };
  },
};
</script>

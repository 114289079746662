<template>
  <div class="container-fluid">
    <table class="xz-table">
      <thead>
      <tr>
        <th class="bold">Betalingsmiddel</th>
        <th class="bold">Antall</th>
        <th class="bold"></th>
        <th class="bold">Beløp</th>
      </tr>
      </thead>
      <tbody>

      <tr>
        <td colSpan="4"><b>Kontant</b></td>
      </tr>
      <tr>
        <td>Ta med</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>
      <tr>
        <td>Levere</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>
      <tr>
        <td>Total kontant</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>

      <tr>
        <td colSpan="4"><b>Vipps</b></td>
      </tr>
      <tr>
        <td>Ta med </td>
        <td>{{zReport.vippsPickupCount}}</td>
        <td></td>
        <td>{{displayTwoDecimals(zReport.vippsPickupPaymentsTotalAmount)}}</td>
      </tr>
      <tr>
        <td>Levere</td>
        <td>{{zReport.vippsDeliveryCount}}</td>
        <td></td>
        <td>{{displayTwoDecimals(zReport.vippsDeliveryPaymentsTotalAmount)}}</td>
      </tr>

      <tr>
        <td>Total Vipps</td>
        <td>{{vippsTotalCount}}</td>
        <td></td>
        <td>{{displayTwoDecimals(vippsPaymentsTotalAmount)}}</td>
      </tr>

      <tr>
        <td colSpan="4"><b>Kort</b></td>
      </tr>
      <tr>
        <td>Ta med</td>
        <td>{{zReport.cardPickupCount}}</td>
        <td></td>
        <td>{{displayTwoDecimals(zReport.cardPickupPaymentsTotalAmount)}}</td>
      </tr>
      <tr>
        <td>Levere</td>
        <td>{{zReport.cardDeliveryCount}}</td>
        <td></td>
        <td>{{displayTwoDecimals(zReport.cardDeliveryPaymentsTotalAmount)}}</td>
      </tr>
      <tr>
        <td>Total kort</td>
        <td>{{cardTotalCount}}</td>
        <td></td>
        <td>{{displayTwoDecimals(cardPaymentsTotalAmount)}}</td>
      </tr>

      <tr>
        <td class="bold">Total salg</td>
        <td class="bold">{{totalPaymentsCount}}</td>
        <td></td>
        <td class="bold">{{displayTwoDecimals(totalPayments)}}</td>
      </tr>
      </tbody>
    </table>
    <hr class="horizontal-dashed"/>
  </div>
</template>

<script>
import WebshopZReportUtil from "@/util/WebshopZReportUtil";
import MathUtil from "@/util/MathUtil";

export default {
  props: {
    zReport: Object,
    vat: Object
  },
  computed: {
    vippsTotalCount() {
      return WebshopZReportUtil.getVippsTotalCount(this.zReport);
    },
    vippsPaymentsTotalAmount() {
      return this.zReport.vippsPickupPaymentsTotalAmount + this.zReport.vippsDeliveryPaymentsTotalAmount;
    },

    cardTotalCount() {
      return WebshopZReportUtil.getCardTotalCount(this.zReport);
    },

    cardPaymentsTotalAmount() {
      return this.zReport.cardPickupPaymentsTotalAmount + this.zReport.cardDeliveryPaymentsTotalAmount;
    },

    totalPaymentsCount() {
      return (WebshopZReportUtil.getCashTotalCount(this.zReport) + WebshopZReportUtil.getVippsTotalCount(this.zReport) + WebshopZReportUtil.getCardTotalCount(this.zReport));
    },
    totalPayments() {
      return (this.zReport.vippsPickupPaymentsTotalAmount + this.zReport.vippsDeliveryPaymentsTotalAmount
          + this.zReport.cardPickupPaymentsTotalAmount + this.zReport.cardDeliveryPaymentsTotalAmount);
    }
  },
  methods: {
    displayTwoDecimals(num){
      if(num > 0 || num < 0){
        return MathUtil.displayTwoDecimals(num);
      }else {
        return num;
      }
    }
  }
}
</script>

import authHeader from "@/services/auth/AuthHeader";
import axios from "axios";

class SupportService {
    getCloudPrntDocument(locationId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/superadmin/support/getCloudPrntDocument' + '?merchant=' + user.merchant + '&locationId=' + locationId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    emptyJobs(locationId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/superadmin/support/emptyJobs' + '?merchant=' + user.merchant + '&locationId=' + locationId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

}

export default new SupportService();

import axios from "axios";

class Recaptcha {
    checkRecaptchaToken(token, action){
        let url = '/api/public/checkRecaptchaToken';
        return axios.post(url, {
            recaptchaToken: token,
            recaptchaAction: action
        });
    }
}

export default new Recaptcha();
<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4">
    <h3>{{ dishesAction.openName }}</h3>
    <p class="mt-4">{{ dishesAction.openConfirmMessage }}</p>

    <div v-if="hasMultiLocations()" class="select-location-box mt-4 mb-5">
      <span class="ingredient-title">Velg avdeling</span>
      <select class="form-select" v-model="locationId">
        <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
      </select>
    </div>

    <button type="button" class="bo_btn mt-4" @click="save"> {{ dishesAction.openName }}  </button>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import SettingsService from "@/services/settings/SettingsService";
import LocationUtil from "@/util/LocationUtil";
import LocationService from "@/services/location/LocationService";
import MerchantUtil from "@/util/MerchantUtil";

export default {
    components: {LoadingSpinner},
    data() {
      return {
        loading: false,
        locationId: '',
        locations: [],
        dishesAction: {}
      };
    },
    mounted() {
      this.locationId = this.$route.query.locationId;

      LocationService.listLocations().then(
          (response) => {
            this.locations = response.data;

            for (let i = 0; i < this.locations.length; i++) {
              let location = this.locations[i];
              if(location.id == this.locationId){
                let customActions = location.customActions;
                for (let a = 0; a < customActions.length; a++) {
                  let action = customActions[a];
                  if(action.actionType === 'CLOSE_OPEN_DISHES'){
                    this.dishesAction = action;
                    break;
                  }
                }
                break;
              }
            }
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      save(){
        this.loading = true;

        SettingsService.openDishes(MerchantUtil.getMerchant(), this.locationId).then(
            (response) => {
              let status = response.data;
              if(status == 'SUCCEEDED'){
                this.$router.push({ path: 'overview' });
              } else {
                console.log("openDishes failed");
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      }
    }
  };
</script>

<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4">
    <div style="max-width:597px">
        <h3>Åpne takeaway igjen</h3>
        <p class="mt-4">Etter at takeaway er åpen igjen, vil det være mulig for brukere å bestille takeaway online.</p>

        <div v-if="hasMultiLocations()" class="select-location-box mt-4 mb-5">
          <span class="ingredient-title">Velg avdeling</span>
          <select class="form-select" v-model="locationId">
            <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
          </select>
        </div>

        <div class="d-none">
          <SelectDate ref="selectDateFrom" label="Fra dato"/>
          <SelectDate ref="selectDateTo" label="Til dato"/>
        </div>

        <button type="button" class="bo_btn" @click="save"> Åpne takeaway </button>
      </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import SelectDate from "@/components/other/SelectDate";
import MerchantUtil from "@/util/MerchantUtil";
import SettingsService from "@/services/settings/SettingsService";
import moment from "moment";
import LocationUtil from "@/util/LocationUtil";
import LocationService from "@/services/location/LocationService";

export default {
    components: {LoadingSpinner, SelectDate},
    data() {
      return {
        loading: false,
        locationId: '',
        locations: [],
        message: 'Online takeaway er midlertidig stengt for i dag.'
      };
    },
    computed: {
      activateSaveButton(){
        if(this.message.length > 0){
          return true;
        } else {
          return false;
        }
      }
    },
    mounted() {
      this.locationId = this.$route.query.locationId;

      LocationService.listLocations().then(
          (response) => {
            this.locations = response.data;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      save(){
        this.loading = true;

        let merchant = MerchantUtil.getMerchant();
        let locationId = this.locationId;
        let selectedFromDate =  this.$refs.selectDateFrom.getSelectedDate();
        let selectedToDate =  this.$refs.selectDateTo.getSelectedDate();
        let fromDate = (moment(selectedFromDate)).format('YYYY-MM-DD');
        let toDate = (moment(selectedToDate)).format('YYYY-MM-DD');

        this.openTakeaway(merchant, locationId, fromDate, toDate);
      },
      openTakeaway(merchant, locationId, fromDate, toDate){
        SettingsService.openTakeaway(merchant, locationId, fromDate, toDate).then(
            (response) => {
              let status = response.data;
              if(status == 'SUCCEEDED'){
                this.$router.push({ path: 'overview' });
              } else {
                console.log("Open online takeaway failed");
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      }
    }
  };
</script>

<template>
  <div class="container-fluid">
    <table class="xz-table">
      <thead>
      <tr>
        <th class="bold">Betalingsmiddel</th>
        <th class="bold">Antall</th>
        <th class="bold"></th>
        <th class="bold">Beløp</th>
      </tr>
      </thead>
      <tbody>

      <tr>
        <td colSpan="4"><b>Kontant</b></td>
      </tr>
      <tr>
        <td>Ta med</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>
      <tr>
        <td>Levere</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>
      <tr>
        <td>Total kontant</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>

      <tr>
        <td colSpan="4"><b>Vipps</b></td>
      </tr>
      <tr>
        <td>Ta med </td>
        <td>{{ xReportCounters.vippsPickupCount }}</td>
        <td></td>
        <td>{{ displayTwoDecimals(xReportCounters.vippsPickupPaymentsTotalAmount) }}</td>
      </tr>
      <tr>
        <td>Levere</td>
        <td>{{ xReportCounters.vippsDeliveryCount }}</td>
        <td></td>
        <td>{{ displayTwoDecimals(xReportCounters.vippsDeliveryPaymentsTotalAmount) }}</td>
      </tr>

      <tr>
        <td>Total Vipps</td>
        <td>{{ vippsTotalCount }}</td>
        <td></td>
        <td>{{ displayTwoDecimals(vippsPaymentsTotalAmount) }}</td>
      </tr>

      <tr>
        <td colSpan="4"><b>Kort</b></td>
      </tr>
      <tr>
        <td>Ta med</td>
        <td>{{ xReportCounters.cardPickupCount }}</td>
        <td></td>
        <td>{{ displayTwoDecimals(xReportCounters.cardPickupPaymentsTotalAmount) }}</td>
      </tr>
      <tr>
        <td>Levere</td>
        <td>{{ xReportCounters.cardDeliveryCount }}</td>
        <td></td>
        <td>{{ displayTwoDecimals(xReportCounters.cardDeliveryPaymentsTotalAmount) }}</td>
      </tr>
      <tr>
        <td>Total kort</td>
        <td>{{ cardTotalCount }}</td>
        <td></td>
        <td>{{ displayTwoDecimals(cardPaymentsTotalAmount) }}</td>
      </tr>

      <tr>
        <td class="bold">Total salg</td>
        <td class="bold">{{ totalPaymentsCount }}</td>
        <td></td>
        <td class="bold">{{ displayTwoDecimals(totalPayments) }}</td>
      </tr>
      </tbody>
    </table>
    <hr class="horizontal-dashed"/>
  </div>
</template>

<script>
  import WebshopXReportUtil from "@/util/WebshopXReportUtil";
  import MathUtil from "@/util/MathUtil";

  export default {
    props: {
      xReportCounters: Object,
      vat: Object
    },
    computed: {
      vippsTotalCount() {
        return WebshopXReportUtil.getVippsTotalCount(this.xReportCounters);
      },
      vippsPaymentsTotalAmount() {
        return this.xReportCounters.vippsPickupPaymentsTotalAmount + this.xReportCounters.vippsDeliveryPaymentsTotalAmount;
      },

      cardTotalCount() {
        return WebshopXReportUtil.getCardTotalCount(this.xReportCounters);
      },

      cardPaymentsTotalAmount() {
        return this.xReportCounters.cardPickupPaymentsTotalAmount + this.xReportCounters.cardDeliveryPaymentsTotalAmount;
      },

      totalPaymentsCount() {
        return (WebshopXReportUtil.getCashTotalCount(this.xReportCounters) + WebshopXReportUtil.getVippsTotalCount(this.xReportCounters) + WebshopXReportUtil.getCardTotalCount(this.xReportCounters));
      },
      totalPayments() {
        return (this.xReportCounters.vippsPickupPaymentsTotalAmount + this.xReportCounters.vippsDeliveryPaymentsTotalAmount
            + this.xReportCounters.cardPickupPaymentsTotalAmount + this.xReportCounters.cardDeliveryPaymentsTotalAmount);
      }
    },
    methods: {
      displayTwoDecimals(num){
        if(num > 0 || num < 0){
          return MathUtil.displayTwoDecimals(num);
        }else {
          return num;
        }
      }
    }
  }
</script>

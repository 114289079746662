<template>
  <div style="max-width: 400px; margin-top: 10px;">
    <h5>Dagens totale salg fordelt på ordretyper</h5>
    <vue3-chart-js v-bind="{ ...pieChart }" />
  </div>
</template>

<script>
  import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

  export default {
    props: {
      totalSalesTodayByTypeLabels: Array,
      totalSalesTodayByType: Array
    },
    components: {
      Vue3ChartJs,
    },
    setup(props) {
      const pieChart = {
        type: "pie",
        data: {
          labels: props.totalSalesTodayByTypeLabels,
          datasets: [{
            backgroundColor: ["#B30E10", "#f0c7a9","#008000"],
            data: props.totalSalesTodayByType
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true
        }
      };
      return {
        pieChart,
      };
    },
  }
</script>

<template>
  <div class="container">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div class="mt-4" style="max-width:460px">
        <h3>Oppgjørsrapport for nettbutikken</h3>

        <div v-if="hasMultiLocations()" class="mt-5">
          <span class="ingredient-title">Velg avdeling</span>
          <select class="form-select" v-model="locationId">
            <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
          </select>
        </div>

        <SelectDate ref="selectDate" :label="'Velg dato'" />
        <button type="button" class="bo_btn" :class="isObjectEmpty(this.zReport) ? 'mb-5' : 'mb-0'" @click="showZReport"> Vis oppgjørsrapport </button>

        <div v-if="isObjectEmpty(zReport) && resultFetched" class="xz-report mb-5">
          <p class="no-report-found">Fant ingen oppgjørsrapport.</p>
        </div>

        <ShowWebshopZReport v-if="!isObjectEmpty(zReport)"
                            :zReport="zReport"
                            :company="company"
                            :location="getLocation()"
                            :vat="vat" />
      </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import SelectDate from "@/components/other/SelectDate";
import ShowWebshopZReport from "@/components/report/zreport/ShowWebshopZReport";
import ReportService from "@/services/report/ReportService";
import LocationUtil from "@/util/LocationUtil";

export default {
  components: {LoadingSpinner, SelectDate, ShowWebshopZReport},
  data() {
    return {
      loading: true,

      locationId: '',
      locations: [],
      merchant: '',
      company: {},
      selectedDate: new Date(),
      vat: {},
      zReport: {},
      resultFetched: false
    };
  },
  mounted() {
    ReportService.getDataForReportGui().then(
        (response) => {
          let dataForGui = response.data;
          //console.log("dataForGui: " +  JSON.stringify(dataForGui));
          this.company = dataForGui.company;
          this.locations = dataForGui.locations;
          this.terminals = dataForGui.terminals;
          this.vat = dataForGui.vat;
          this.merchant = dataForGui.merchant;

          this.locationId = this.locations.length ? this.locations[0].id : '';

          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
    );
  },
  methods: {
    hasMultiLocations(){
      return LocationUtil.hasMultiLocations();
    },
    getLocation(){
      return LocationUtil.getLocation(this.locationId);
    },
    showZReport() {
      this.loading = true;
      let selectedDate = this.$refs.selectDate.getSelectedDate();
      //console.log("selectedDate: " + selectedDate);

      ReportService.getWebshopZReport(this.locationId, selectedDate).then(
          (response) => {
            if(response.data.cagistahospitality) {
              this.zReport= response.data.cagistahospitality;
              console.log("zReport: " + JSON.stringify(this.zReport));
            }else {
              this.zReport= {};
            }
            //console.log('zReport: ' +  JSON.stringify(this.zReport));
            this.loading = false;
            this.resultFetched = true;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    isObjectEmpty(jsonObject){
      return !(Object.keys(jsonObject).length)
    }
  }
};
</script>

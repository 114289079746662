<template>
  <div class="mt-4 mb-4">
    <span class="ingredient-title">{{ label }}</span>
    <datepicker v-model="date" :locale="locale" inputFormat="dd.MM.yyyy" class="form-control"/>
  </div>
</template>

<script>
  import {nb} from "date-fns/locale";
  import Datepicker from 'vue3-datepicker'
  import moment from "moment";

  export default {
    components: {Datepicker},
    props: {
      label: String,
      selectedDate: String /* 'YYYY-MM-DD' */
    },
    data() {
      return {
        locale: nb,
        date: new Date()
      };
    },
    mounted() {
      if(this.selectedDate){
        //console.log(this.selectedDate);
        this.setSelectedDate(this.selectedDate);
      }
    },
    methods: {
      getSelectedDate() {
        return (moment(this.date)).format('YYYY-MM-DD');
      },
      setSelectedDate(dateString) {
        /** dateString: "YYYY-MM-DD" */
        this.date = moment(dateString, 'YYYY-MM-DD').toDate();
      }
    }
  }
</script>

<style scoped>
  .ingredient-title{
    display: block;
    overflow: auto;
    font-weight: bold;
    margin:15px 0 10px 0;
  }
</style>

<template>
  <div class="container-fluid">
    <div class="center">
      <span class="xz-title">{{location.name}}</span><br/>
      <span>Org.nr. {{location.vatNumber}} MVA</span>
      <hr class="horizontal-dashed"/>
      <div v-if="terminal.terminalId == 'ALL'" class="xz-title mb-4">
          <h5> Omsetningsrapport </h5>
          <div> (Alle selvbetjeningskioskene og nettbutikken samlet) </div>
      </div>
      <div v-else-if="terminal.terminalId == 'WEBSHOP'" class="xz-title mb-4">
          <h5> Omsetningsrapport for nettbutikken </h5>
      </div>
      <div v-else class="xz-title mb-4">
           <h5> Omsetningsrapport for {{ terminal.terminalType }} - {{ terminal.deviceName }} </h5>
      </div>
    </div>
    <span>Fra og med dato: {{ formatDate(fromDate) }}</span><br/>
    <span>Til og med dato: {{ formatDate(toDate) }}</span><br/>
    <hr class="horizontal-dashed"/>
  </div>
</template>

<script>
import moment from 'moment'
import MerchantUtil from "@/util/MerchantUtil";

export default {
  props: {
    location: Object,
    terminal: Object,
    fromDate: String,
    toDate: String
  },
  methods: {
    useFunctionality(){
      return MerchantUtil.useFunctionality();
    },
    formatDate(date) {
      return (moment(date)).format('DD.MM.YYYY');
    }
  }
}
</script>

import authHeader from "@/services/auth/AuthHeader";
import axios from "axios";

class LocationService {
    listLocations(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/location/listLocations' + '?merchant=' + user.merchant;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getLocation(locationId){
        let url = '/api/location/getLocation' + '?locationId=' + locationId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    createLocation(location){
        let url = '/api/superadmin/location/createLocation';
        let basicAuth = authHeader();
        return axios.post(url, location,{
            headers: basicAuth });
    }

    editLocation(location){
        let url = '/api/superadmin/location/editLocation';
        let basicAuth = authHeader();
        return axios.post(url, location,{
            headers: basicAuth });
    }

    deleteLocation(location){
        let url = '/api/superadmin/location/deleteLocation';
        let basicAuth = authHeader();
        return axios.post(url, location, {
            headers: basicAuth}
        );
    }
}

export default new LocationService();